import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../reducer/base/base.context';
import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { DEPARTMENT } from '../../utils/consts/selects';
import { EMPLOYMENT } from '../../utils/consts/radioButtons';
import { getPreviousSickReportStep, getRequiredErrorMessageText, goToNextSickReportStep } from '../../utils/common/functions';
import { inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations, rightAlignedTextColConfigurations, rightAlignedInputColConfigurations, getRowClassNames } from '../../utils/common/bootstrap';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';
import MaskedInputField from '../masked-input/masked-input';
import InfoIconField from '../info-icon-field/info-icon-field.component';

const SickReportIllnessCaseInformation = ({ isDetail }) => {
    const [validated, setValidated] = useState(false);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        currentSickReportType,
        currentStep,
        setCurrentStep,
        illnessCaseInformationFields,
        setIllnessCaseInformationFields,
        illnessCaseDetailsFields,
        setIllnessCaseDetailsFields,
        stepsValidated,
        setStepsValidated
    } = useContext(SickReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        firstName,
        lastName,
        role,
        personId,
        ahvSvNumber,
        employment,
        workloadInPercent,
        entryDate,
        department,
        workplace,
        telephonePrivate,
        emailPrivate
    } = illnessCaseInformationFields;


    const {
        monthlyBaseSalary,
        monthlyAdditionalSalary,
        yearlySalaryByHourlyRate,
        yearlySalaryVacationCompensation,
        monthlyAllowance,
        yearlyInconviniences,
        yearlyLebo,
        yearlyBonus,
        bankDetails,
        ibanNr,
        postAccount,
        accountNr
    } = illnessCaseDetailsFields;

    useEffect(() => {
        setCurrentStep(!isDetail ? SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_INFORMATION : SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_DETAILS);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentStep) {
            const prevStep = getPreviousSickReportStep(currentSickReportType, currentStep);

            if (prevStep) {
                if (!stepsValidated[prevStep]) {
                    navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`);
                } else {
                    setFooterNextAction(() => submitButtonRef.current.click());
                    setFooterBackAction(() => navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`));
                }
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setIllnessCaseInformationFields({ ...illnessCaseInformationFields, [name]: value });
    };

    const handleDetailFieldsChange = (event) => {
        const { name, value } = event.target;

        setIllnessCaseDetailsFields({ ...illnessCaseDetailsFields, [name]: value });
    };

    const handleDetailsCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setIllnessCaseDetailsFields({ ...illnessCaseDetailsFields, [name]: checked });
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            if (isDetail) {
                setStepsValidated( {
                    ...stepsValidated,
                    [SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_DETAILS]: true
                } );
            } else {
                setStepsValidated( {
                    ...stepsValidated,
                    [SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_INFORMATION]: true
                } );
            }

            goToNextSickReportStep(currentSickReportType, currentStep, navigate);
        }

        setValidated(true);
    };

    return (
        <div className="sick-report-illnessCaseInformationFields">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer title={'Angaben zur erkrankten Person'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Vorname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <InfoIconField
                                    inputType='text'
                                    infoIconId='info-3'
                                    infoIconText='Bitte geben Sie den Vornamen der erkrankten Person ein.'
                                >
                                    <Form.Control
                                        className={ inputColClassNames }
                                        required
                                        type="text"
                                        value={ firstName }
                                        name="firstName"
                                        onChange={ handleFieldsChange }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { getRequiredErrorMessageText('Vorname') }
                                    </Form.Control.Feedback>  
                                </InfoIconField>
                                 
                                
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Nachname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ lastName }
                                    name="lastName"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Nachname') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Funktion*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    name="role"
                                    value={ role }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Funktion') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Personen-ID*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    maxLength={ 5 }
                                    name="personId"
                                    value={ personId }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Personen-ID') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>AHV/SV Nummer</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <InfoIconField
                                    inputType='text'
                                    infoIconId='info-4'
                                    infoIconText='Bitte geben Sie die AHV/SV Nummer der erkrankten Person ein.'
                                >
                                    <MaskedInputField
                                        mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/]}
                                        inputProps={{
                                            name: 'ahvSvNumber',
                                            value: ahvSvNumber,
                                            onChange: handleFieldsChange,
                                            className: inputColClassNames,
                                        }}
                                    />
                                </InfoIconField>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Anstellung*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="befristet"
                                    name='employment'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ EMPLOYMENT.TEMPORARY }
                                    checked={ employment === EMPLOYMENT.TEMPORARY }
                                />
                                <Form.Check
                                    label="unbefristet"
                                    name='employment'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ EMPLOYMENT.UNLIMITED }
                                    checked={ employment === EMPLOYMENT.UNLIMITED }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Pensum in %*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="number"
                                    min="1"
                                    max="100"
                                    name="workloadInPercent"
                                    value={ workloadInPercent }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Pensum in %') }
                                </Form.Control.Feedback>
                            </Col>
                            <Col>
                                (Angabe ohne Kommastelle, d.h. auf- oder abrunden)
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Eintrittsdatum*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="date"
                                    name="entryDate"
                                    value={ entryDate }
                                    max={ new Date().toISOString().split('T')[0] }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Eintrittsdatum') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Dienststelle*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Select
                                    className={ inputColClassNames }
                                    required
                                    name="department"
                                    onChange={ handleFieldsChange }
                                    value={ department }
                                >
                                    {
                                        isDetail ? (
                                            <Fragment>
                                                <option value=''>Bitte auswählen...</option>
                                                <option value={ DEPARTMENT.AKSO }>{ DEPARTMENT.AKSO }</option>
                                                <option value={ DEPARTMENT.IV }>{ DEPARTMENT.IV }</option>
                                                <option value={ DEPARTMENT.SGV }>{ DEPARTMENT.SGV }</option>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <option value=''>Bitte auswählen...</option>
                                                <option value={ DEPARTMENT.ABMH }>{ DEPARTMENT.ABMH }</option>
                                                <option value={ DEPARTMENT.ADA }>{ DEPARTMENT.ADA }</option>
                                                <option value={ DEPARTMENT.AFIN }>{ DEPARTMENT.AFIN }</option>
                                                <option value={ DEPARTMENT.AFU }>{ DEPARTMENT.AFU }</option>
                                                <option value={ DEPARTMENT.AGEM }>{ DEPARTMENT.AGEM }</option>
                                                <option value={ DEPARTMENT.AGI }>{ DEPARTMENT.AGI }</option>
                                                <option value={ DEPARTMENT.AGS }>{ DEPARTMENT.AGS }</option>
                                                <option value={ DEPARTMENT.AIO }>{ DEPARTMENT.AIO }</option>
                                                <option value={ DEPARTMENT.AJUV }>{ DEPARTMENT.AJUV }</option>
                                                <option value={ DEPARTMENT.AKS }>{ DEPARTMENT.AKS }</option>
                                                <option value={ DEPARTMENT.AKSO }>{ DEPARTMENT.AKSO }</option>
                                                <option value={ DEPARTMENT.ALW }>{ DEPARTMENT.ALW }</option>
                                                <option value={ DEPARTMENT.AMB }>{ DEPARTMENT.AMB }</option>
                                                <option value={ DEPARTMENT.ARP }>{ DEPARTMENT.ARP }</option>
                                                <option value={ DEPARTMENT.AS }>{ DEPARTMENT.AS }</option>
                                                <option value={ DEPARTMENT.ASI }>{ DEPARTMENT.ASI }</option>
                                                <option value={ DEPARTMENT.AVT }>{ DEPARTMENT.AVT }</option>
                                                <option value={ DEPARTMENT.AWA }>{ DEPARTMENT.AWA }</option>
                                                <option value={ DEPARTMENT.AWJF }>{ DEPARTMENT.AWJF }</option>
                                                <option value={ DEPARTMENT.BBZ_OL }>{ DEPARTMENT.BBZ_OL }</option>
                                                <option value={ DEPARTMENT.BBZ_SOGR }>{ DEPARTMENT.BBZ_SOGR }</option>
                                                <option value={ DEPARTMENT.BVS }>{ DEPARTMENT.BVS }</option>
                                                <option value={ DEPARTMENT.DS_BJD }>{ DEPARTMENT.DS_BJD }</option>
                                                <option value={ DEPARTMENT.DS_DBK }>{ DEPARTMENT.DS_DBK }</option>
                                                <option value={ DEPARTMENT.DS_DDI }>{ DEPARTMENT.DS_DDI }</option>
                                                <option value={ DEPARTMENT.DS_FD }>{ DEPARTMENT.DS_FD }</option>
                                                <option value={ DEPARTMENT.DS_VWD }>{ DEPARTMENT.DS_VWD }</option>
                                                <option value={ DEPARTMENT.GESA }>{ DEPARTMENT.GESA }</option>
                                                <option value={ DEPARTMENT.GV }>{ DEPARTMENT.GV }</option>
                                                <option value={ DEPARTMENT.HBA }>{ DEPARTMENT.HBA }</option>
                                                <option value={ DEPARTMENT.HPSZ }>{ DEPARTMENT.HPSZ }</option>
                                                <option value={ DEPARTMENT.IV }>{ DEPARTMENT.IV }</option>
                                                <option value={ DEPARTMENT.JUGA }>{ DEPARTMENT.JUGA }</option>
                                                <option value={ DEPARTMENT.KAPO }>{ DEPARTMENT.KAPO }</option>
                                                <option value={ DEPARTMENT.KDLV }>{ DEPARTMENT.KDLV }</option>
                                                <option value={ DEPARTMENT.KESB }>{ DEPARTMENT.KESB }</option>
                                                <option value={ DEPARTMENT.KFK }>{ DEPARTMENT.KFK }</option>
                                                <option value={ DEPARTMENT.KSO }>{ DEPARTMENT.KSO }</option>
                                                <option value={ DEPARTMENT.KSSO }>{ DEPARTMENT.KSSO }</option>
                                                <option value={ DEPARTMENT.KSTA }>{ DEPARTMENT.KSTA }</option>
                                                <option value={ DEPARTMENT.LJ }>{ DEPARTMENT.LJ }</option>
                                                <option value={ DEPARTMENT.MFK }>{ DEPARTMENT.MFK }</option>
                                                <option value={ DEPARTMENT.MISA }>{ DEPARTMENT.MISA }</option>
                                                <option value={ DEPARTMENT.OA }>{ DEPARTMENT.OA }</option>
                                                <option value={ DEPARTMENT.PA }>{ DEPARTMENT.PA }</option>
                                                <option value={ DEPARTMENT.PD }>{ DEPARTMENT.PD }</option>
                                                <option value={ DEPARTMENT.PKSO }>{ DEPARTMENT.PKSO }</option>
                                                <option value={ DEPARTMENT.RA }>{ DEPARTMENT.RA }</option>
                                                <option value={ DEPARTMENT.RRD }>{ DEPARTMENT.RRD }</option>
                                                <option value={ DEPARTMENT.SGV }>{ DEPARTMENT.SGV }</option>
                                                <option value={ DEPARTMENT.SMG }>{ DEPARTMENT.SMG }</option>
                                                <option value={ DEPARTMENT.SPG }>{ DEPARTMENT.SPG }</option>
                                                <option value={ DEPARTMENT.STA }>{ DEPARTMENT.STA }</option>
                                                <option value={ DEPARTMENT.STAWA }>{ DEPARTMENT.STAWA }</option>
                                                <option value={ DEPARTMENT.VSA }>{ DEPARTMENT.VSA }</option>
                                                <option value={ DEPARTMENT.WALDEGG }>{ DEPARTMENT.WALDEGG }</option>
                                                <option value={ DEPARTMENT.WARTENFELS }>{ DEPARTMENT.WARTENFELS }</option>
                                                <option value={ DEPARTMENT.ZB }>{ DEPARTMENT.ZB }</option>
                                                <option value={ DEPARTMENT.ZD }>{ DEPARTMENT.ZD }</option>
                                                <option value={ DEPARTMENT.ZZ }>{ DEPARTMENT.ZZ }</option>
                                            </Fragment>
                                        )
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Dienststelle') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Arbeitsort*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    name="workplace"
                                    value={ workplace }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Arbeitsort') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                    </Container>
                </FieldsContainer>

                {
                    isDetail &&
                        <Fragment>
                            <FieldsContainer title={'Angaben zum Lohn'}>
                                <Container fluid>
                                    <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                                        <Col>
                                            <span>Zeitperiode: 12 Mt. vor Beginn der Krankheit</span>
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                            <Form.Label>{`Vertraglicher Grundlohn pro Monat Brutto inkl. Teuerungszulage ${ yearlySalaryByHourlyRate ? '' : '*' }`}</Form.Label>
                                        </Col>
                                        <Col { ...rightAlignedInputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                required={ !yearlySalaryByHourlyRate }
                                                type="number"
                                                value={ monthlyBaseSalary }
                                                name="monthlyBaseSalary"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                            {
                                                !yearlySalaryByHourlyRate &&
                                                    <Form.Control.Feedback type="invalid">
                                                        { getRequiredErrorMessageText('Vertraglicher Grundlohn pro Monat Brutto inkl. Teuerungszulage') }
                                                    </Form.Control.Feedback>
                                            }
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Besondere vertragliche Leistungen pro Monat Brutto (Besitzstand etc.)</Form.Label>
                                        </Col>
                                        <Col { ...rightAlignedInputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                type="number"
                                                value={ monthlyAdditionalSalary }
                                                name="monthlyAdditionalSalary"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                            <Form.Label>{`Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto (bitte Details "Stunden/Ansatz" via Mail) ${ monthlyBaseSalary ? '' : '*' }`}</Form.Label>
                                        </Col>
                                        <Col { ...rightAlignedInputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                required={ !monthlyBaseSalary }
                                                type="number"
                                                value={ yearlySalaryByHourlyRate }
                                                name="yearlySalaryByHourlyRate"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                            {
                                                !monthlyBaseSalary &&
                                                    <Form.Control.Feedback type="invalid">
                                                        { getRequiredErrorMessageText('Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto (bitte Details "Stunden/Ansatz" via Mail') }
                                                    </Form.Control.Feedback>
                                            }
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                            <Form.Label>{`Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto Ferien- & Feiertagsentschädigung ${ yearlySalaryByHourlyRate ? '*' : '' }`}</Form.Label>
                                        </Col>
                                        <Col { ...rightAlignedInputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                required={ yearlySalaryByHourlyRate }
                                                type="number"
                                                value={ yearlySalaryVacationCompensation }
                                                name="yearlySalaryVacationCompensation"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                            {
                                                yearlySalaryByHourlyRate &&
                                                    <Form.Control.Feedback type="invalid">
                                                        { getRequiredErrorMessageText('Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto Ferien- & Feiertagsentschädigung') }
                                                    </Form.Control.Feedback>
                                            }
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Kinder- / Familien- / Ausbildungszulagen pro Monat</Form.Label>
                                        </Col>
                                        <Col { ...rightAlignedInputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                type="number"
                                                value={ monthlyAllowance }
                                                name="monthlyAllowance"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Inkonvenienzen (gemäss GAV); 12 Mt. Periode (bitte Details via Mail)</Form.Label>
                                        </Col>
                                        <Col { ...rightAlignedInputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                type="number"
                                                value={ yearlyInconviniences }
                                                name="yearlyInconviniences"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                            <Form.Label>LEBO; 12 Mt. Periode</Form.Label>
                                        </Col>
                                        <Col { ...rightAlignedInputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                type="number"
                                                value={ yearlyLebo }
                                                name="yearlyLebo"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                            <Form.Label>13. Monatslohn / Gratifikation pro Jahr</Form.Label>
                                        </Col>
                                        <Col { ...rightAlignedInputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                type="number"
                                                value={ yearlyBonus }
                                                name="yearlyBonus"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </FieldsContainer>

                            <FieldsContainer title={'Zahlungsverbindung der/des MA'}>
                                <Container fluid>
                                    <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                                        <Col { ...textColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Bankverbindung</Form.Label>
                                        </Col>
                                        <Col { ...inputColConfigurations }>
                                            <Form.Check
                                                label=""
                                                name="bankDetails"
                                                type='checkbox'
                                                onChange={ handleDetailsCheckboxChange }
                                                value={ bankDetails }
                                                checked={ bankDetails }
                                            />
                                        </Col>

                                        <Col { ...textColConfigurations } className={ textColClassNames }>
                                            <Form.Label>{`IBAN-Nr. ${ bankDetails ? '*' : '' }`}</Form.Label>
                                        </Col>
                                        <Col { ...inputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                required={ bankDetails }
                                                type="text"
                                                value={ ibanNr }
                                                name="ibanNr"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                            {
                                                bankDetails &&
                                                    <Form.Control.Feedback type="invalid">
                                                        { getRequiredErrorMessageText('IBAN-Nr.') }
                                                    </Form.Control.Feedback>
                                            }
                                        </Col>
                                    </Row>

                                    <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                        <Col { ...textColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Postkonto</Form.Label>
                                        </Col>
                                        <Col { ...inputColConfigurations }>
                                            <Form.Check
                                                label=""
                                                name="postAccount"
                                                type='checkbox'
                                                onChange={ handleDetailsCheckboxChange }
                                                value={ postAccount }
                                                checked={ postAccount }
                                            />
                                        </Col>

                                        <Col { ...textColConfigurations } className={ textColClassNames }>
                                            <Form.Label>{`Konto-Nr. ${ postAccount ? '*' : '' }`}</Form.Label>
                                        </Col>
                                        <Col { ...inputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                required={ postAccount }
                                                type="text"
                                                value={ accountNr }
                                                name="accountNr"
                                                onChange={ handleDetailFieldsChange }
                                            />
                                            {
                                                accountNr &&
                                                    <Form.Control.Feedback type="invalid">
                                                        { getRequiredErrorMessageText('Konto-Nr.') }
                                                    </Form.Control.Feedback>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </FieldsContainer>
                        </Fragment>
                }

                <FieldsContainer title={'Erreichbarkeit der erkrankten Person während der Krankheitsabwesenheit'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Tel.-Nr. MA/Privat*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="tel"
                                    value={ telephonePrivate }
                                    name="telephonePrivate"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Tel.-Nr. MA/Privat') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>E-Mail MA/Privat*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="email"
                                    value={ emailPrivate }
                                    name="emailPrivate"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('E-Mail MA/Privat') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                    </Container>
                </FieldsContainer>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
}

export default SickReportIllnessCaseInformation;
