import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { BaseContext } from '../../reducer/base/base.context';
import { ACCIDENT_REPORT_STEPS_ROUTES, SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';

const Home = () => {
    const {
        setHeaderFormName,
        setFooterIsWithActions
    } = useContext(BaseContext);

    useEffect(() => {
        setHeaderFormName('');
        setFooterIsWithActions(false);
        document.title = 'Kanton Solothurn';

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='home'>
            <h1>Home</h1>
            <Link to={ `/unfallmeldung/${ ACCIDENT_REPORT_STEPS_ROUTES.STARTUP }` }>Unfallmeldung</Link><br />
            <Link to={ `/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(null, SICK_REPORT_STEPS.STARTUP) }` }>Krankheitsmeldung</Link>
        </div>
    );
}

export default Home;