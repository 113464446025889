export const SICK_REPORT_ACTION_TYPES = {
    SET_SICK_REPORT_TYPE: 'SET_SICK_REPORT_TYPE',
    SET_SICK_REPORT_STEP: 'SET_SICK_REPORT_STEP',
    SET_SICK_REPORT_STARTUP_FIELDS: 'SET_SICK_REPORT_STARTUP_FIELDS',
    SET_SICK_REPORT_ILLNESS_CASE_INFORMATION_FIELDS: 'SET_SICK_REPORT_ILLNESS_CASE_INFORMATION_FIELDS',
    SET_SICK_REPORT_ILLNESS_CASE_DETAILS_FIELDS: 'SET_SICK_REPORT_ILLNESS_CASE_DETAILS_FIELDS',
    SET_SICK_REPORT_EMPLOYEE_INFORMATION_FIELDS: 'SET_SICK_REPORT_EMPLOYEE_INFORMATION_FIELDS',
    SET_SICK_REPORT_EMPLOYMENT_DETAILS_FIELDS: 'SET_SICK_REPORT_EMPLOYMENT_DETAILS_FIELDS',
    SET_SICK_REPORT_INSURANCE_PENSION_INFORMATION_FIELDS: 'SET_SICK_REPORT_INSURANCE_PENSION_INFORMATION_FIELDS',
    SET_SICK_REPORT_ILLNESS_CASE_MANAGEMENT_FIELDS: 'SET_SICK_REPORT_ILLNESS_CASE_MANAGEMENT_FIELDS',
    SET_SICK_REPORT_DISABILITY_MANAGEMENT_FIELDS: 'SET_SICK_REPORT_DISABILITY_MANAGEMENT_FIELDS',
    SET_SICK_REPORT_STEPS_VALIDATED: 'SET_SICK_REPORT_STEPS_VALIDATED'
};