import { createContext } from "react";

export const BaseContext = createContext({
    headerFormName: '',
    setHeaderFormName: () => {},
    footerBackAction: () => {},
    setFooterBackAction: () => {},
    footerNextAction: () => {},
    setFooterNextAction: () => {},
    footerCancelAction: () => {},
    setFooterCancelAction: () => {},
    footerIsWithActions: false,
    setFooterIsWithActions: () => {},
    footerNextButtonText: '',
    setFooterNextButtonText: () => {},
    isFooterAccidentCopyright: true,
    setIsFooterAccidentCopyright: () => {},
});