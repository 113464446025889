import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../reducer/base/base.context';
import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { YES_NO_STATELESS } from '../../utils/consts/radioButtons';
import { getPreviousSickReportStep, getRequiredErrorMessageText, goToNextSickReportStep } from '../../utils/common/functions';
import { getRowClassNames, inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations } from '../../utils/common/bootstrap';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';
import { createObjectUrl, objectUrlExists, revokeObjectUrl } from '../../utils/sessionStorageHelperFunctions';
import { addFileToDatabase, deleteFileFromDatabase, getFileFromDatabase, openDatabase } from '../../utils/indexedDbHelperFunctions';
import InfoIconField from '../info-icon-field/info-icon-field.component';


const SickReportIllnessCaseManagement = () => {
    const [validated, setValidated] = useState(false);
    const [countMedicalCertificates, setCountMedicalCertificates] = useState(1);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        currentSickReportType,
        currentStep,
        setCurrentStep,
        illnessCaseManagementFields,
        setIllnessCaseManagementFields,
        stepsValidated,
        setStepsValidated
    } = useContext(SickReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        caseIllnessStartDate,
        caseRelapse,
        caseAbsenceLongTerm,
        casePregnancyCalculatedDateOfBirth,
        caseRemark,
        medicalCertificates,
        superiorFirstName,
        superiorLastName,
        superiorTelephone,
        superiorEmail
    } = illnessCaseManagementFields;

    useEffect(() => {
        setCurrentStep(SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_MANAGEMENT);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function initializeMedicalCertificates() {
            for (let i = 0; i < medicalCertificates.length; i++) {
                const mcData = medicalCertificates[i].certificate;

                if (mcData.objectUrl && !objectUrlExists(mcData.objectUrl)) {
                    revokeObjectUrl(mcData.objectUrl);
                    mcData.objectUrl = null;
                }

                if(mcData.id) {
                    try {
                        const db = await openDatabase();
                        const file = await getFileFromDatabase(db, mcData.id);
                        const objectUrl = createObjectUrl(file);
                        mcData.objectUrl = objectUrl;

                        const newMedicalCertificates = [...medicalCertificates];
                        newMedicalCertificates[i].certificate = mcData;
                
                        setIllnessCaseManagementFields({ ...illnessCaseManagementFields, medicalCertificates: newMedicalCertificates });
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }

        if (currentStep) {
            const prevStep = getPreviousSickReportStep(currentSickReportType, currentStep);

            if (prevStep) {
                if (!stepsValidated[prevStep]) {
                    navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`);
                } else {
                    setFooterNextAction(() => submitButtonRef.current.click());
                    setFooterBackAction(() => navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`));

                    setCountMedicalCertificates(medicalCertificates ? medicalCertificates.length || 1 : 1);
                    initializeMedicalCertificates();
                }
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setIllnessCaseManagementFields({ ...illnessCaseManagementFields, [name]: value });
    };

    // MedicalCertificates Functions Start
    const handleMedicalCertificatesRemove = (index) => {
        handleMedicalCertificatesCertificateDelete(index, true);
        const newMedicalCertificates = [...medicalCertificates];
        newMedicalCertificates.splice(index, 1);

        setIllnessCaseManagementFields({ ...illnessCaseManagementFields, medicalCertificates: newMedicalCertificates });
        setCountMedicalCertificates(countMedicalCertificates - 1);
    };

    const handleMedicalCertificatesAdd = () => {
        if (countMedicalCertificates < 3) {
            const newMedicalCertificates = [...medicalCertificates];
            newMedicalCertificates.push({
                certificate: {
                    id: '',
                    name: '',
                    type: '',
                    objectUrl: ''
                },
                startDate: '',
                endDate: '',
                incapacityInPercent: ''
            });

            setIllnessCaseManagementFields({ ...illnessCaseManagementFields, medicalCertificates: newMedicalCertificates });
            setCountMedicalCertificates(countMedicalCertificates + 1);
        }
    };

    const handleMedicalCertificatesChange = (event, index) => {
        let { name, value } = event.target;

        if (name.indexOf(`-${index}`) !== -1) {
            name = name.replace(`-${index}`, '');
        }

        const newMedicalCertificates = [...medicalCertificates];
        newMedicalCertificates[index][name] = value;

        setIllnessCaseManagementFields({ ...illnessCaseManagementFields, medicalCertificates: newMedicalCertificates });
    };

    const handleMedicalCertificatesCertificateChange = async(event, index) => {
        const { files } = event.target;

        // Check for existing ObjectUrl and revoke it
        if (medicalCertificates[index].certificate.objectUrl) {
            revokeObjectUrl(medicalCertificates[index].certificate.objectUrl);
        }

        // Delete file from database
        if (medicalCertificates[index].certificate.id) {            
            try {
                const db = await openDatabase();
                await deleteFileFromDatabase(db, medicalCertificates[index].certificate.id);
            } catch (error) {
                console.log(error);
            }
        }

        const mCData = {};
        //Create objectUrl from file
        if (files && files[0]) {
            try {
                const db = await openDatabase();
                const data = await addFileToDatabase(db, files[0]);
                mCData.id = data;
                mCData.name = files[0].name;
                mCData.type = files[0].type;
                mCData.objectUrl = URL.createObjectURL(files[0]);
            } catch (error) {
                console.log(error);
            }
        }

        const newMedicalCertificates = [...medicalCertificates];
        newMedicalCertificates[index].certificate = mCData;

        setIllnessCaseManagementFields({ ...illnessCaseManagementFields, medicalCertificates: newMedicalCertificates });
    };

    const handleMedicalCertificatesCertificateDelete = async(index, isNoNeedToUpdateState = false) => {
        // Check for existing ObjectUrl and revoke it
        if (medicalCertificates[index].certificate.objectUrl) {
            revokeObjectUrl(medicalCertificates[index].certificate.objectUrl);
        }

        // Delete file from database
        if (medicalCertificates[index].certificate.id) {
            try {
                const db = await openDatabase();
                await deleteFileFromDatabase(db, medicalCertificates[index].certificate.id);
            } catch (error) {
                console.log(error);
            }
        }

        if (isNoNeedToUpdateState) {
            return;
        }

        const newMedicalCertificates = [...medicalCertificates];
        newMedicalCertificates[index].certificate = {
            id: '',
            name: '',
            type: '',
            objectUrl: ''
        };

        setIllnessCaseManagementFields({ ...illnessCaseManagementFields, medicalCertificates: newMedicalCertificates });
    };
    // MedicalCertificates Functions End

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_MANAGEMENT]: true
            } );
            goToNextSickReportStep(currentSickReportType, currentStep, navigate);
        }

        setValidated(true);
    };

    return (
        <div className="sick-report-illnessCaseInformationFields">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer title={'Angaben zum Fall'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Krankheitsbeginn*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    max={ new Date().toISOString().split('T')[0] }
                                    type="date"
                                    name="caseIllnessStartDate"
                                    value={ caseIllnessStartDate }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Krankheitsbeginn') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col xl={ 6 } sm={ 12 }>
                                Hinweis: bei Wiederaufnahme od. Rückfällen, wenn der Fall vorgängig bereits abgeschlossen wurde, muss zwingend ein neues Datum für den erneuten Krankheitsbeginnn angegeben werden.
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Handelt es sich um einen Rückfall?*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="Ja"
                                    name='caseRelapse'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ caseRelapse === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="Nein"
                                    name='caseRelapse'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ caseRelapse === YES_NO_STATELESS.NO }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>

                            <Col xl={ 6 } sm={ 12 }>
                                Um einen Rückfall handelt es sich dann, wenn innerhalb eines Jahres seit dem Abschluss des letzten Krankheitsfalls eine erneute Arbeitsunfähigkeit aufgrund der gleichen Diagnose eintritt. Ist die erneute Arbeitsfähigkeit innerhalb eines Jahres auf eine andere Diagnose zurückzuführen, halten Sie dies bitte im Feld "Bemerkungen / zusätzliche Angaben" fest. Letztere Situation stellt keinen Rückfall dar.
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Abwesenheit voraussichtlich längerfristig*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="Ja"
                                    name='caseAbsenceLongTerm'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ caseAbsenceLongTerm === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="Nein"
                                    name='caseAbsenceLongTerm'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ caseAbsenceLongTerm === YES_NO_STATELESS.NO }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>

                            <Col xl={ 6 } sm={ 12 }>
                                (nach Einschätzung der vorgesetzten Person länger als 1 Monat)
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                Bei Arbeitsunfähigkeit während Schwangerschaft
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Errechneter Geburtstermin</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="date"
                                    min={ new Date().toISOString().split('T')[0] }
                                    name="casePregnancyCalculatedDateOfBirth"
                                    value={ casePregnancyCalculatedDateOfBirth }
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                Bei längerdauender Krankheut muss jeweils spätestens nach Ablauf von 30 Tagen eine neue Krankheitsmeldung mit einem neuen Arztzeugnis eingereicht werden. Dies gilt auch, wenn das vorangehende Zeugnis vom Arzt für eine längere Zeitspanne oder für eine unbestimmte Dauer (z.B. bis auf weiteres) ausgestellt worden ist. (für GAV Versicherte §173 GAV)
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Bemerkung / zusätzliche Angaben</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <InfoIconField
                                    infoIconId='info-5'
                                    infoIconText='Bitte geben Sie hier allfällige zusätzliche Angaben ein.'
                                    inputType={ 'textarea' }
                                >
                                    <Form.Control
                                        as="textarea"
                                        rows={ 3 }
                                        value={ caseRemark }
                                        name="caseRemark"
                                        onChange={ handleFieldsChange }
                                    />
                                </InfoIconField>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Arztzeugnis'}>
                    <Container fluid>
                    {
                            [...Array(countMedicalCertificates)].map((item, index) => {
                                return (
                                    <Fragment key={ index }>
                                        <Row { ...rowConfigurations } className={ index > 0 ? 'mt-4 pt-4 border-top border-1 align-items-start' : 'align-items-start' }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Arztzeugnis vom ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="date"
                                                    name="startDate"
                                                    value={ medicalCertificates[index].startDate }
                                                    onChange={ (event) => handleMedicalCertificatesChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Arztzeugnis vom') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>

                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Gültig bis ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="date"
                                                    name="endDate"
                                                    value={ medicalCertificates[index].endDate }
                                                    onChange={ (event) => handleMedicalCertificatesChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Gültig bis') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>

                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Arbeitsunfähigkeit in % ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="number"
                                                    min={ 1 }
                                                    max={ 100 }
                                                    value={ medicalCertificates[index].incapacityInPercent }
                                                    name="incapacityInPercent"
                                                    onChange={ (event) => handleMedicalCertificatesChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Arbeitsunfähigkeit in %') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col>
                                                Bitte beachten Sie unsere detaillierten Weisungen und Instruktionen auf der <a href='https://so.ch/verwaltung/finanzdepartement/personalamt/anstellungsbedingungen-gesamtarbeitsvertrag/versicherung/krankheit/' target='_blank' rel='noreferrer'>Homepage des Personalamts</a><br />Das Arztzeugnis gilt nicht als Beweis für eine Arbeitsunfähigkeit dar.<br />Im Zweifelsfall können Arztzeugnisse durch den Krankentaggeldversicherer / Vertrauensarzt überprüft werden.
                                            </Col>
                                        </Row>

                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Arztzeugnis ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations } xl={ 8 }>
                                                {
                                                    (!(medicalCertificates[index].certificate.objectUrl && objectUrlExists(medicalCertificates[index].certificate.objectUrl))) ? (
                                                        <Fragment>
                                                            <Form.Control
                                                                required={ index === 0 }
                                                                type="file"
                                                                name="certificate"
                                                                onChange={ (event) => handleMedicalCertificatesCertificateChange(event, index) }
                                                            />
                                                            {
                                                                index === 0 &&
                                                                    <Form.Control.Feedback type="invalid">
                                                                        { getRequiredErrorMessageText('Arztzeugnis') }
                                                                    </Form.Control.Feedback>
                                                            }
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <a href={ medicalCertificates[index].certificate.objectUrl } target="_blank" rel="noreferrer">
                                                                { medicalCertificates[index].certificate.name }
                                                            </a>
                                                            <Button className='ms-xl-2 ms-sm-0 ms-xs-0 mt-xl-0 mt-sm-2 mt-xs-2' onClick={ () => handleMedicalCertificatesCertificateDelete(index) }>Beilage entfernen</Button>
                                                        </Fragment>
                                                    )
                                                }
                                            </Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col>
                                                Bei Geburt (Schwangerschaftskomplikationen) letztes AZ oder Geburtsmitteilung anhängen
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className='mt-2'>
                                                <Button disabled={ countMedicalCertificates === 1 } variant="secondary" onClick={ () => handleMedicalCertificatesRemove(index) }>Löschen</Button>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Fragment>
                                )
                            })
                        }
                    </Container>
                </FieldsContainer>
                <Container fluid className='mt-2 mb-4'>
                    <Row>
                        <Col>
                            <Button variant="secondary" disabled={ countMedicalCertificates >= 3 } onClick={ handleMedicalCertificatesAdd }>hinzufügen</Button>
                        </Col>
                        <Col className='text-end'>
                            <span className='text-muted'>Sie müssen mindestens 1 Block und können maximal 3 Blöcke befüllen!</span>
                        </Col>
                    </Row>
                </Container>

                <FieldsContainer title={'Daten des vorgesetzten oder für die Meldung zuständigen Person'}>
                    <Container fluid>

                        <Row>
                            <Col>
                                (für die Koordination/Kommunikation mit dem Personalamt)
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Vorname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    name="superiorFirstName"
                                    value={ superiorFirstName }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Vorname') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Nachname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    name="superiorLastName"
                                    value={ superiorLastName }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Nachname') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Tel.-Nr. Arbeitsplatz*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="tel"
                                    name="superiorTelephone"
                                    value={ superiorTelephone }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Tel.-Nr. Arbeitsplatz') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>E-Mail Arbeitsplatz*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="email"
                                    name="superiorEmail"
                                    value={ superiorEmail }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('E-Mail Arbeitsplatz') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
}

export default SickReportIllnessCaseManagement;
