import { useReducer } from "react";
import { createAction } from "../../utils/reducer/reducer.utils";
import { BASE_ACTION_TYPES } from "./base.actions";
import { BaseContext } from "./base.context";
import { useNavigate } from 'react-router-dom';
import { setBaseData, getBaseData } from '../../utils/sessionStorageHelperFunctions';

const baseReducer = (state, action) => {
    const {
        type,
        payload
    } = action;

    switch (type) {
        case BASE_ACTION_TYPES.SET_HEADER_FORM_NAME:
            return {
                ...state,
                headerFormName: payload
            };
        case BASE_ACTION_TYPES.SET_FOOTER_BACK_ACTION:
            return {
                ...state,
                footerBackAction: payload
            };
        case BASE_ACTION_TYPES.SET_FOOTER_NEXT_ACTION:
            return {
                ...state,
                footerNextAction: payload
            };
        case BASE_ACTION_TYPES.SET_FOOTER_CANCEL_ACTION:
            return {
                ...state,
                footerCancelAction: payload
            };
        case BASE_ACTION_TYPES.SET_FOOTER_IS_WITH_ACTIONS:
            return {
                ...state,
                footerIsWithActions: payload
            };
        case BASE_ACTION_TYPES.SET_FOOTER_NEXT_BUTTON_TEXT:
            return {
                ...state,
                footerNextButtonText: payload
            };
        case BASE_ACTION_TYPES.SET_IS_FOOTER_ACCIDENT_COPYRIGHT:
            return {
                ...state,
                isFooterAccidentCopyright: payload
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
}

const INITIAL_STATE = () => {
    let navigate = useNavigate();
    const baseData = getBaseData();

    const {
        headerFormName,
        footerIsWithActions,
        isFooterAccidentCopyright
    } = baseData;

    return {
        headerFormName: headerFormName ? headerFormName : '',
        footerBackAction: () => {
            navigate(-1);
        },
        footerNextAction: () => {},
        footerCancelAction: () => {
            navigate('/');
        },
        footerIsWithActions: footerIsWithActions ? footerIsWithActions : false,
        footerNextButtonText: 'Weiter',
        isFooterAccidentCopyright: isFooterAccidentCopyright ? isFooterAccidentCopyright : true
    }
};

export const BaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(baseReducer, INITIAL_STATE());

    const {
        headerFormName,
        footerBackAction,
        footerNextAction,
        footerCancelAction,
        footerIsWithActions,
        footerNextButtonText,
        isFooterAccidentCopyright
    } = state;

    const setHeaderFormName = (headerFormName) => {
        setBaseData('headerFormName', headerFormName);
        dispatch(createAction(BASE_ACTION_TYPES.SET_HEADER_FORM_NAME, headerFormName));
    };
    const setFooterBackAction = (footerBackAction) => {
        dispatch(createAction(BASE_ACTION_TYPES.SET_FOOTER_BACK_ACTION, footerBackAction));
    };
    const setFooterNextAction = (footerNextAction) => {
        dispatch(createAction(BASE_ACTION_TYPES.SET_FOOTER_NEXT_ACTION, footerNextAction));
    };
    const setFooterCancelAction = (footerCancelAction) => {
        dispatch(createAction(BASE_ACTION_TYPES.SET_FOOTER_CANCEL_ACTION, footerCancelAction));
    };
    const setFooterIsWithActions = (footerIsWithActions) => {
        setBaseData('footerIsWithActions', footerIsWithActions);
        dispatch(createAction(BASE_ACTION_TYPES.SET_FOOTER_IS_WITH_ACTIONS, footerIsWithActions));
    };
    const setFooterNextButtonText = (footerNextButtonText) => {
        dispatch(createAction(BASE_ACTION_TYPES.SET_FOOTER_NEXT_BUTTON_TEXT, footerNextButtonText));
    };

    const setIsFooterAccidentCopyright = (isFooterAccidentCopyright) => {
        setBaseData('isFooterAccidentCopyright', isFooterAccidentCopyright);
        dispatch(createAction(BASE_ACTION_TYPES.SET_IS_FOOTER_ACCIDENT_COPYRIGHT, isFooterAccidentCopyright));
    };

    const value = {
        headerFormName,
        setHeaderFormName,
        footerBackAction,
        setFooterBackAction,
        footerNextAction,
        setFooterNextAction,
        footerCancelAction,
        setFooterCancelAction,
        footerIsWithActions,
        setFooterIsWithActions,
        footerNextButtonText,
        setFooterNextButtonText,
        isFooterAccidentCopyright,
        setIsFooterAccidentCopyright
    };

    return (
        <BaseContext.Provider value={ value }>
            { children }
        </BaseContext.Provider>
    );
}