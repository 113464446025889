import { ACCIDENT_REPORT_STARTUP_REPORT_FROM, ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT, YES_NO_STATELESS, EMPLOYMENT, PHYSICAL_ACTIVITY, STRESS, IV_EARLY_DETECTION, RECOVERY_BACK_TO_WORK, GENDER } from './consts/radioButtons';
import { CIVIL_STATUS, SICK_REPORT_TYPE } from './consts/selects';

export const mapAccidentReportStartupReportFrom = (value) => {
    switch (value) {
        case ACCIDENT_REPORT_STARTUP_REPORT_FROM.INJURED_PERSON:
            return 'durch die verunfallte Person';
        case ACCIDENT_REPORT_STARTUP_REPORT_FROM.ANOTHER_PERSON:
            return 'stellvertretend durch eine andere Person';
        default:
            return '';
    }
}

export const mapAccidentReportStartupTypeAccident = (value) => {
    switch (value) {
        case ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.BAGATELLE:
            return 'Bagatelle (keine resp. max. 3 Tage Arbeitsunfähigkeit)';
        case ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.ACCIDENT:
            return 'Unfall (Arbeitsunfähigkeit ab 4 und mehr Tage)';
        case ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.RELAPSE:
            return 'Rückfall';
        case ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.TOOTH_DEMAGE:
            return 'Zahnschaden';
        case ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.OCCUPATIONAL_DISEASE:
            return 'Berufskrankheit';
        default:
            return '';
    }
}

export const mapCivilStatus = (value) => {
    switch (value) {
        case CIVIL_STATUS.DIVORCED:
            return 'geschieden';
        case CIVIL_STATUS.DISSOLVED_PARTNERSHIP:
            return 'in aufgelöster Partnerschaft';
        case CIVIL_STATUS.REGISTERED_PARTNERSHIP:
            return 'in eingetragener Partnerschaft';
        case CIVIL_STATUS.SINGLE:
            return 'ledig';
        case CIVIL_STATUS.MARRIED:
            return 'verheiratet';
        case CIVIL_STATUS.WIDOWED:
            return 'verwitwet';
        case CIVIL_STATUS.LIVING_SEPARATELY:
            return 'getrennt lebend';
        default:
            return '';
    }
}

export const mapYesNoStateless = (value) => {
    switch (value) {
        case YES_NO_STATELESS.YES:
            return 'Ja';
        case YES_NO_STATELESS.NO:
            return 'Nein';
        case YES_NO_STATELESS.STATELESS:
            return '';
        default:
            return '';
    }
}

export const mapEmployment = (value) => {
    switch (value) {
        case EMPLOYMENT.TEMPORARY:
            return 'befristet';
        case EMPLOYMENT.UNLIMITED:
            return 'unbefristet';
        default:
            return '';
    }
}

export const mapSickReportType = (value) => {
    switch (value) {
        case SICK_REPORT_TYPE.ADMINISTRATION_AND_CANTONAL_SCHOOLS:
            return 'für Verwaltung und kantonalen Schulen';
        case SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS:
            return 'für selbstständige Anstalten (AKSO / IVSO / SGV)';
        case SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS:
            return 'für Volksschulen';
        case SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL:
            return 'für Gemeinde- und/oder Sozialregion-Personal';
        default:
            return '';
    }
}

export const mapPhysicalActivity = (value) => {
    switch (value) {
        case PHYSICAL_ACTIVITY.LIGHT:
            return 'leicht';
        case PHYSICAL_ACTIVITY.MEDIUM:
            return 'mittel';
        case PHYSICAL_ACTIVITY.HEAVY:
            return 'schwer';
        default:
            return '';
    }
}

export const mapStress = (value) => {
    switch (value) {
        case STRESS.NONE:
            return 'keine';
        case STRESS.BACK:
            return 'Rücken';
        case STRESS.BACK_SHOULDER:
            return 'Rücken und Schulter';
        case STRESS.SHOULDER:
            return 'Schulter';
        default:
            return '';
    }
}

export const mapIvEarlyDetection = (value) => {
    switch (value) {
        case IV_EARLY_DETECTION.WAIT:
            return 'abwarten / noch keine IV-Meldung notwenig (z.B. keine Informationen vorliegend wie lange der Ausfall noch dauert oder welche Auswirkungen die Erkrankung auf die berufliche Leistungsfähigkeit haben wird)';
        case IV_EARLY_DETECTION.DONE:
            return 'die Meldung ist bereits erfolgt (wenn "ja"; wann und durch wen?)';
        case IV_EARLY_DETECTION.NOT_NEEDED:
            return 'es ist definitiv keine IV-Meldung notwendig (z.B. die Arbeitsunfähigkeit wird voraussichtlich < 60 Tage sein, die Erkrankung hat keinen EInfluss auf die berufliche Leistungsfähigkeit oder es handelt sich um eine Schwangerschaft deren Arbeitsunfähigkeit mit der Geburt endet)';
        case IV_EARLY_DETECTION.CONTACT_ME:
            return 'bitte nehmen Sie mit mir Kontakt auf (z.B. für Mehrinformationen oder Verständnisfragen)';
        default:
            return '';
    }
}

export const mapRecoveryBackToWork = (value) => {
    switch (value) {
        case RECOVERY_BACK_TO_WORK.FULLY:
            return 'ganz / Fallabschluss (vollständige Wiederaufnahme oder bei Schwangerschaft - Geburt erfolgt)';
        case RECOVERY_BACK_TO_WORK.PARTLY:
            return 'teilweise';
        case RECOVERY_BACK_TO_WORK.THERAOEUTIC:
            return 'therapeutischer Arbeitsversuch';
        case RECOVERY_BACK_TO_WORK.NO:
            return 'nein';
        default:
            return '';
    }
}

export const mapBooleanToYesNo = (value) => {
    switch (value) {
        case true:
            return 'Ja';
        case false:
            return 'Nein';
        default:
            return '';
    }
}

export const mapGender = (value) => {
    switch (value) {
        case GENDER.FEMALE:
            return 'weiblich';
        case GENDER.MALE:
            return 'männlich';
        default:
            return '';
    }
}