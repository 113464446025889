import './header.styles.scss';
import { useContext } from 'react';
import { BaseContext } from '../../reducer/base/base.context';

const Header = () => {
    const {
        headerFormName
    } = useContext(BaseContext);

    return (
        <header className='header'>
            <div className='header-top'>
                <div className='address'>
                    <span>Personalamt</span><br />
                    <span>Rathaus</span><br />
                    <span>4509 Solothurn</span>
                </div>
                <div className='logo'>
                    <img src='/assets/images/logo_so.svg' alt='Logo' />
                </div>
            </div>
            { (headerFormName && headerFormName.length > 0) && (
                <div className='header-bottom'>
                    <div className='form-name'>
                        <h1>{ headerFormName }</h1>
                    </div>
                </div>
            ) }
        </header>
    );
};

export default Header;