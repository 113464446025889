export const CIVIL_STATUS = {
    DIVORCED: 'divorced',
    DISSOLVED_PARTNERSHIP: 'dissolvedPartnership',
    REGISTERED_PARTNERSHIP: 'registeredPartnership',
    SINGLE: 'single',
    MARRIED: 'married',
    WIDOWED: 'widowed',
    LIVING_SEPARATELY: 'livingSeparately'
};

export const DEPARTMENT = {
    ABMH: 'ABMH',
    ADA: 'ADA',
    AFIN: 'AFIN',
    AFU: 'AFU',
    AGEM: 'AGEM',
    AGI: 'AGI',
    AGS: 'AGS',
    AIO: 'AIO',
    AJUV: 'AJUV',
    AKS: 'AKS',
    AKSO: 'AKSO',
    ALW: 'ALW',
    AMB: 'AMB',
    ARP: 'ARP',
    AS: 'AS',
    ASI: 'ASI',
    AVT: 'AVT',
    AWA: 'AWA',
    AWJF: 'AWJF',
    BBZ_OL: 'BBZ Ol',
    BBZ_SOGR: 'BBZ SoGr',
    BVS: 'BVS',
    DS_BJD: 'DS BJD',
    DS_DBK: 'DS DBK',
    DS_DDI: 'DS DdI',
    DS_FD: 'DS FD',
    DS_VWD: 'DS VWD',
    GESA: 'GESA',
    GV: 'GV',
    HBA: 'HBA',
    HPSZ: 'HPSZ',
    IV: 'IVSO',
    JUGA: 'JUGA',
    KAPO: 'KAPO',
    KDLV: 'KDLV',
    KESB: 'KESB',
    KFK: 'KFK',
    KSO: 'KSO',
    KSSO: 'KSSO',
    KSTA: 'KSTA',
    LJ: 'LJ',
    MFK: 'MFK',
    MISA: 'MISA',
    OA: 'OA',
    PA: 'PA',
    PD: 'PD',
    PKSO: 'PKSO',
    RA: 'RA',
    RRD: 'RRD',
    SGV: 'SGV',
    SMG: 'SMG',
    SPG: 'SpG',
    STA: 'StA',
    STAWA: 'STAWA',
    VSA: 'VSA',
    WALDEGG: 'WALDEGG',
    WARTENFELS: 'WARTENFELS',
    ZB: 'ZB',
    ZD: 'ZD',
    ZZ: 'ZZ'
};

export const SICK_REPORT_TYPE = {
    ADMINISTRATION_AND_CANTONAL_SCHOOLS: 'administrationAndCantonalSchools',
    INDEPENDENT_INSTITUTIONS: 'independentInstitutions',
    ELEMENTARY_SCHOOLS: 'elementarySchools',
    MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL: 'municipalAndOrSocialRegionPersonnel'
};
