import { createContext } from "react";

export const AccidentReportContext = createContext({
    currentStep: null,
    setCurrentStep: () => {},
    startupFields: {},
    setStartupFields: () => {},
    personFields: {},
    setPersonFields: () => {},
    accidentFields: {},
    setAccidentFields: () => {},
    specialFields: {},
    setSpecialFields: () => {},
    stepsValidated: {},
    setStepsValidated: () => {}
});