export const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('ktsoStore', 1);

        request.onerror = (event) => {
            reject('Failed to open database');
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
        };
    });
};

export const addFileToDatabase = (db, file) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('files', 'readwrite');
        const store = transaction.objectStore('files');

        const request = store.add(file);

        request.onsuccess = (event) => {
            console.log('File successfully added to database');
            resolve(event.target.result);
        };

        request.onerror = () => {
            reject(Error('Failed to add file to database'));
        };
    });
};

export const getFileFromDatabase = (db, id) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('files', 'readonly');
        const store = transaction.objectStore('files');

        const request = store.get(id);

        request.onsuccess = (event) => {
            const result = event.target.result;
            if (result) {
                resolve(result);
            } else {
                reject(Error('File not found'));
            }
        };

        request.onerror = () => {
            reject(Error('Failed to get file from database'));
        };
    });
};

export const deleteFileFromDatabase = (db, id) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('files', 'readwrite');
        const store = transaction.objectStore('files');

        const request = store.delete(id);

        request.onsuccess = () => {
            resolve('File deleted from database');
        };

        request.onerror = () => {
            reject(Error('Failed to delete file from database'));
        };
    });
};

export const deleteAllFilesFromDatabase = (db) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('files', 'readwrite');
        const store = transaction.objectStore('files');

        const request = store.clear();

        request.onsuccess = () => {
            resolve('All files deleted from database');
        };

        request.onerror = () => {
            reject(Error('Failed to delete all files from database'));
        };
    });
};

export const deleteDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.deleteDatabase('ktsoStore');

        request.onerror = () => {
            reject(Error('Failed to delete database'));
        };

        request.onsuccess = () => {
            resolve('Database deleted');
        };
    });
};

export const initializeTabCloseListener = () => {
    window.addEventListener('beforeunload', () => {
        deleteDatabase()
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    });
};
