import { createContext } from "react";

export const SickReportContext = createContext({
    currentSickReportType: null,
    setCurrentSickReportType: () => {},
    currentStep: null,
    setCurrentStep: () => {},
    startupFields: {},
    setStartupFields: () => {},
    illnessCaseInformationFields: {},
    setIllnessCaseInformationFields: () => {},
    illnessCaseDetailsFields: {},
    setIllnessCaseDetailsFields: () => {},
    employeeInformationFields: {},
    setEmployeeInformationFields: () => {},
    employmentDetailsFields: {},
    setEmploymentDetailsFields: () => {},
    insurancePensionInformationFields: {},
    setInsurancePensionInformationFields: () => {},
    illnessCaseManagementFields: {},
    setIllnessCaseManagementFields: () => {},
    disabilityManagementFields: {},
    setDisabilityManagementFields: () => {},
    stepsValidated: [],
    setStepsValidated: () => {},
});