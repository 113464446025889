import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import { getRequiredErrorMessageText } from "../../utils/common/functions";

const MaskedInputField = ({ mask, inputProps, req }) => {
    if (!req) {
        req = {
            isRequired: false,
            label: ''
        };
    }

    const {
        isRequired,
        label
    } = req;

  return (
      <MaskedInput
        { ...inputProps }
        mask={mask}
        guide={true}
        required={isRequired}
        placeholderChar="_"
        showMask
        keepCharPositions={true}
        render={(ref, props) => (
            <Fragment>
                <Form.Control {...props} ref={ref} />
                <Form.Control.Feedback type="invalid">
                    { getRequiredErrorMessageText(label) }
                </Form.Control.Feedback>
            </Fragment>
          
        )}
      />
  );
};

export default MaskedInputField;
