import axios from "axios";

const API_BASE_URL = "http://localhost:8080/api/";

export const postRequest = (url, data, headers = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + url, data, headers)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};