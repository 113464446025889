import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccidentReportContext } from '../../reducer/accident-report/accident-report.context';
import { BaseContext } from '../../reducer/base/base.context';
import { ACCIDENT_REPORT_STEPS, ACCIDENT_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { YES_NO_STATELESS } from '../../utils/consts/radioButtons';
import { getRequiredErrorMessageText } from '../../utils/common/functions';
import { textColClassNames, inputColClassNames, inputColConfigurations, rowConfigurations, textColConfigurations, getRowClassNames } from '../../utils/common/bootstrap';
import { addFileToDatabase, deleteFileFromDatabase, getFileFromDatabase, openDatabase } from '../../utils/indexedDbHelperFunctions';
import { objectUrlExists, revokeObjectUrl, createObjectUrl } from '../../utils/sessionStorageHelperFunctions';

const AccidentReportAccident = () => {
    const [validated, setValidated] = useState(false);
    const [countInjuries, setCountInjuries] = useState(1);
    const [countDoctors, setCountDoctors] = useState(1);
    const [countWorkIncapacities, setCountWorkIncapacities] = useState(1);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        setCurrentStep,
        accidentFields,
        setAccidentFields,
        personFields,
        stepsValidated,
        setStepsValidated
    } = useContext(AccidentReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        accidentDetailsIndustrialAccident
    } = personFields;

    const {
        lastWorkDay,
        timeClockOut,
        injuries,
        doctors,
        workIncapacities
    } = accidentFields;

    useEffect(() => {
        if (!stepsValidated[ACCIDENT_REPORT_STEPS.PERSONAL_INFORMATION]) {
            navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.PERSONAL_INFORMATION}`);
        } else {
            setCurrentStep(ACCIDENT_REPORT_STEPS.ACCIDENT_INFORMATION);
            setFooterNextAction(() => submitButtonRef.current.click());
            setFooterBackAction(() => navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.PERSONAL_INFORMATION}`));
        }

        setCountDoctors(doctors.length || 1);
        setCountInjuries(injuries.length || 1);
        setCountWorkIncapacities(workIncapacities.length || 1);

        async function initializeMedicalCertificates() {
            for (let i = 0; i < workIncapacities.length; i++) {
                const mcData = workIncapacities[i].medicalCertificate;

                if (mcData.objectUrl && !objectUrlExists(mcData.objectUrl)) {
                    revokeObjectUrl(mcData.objectUrl);
                    mcData.objectUrl = null;
                }

                if(mcData.id) {
                    try {
                        const db = await openDatabase();
                        const file = await getFileFromDatabase(db, mcData.id);
                        const objectUrl = createObjectUrl(file);
                        mcData.objectUrl = objectUrl;

                        const newWorkIncapacities = [...workIncapacities];
                        newWorkIncapacities[i].medicalCertificate = mcData;
                
                        setAccidentFields({ ...accidentFields, workIncapacities: newWorkIncapacities });
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }

        initializeMedicalCertificates();

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setAccidentFields({ ...accidentFields, [name]: value });
    };

    // Injuries Functions Start
    const handleInjuriesChange = (event, index, childName = null, isBooltype = false) => {
        const { name, value } = event.target;

        const newInjuries = [...injuries];

        if (childName) {
            if (isBooltype) {
                newInjuries[index][name][childName] = value === 'true' ? true : false;
            } else {
                newInjuries[index][name][childName] = value;
            }
        } else {
            newInjuries[index][name] = value;
        }

        setAccidentFields({ ...accidentFields, injuries: newInjuries });
    };

    const handleInjuryRemove = (index) => {
        const newInjuries = [...injuries];
        newInjuries.splice(index, 1);

        setAccidentFields({ ...accidentFields, injuries: newInjuries });
        setCountInjuries(countInjuries - 1);
    };

    const handleInjuryAdd = () => {
        if (countInjuries < 5) {
            const newInjuries = [...injuries];
            newInjuries.push({
                affectedBodyPart: '',
                bodySide: {
                    left: false,
                    right: false,
                    unknown: false
                },
                injuryType: ''
            });

            setAccidentFields({ ...accidentFields, injuries: newInjuries });
            setCountInjuries(countInjuries + 1);
        }
    };
    // Injuries Functions End

    // Doctors Functions Start
    const handleDoctorRemove = (index) => {
        const newDoctors = [...doctors];
        newDoctors.splice(index, 1);

        setAccidentFields({ ...accidentFields, doctors: newDoctors });
        setCountDoctors(countDoctors - 1);
    };

    const handleDoctorAdd = () => {
        if (countDoctors < 3) {
            const newDoctors = [...doctors];
            newDoctors.push({
                doctor: '',
                street: '',
                streetNr: '',
                zip: '',
                city: ''
            });

            setAccidentFields({ ...accidentFields, doctors: newDoctors });
            setCountDoctors(countDoctors + 1);
        }
    };

    const handleDoctorsChange = (event, index) => {
        const { name, value } = event.target;

        const newDoctors = [...doctors];
        newDoctors[index][name] = value;

        setAccidentFields({ ...accidentFields, doctors: newDoctors });
    };
    // Doctors Functions End

    // WorkIncapacities Functions Start
    const handleWorkIncapacitiesRemove = (index) => {
        handleWorkIncapacitiesMedicalCertificateDelete(index, true);
        const newWorkIncapacities = [...workIncapacities];
        newWorkIncapacities.splice(index, 1);

        setAccidentFields({ ...accidentFields, workIncapacities: newWorkIncapacities });
        setCountWorkIncapacities(countWorkIncapacities - 1);
    };

    const handleWorkIncapacitiesAdd = () => {
        if (countWorkIncapacities < 3) {
            const newWorkIncapacities = [...workIncapacities];
            newWorkIncapacities.push({
                incapacity: YES_NO_STATELESS.STATELESS,
                incapacityInPercent: '',
                incapacityFrom: '',
                medicalCertificate: {
                    id: '',
                    name: '',
                    type: '',
                    objectUrl: ''
                }
            });

            setAccidentFields({ ...accidentFields, workIncapacities: newWorkIncapacities });
            setCountWorkIncapacities(countWorkIncapacities + 1);
        }
    };

    const handleWorkIncapacitiesChange = (event, index) => {
        let { name, value } = event.target;

        if (name.indexOf(`-${index}`) !== -1) {
            name = name.replace(`-${index}`, '');
        }

        const newWorkIncapacities = [...workIncapacities];
        newWorkIncapacities[index][name] = value;

        setAccidentFields({ ...accidentFields, workIncapacities: newWorkIncapacities });
    };

    const handleWorkIncapacitiesMedicalCertificateChange = async(event, index) => {
        const { files } = event.target;

        // Check for existing ObjectUrl and revoke it
        if (workIncapacities[index].medicalCertificate.objectUrl) {
            revokeObjectUrl(workIncapacities[index].medicalCertificate.objectUrl);
        }

        // Delete file from database
        if (workIncapacities[index].medicalCertificate.id) {            
            try {
                const db = await openDatabase();
                await deleteFileFromDatabase(db, workIncapacities[index].medicalCertificate.id);
            } catch (error) {
                console.log(error);
            }
        }

        const mCData = {};
        //Create objectUrl from file
        if (files && files[0]) {
            try {
                const db = await openDatabase();
                const data = await addFileToDatabase(db, files[0]);
                mCData.id = data;
                mCData.name = files[0].name;
                mCData.type = files[0].type;
                mCData.objectUrl = createObjectUrl(files[0]);
            } catch (error) {
                console.log(error);
            }
        }

        const newWorkIncapacities = [...workIncapacities];
        newWorkIncapacities[index].medicalCertificate = mCData;

        setAccidentFields({ ...accidentFields, workIncapacities: newWorkIncapacities });
    };

    const handleWorkIncapacitiesMedicalCertificateDelete = async(index, isNoNeedToUpdateState = false) => {
        // Check for existing ObjectUrl and revoke it
        if (workIncapacities[index].medicalCertificate.objectUrl) {
            revokeObjectUrl(workIncapacities[index].medicalCertificate.objectUrl);
        }

        // Delete file from database
        if (workIncapacities[index].medicalCertificate.id) {
            try {
                const db = await openDatabase();
                await deleteFileFromDatabase(db, workIncapacities[index].medicalCertificate.id);
            } catch (error) {
                console.log(error);
            }
        }

        if (isNoNeedToUpdateState) {
            return;
        }

        const newWorkIncapacities = [...workIncapacities];
        newWorkIncapacities[index].medicalCertificate = {
            id: '',
            name: '',
            type: '',
            objectUrl: ''
        };

        setAccidentFields({ ...accidentFields, workIncapacities: newWorkIncapacities });
    };
    // WorkIncapacities Functions End

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [ACCIDENT_REPORT_STEPS.ACCIDENT_INFORMATION]: true
            } );
            navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.SPECIAL}`);
        }

        setValidated(true);
    };

    return (
        <div className="accident-report-accident">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                {
                    accidentDetailsIndustrialAccident === YES_NO_STATELESS.NO &&
                    <FieldsContainer title={'Letzter Arbeitstag vor Unfall'}>
                        <Container fluid>
                            <Row className={ getRowClassNames(true) } { ...rowConfigurations }>
                                <Col { ...textColConfigurations } className={ textColClassNames }>
                                    <Form.Label>letzter Arbeitstag (vor Unfall)*</Form.Label>
                                </Col>
                                <Col xxl={4}>
                                    <Form.Control
                                        className='w-auto'
                                        required
                                        type="date"
                                        name="lastWorkDay"
                                        value={ lastWorkDay }
                                        onChange={ handleFieldsChange }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { getRequiredErrorMessageText('letzter Arbeitstag (vor Unfall)') }
                                    </Form.Control.Feedback>
                                </Col>

                                <Col { ...textColConfigurations } className={ textColClassNames }>
                                    <Form.Label>Uhrzeit Ausstempeln / Arbeitsende*</Form.Label>
                                </Col>
                                <Col xxl={4}>
                                    <Form.Control
                                        className='w-auto'
                                        required
                                        type="time"
                                        name="timeClockOut"
                                        value={ timeClockOut }
                                        onChange={ handleFieldsChange }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        { getRequiredErrorMessageText('Uhrzeit Ausstempeln / Arbeitsende') }
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Container>
                    </FieldsContainer>
                }

                <FieldsContainer title={'Verletzungen'}>
                    <Container fluid>
                        {
                            [...Array(countInjuries)].map((item, index) => {
                                return (
                                    <Fragment key={ index }>
                                        <Row { ...rowConfigurations } className={ index > 0 ? 'mt-4 pt-4 border-top border-1 align-items-start' : 'align-items-start' }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `betroffener Körperteil ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="text"
                                                    value={ injuries[index].affectedBodyPart }
                                                    name="affectedBodyPart"
                                                    onChange={ (event) => handleInjuriesChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('betroffener Körperteil') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>

                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Körperseite ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations } xl={ 2 }>
                                                <Form.Check
                                                    label="links"
                                                    name="bodySide"
                                                    type='checkbox'
                                                    onChange={ (event) => handleInjuriesChange(event, index, 'left', true) }
                                                    value={ !injuries[index].bodySide.left }
                                                    checked={ injuries[index].bodySide.left }
                                                    required={ index === 0 && !injuries[index].bodySide.right && !injuries[index].bodySide.unknown }
                                                    feedbackType="invalid"
                                                />
                                                <Form.Check
                                                    label="rechts"
                                                    name="bodySide"
                                                    type='checkbox'
                                                    onChange={ (event) => handleInjuriesChange(event, index, 'right', true) }
                                                    value={ !injuries[index].bodySide.right }
                                                    checked={ injuries[index].bodySide.right }
                                                    required={ index === 0 && !injuries[index].bodySide.left && !injuries[index].bodySide.unknown }
                                                    feedbackType="invalid"
                                                />
                                                <Form.Check
                                                    label="unbestimmt"
                                                    name="bodySide"
                                                    type='checkbox'
                                                    onChange={ (event) => handleInjuriesChange(event, index, 'unknown', true) }
                                                    value={ !injuries[index].bodySide.unknown }
                                                    checked={ injuries[index].bodySide.unknown }
                                                    required={ index === 0 && !injuries[index].bodySide.left && !injuries[index].bodySide.right }
                                                    feedback="Bitte wählen Sie mindestens eine Option aus."
                                                    feedbackType="invalid"
                                                />
                                            </Col>
                                            <Col>
                                                <span className='text-muted'>(Mehrfachnennungen möglich).</span>
                                            </Col>
                                        </Row>

                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Art der Schädigung ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={ 3 }
                                                    required={ index === 0 }
                                                    value={ injuries[index].injuryType }
                                                    name="injuryType"
                                                    onChange={ (event) => handleInjuriesChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Art der Schädigung') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>

                                        <Row { ...rowConfigurations }>
                                            <Col className='mt-2'>
                                                <Button disabled={ countInjuries === 1 } variant="secondary" onClick={ () => handleInjuryRemove(index) }>Löschen</Button>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Fragment>
                                )
                            })
                        }
                    </Container>
                </FieldsContainer>
                <Container fluid className='mt-2 mb-4'>
                    <Row>
                        <Col>
                            <Button variant="secondary" disabled={ countInjuries >= 5 } onClick={ handleInjuryAdd }>hinzufügen</Button>
                        </Col>
                        <Col className='text-end'>
                            <span className='text-muted'>Sie müssen mindestens 1 Block und können maximal 5 Blöcke befüllen!</span>
                        </Col>
                    </Row>
                </Container>

                <FieldsContainer title={'Ärzte'}>
                    <Container fluid>
                        {
                            [...Array(countDoctors)].map((item, index) => {
                                return (
                                    <Fragment key={ index }>
                                        <Row { ...rowConfigurations } className={ index > 0 ? 'mt-4 pt-4 border-top border-1 align-items-start' : 'align-items-start' }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Arzt ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="text"
                                                    value={ doctors[index].doctor }
                                                    name="doctor"
                                                    onChange={ (event) => handleDoctorsChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Arzt') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>

                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Strasse ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="text"
                                                    value={ doctors[index].street }
                                                    name="street"
                                                    onChange={ (event) => handleDoctorsChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Strasse') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>

                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Hausnummer ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="text"
                                                    value={ doctors[index].streetNr }
                                                    name="streetNr"
                                                    onChange={ (event) => handleDoctorsChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Hausnummer') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>

                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Postleitzahl ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="text"
                                                    value={ doctors[index].zip }
                                                    name="zip"
                                                    onChange={ (event) => handleDoctorsChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Postleitzahl') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>

                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Ort ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ index === 0 }
                                                    type="text"
                                                    value={ doctors[index].city }
                                                    name="city"
                                                    onChange={ (event) => handleDoctorsChange(event, index) }
                                                />
                                                {
                                                    index === 0 &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Ort') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className='mt-2'>
                                                <Button disabled={ countDoctors === 1 } variant="secondary" onClick={ () => handleDoctorRemove(index) }>Löschen</Button>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Fragment>
                                )
                            })
                        }
                    </Container>
                </FieldsContainer>
                <Container fluid className='mt-2 mb-4'>
                    <Row>
                        <Col>
                            <Button variant="secondary" disabled={ countDoctors >= 3 } onClick={ handleDoctorAdd }>hinzufügen</Button>
                        </Col>
                        <Col className='text-end'>
                            <span className='text-muted'>Sie müssen mindestens 1 Block und können maximal 3 Blöcke befüllen!</span>
                        </Col>
                    </Row>
                </Container>

                <FieldsContainer title={'Arbeitsunfähigkeit'}>
                    <Container fluid>
                        {
                            [...Array(countWorkIncapacities)].map((item, index) => {
                                return (
                                    <Fragment key={ index }>
                                        <Row { ...rowConfigurations } className={ index > 0 ? 'mt-4 pt-4 border-top border-1 align-items-start' : 'align-items-start' }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Arbeitsunfähigkeit ${ index === 0 ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Check
                                                    label="Ja"
                                                    name={ `incapacity-${ index }` }
                                                    type='radio'
                                                    onChange={ (event) => handleWorkIncapacitiesChange(event, index) }
                                                    value={ YES_NO_STATELESS.YES }
                                                    checked={ workIncapacities[index].incapacity === YES_NO_STATELESS.YES }
                                                />
                                                <Form.Check
                                                    label="Nein"
                                                    name={ `incapacity-${ index }` }
                                                    type='radio'
                                                    onChange={ (event) => handleWorkIncapacitiesChange(event, index) }
                                                    value={ YES_NO_STATELESS.NO }
                                                    checked={ workIncapacities[index].incapacity === YES_NO_STATELESS.NO }
                                                    required={ index === 0 }
                                                    feedback="Bitte wählen Sie eine Option aus."
                                                    feedbackType="invalid"
                                                />
                                            </Col>

                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Arbeitsunfähigkeit in % ${ workIncapacities[index].incapacity === YES_NO_STATELESS.YES ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ workIncapacities[index].incapacity === YES_NO_STATELESS.YES }
                                                    type="text"
                                                    value={ workIncapacities[index].incapacityInPercent }
                                                    name="incapacityInPercent"
                                                    onChange={ (event) => handleWorkIncapacitiesChange(event, index) }
                                                />
                                                {
                                                    workIncapacities[index].incapacity === YES_NO_STATELESS.YES &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Arbeitsunfähigkeit in %') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>

                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `wenn ja, seit wann ${ workIncapacities[index].incapacity === YES_NO_STATELESS.YES ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    required={ workIncapacities[index].incapacity === YES_NO_STATELESS.YES }
                                                    type="date"
                                                    name="incapacityFrom"
                                                    value={ workIncapacities[index].incapacityFrom }
                                                    onChange={ (event) => handleWorkIncapacitiesChange(event, index) }
                                                />
                                                {
                                                    workIncapacities[index].incapacity === YES_NO_STATELESS.YES &&
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('wenn ja, seit wann') }
                                                        </Form.Control.Feedback>
                                                }
                                            </Col>
                                        </Row>


                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>{ `Ärztl. Zeugnis ${ workIncapacities[index].incapacity === YES_NO_STATELESS.YES ? '*' : '' }` }</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations } xl={ 8 }>
                                                {
                                                    (!(workIncapacities[index].medicalCertificate.objectUrl && objectUrlExists(workIncapacities[index].medicalCertificate.objectUrl))) ? (
                                                        <Fragment>
                                                            <Form.Control
                                                                required={ workIncapacities[index].incapacity === YES_NO_STATELESS.YES }
                                                                type="file"
                                                                name="medicalCertificate"
                                                                onChange={ (event) => handleWorkIncapacitiesMedicalCertificateChange(event, index) }
                                                            />
                                                            {
                                                                workIncapacities[index].incapacity === YES_NO_STATELESS.YES &&
                                                                    <Form.Control.Feedback type="invalid">
                                                                        { getRequiredErrorMessageText('Ärztl. Zeugnis') }
                                                                    </Form.Control.Feedback>
                                                            }
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <a href={ workIncapacities[index].medicalCertificate.objectUrl } target="_blank" rel="noreferrer">
                                                                { workIncapacities[index].medicalCertificate.name }
                                                            </a>
                                                            <Button className='ms-xl-2 ms-sm-0 ms-xs-0 mt-xl-0 mt-sm-2 mt-xs-2' onClick={ () => handleWorkIncapacitiesMedicalCertificateDelete(index) }>Beilage entfernen</Button>
                                                        </Fragment>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='mt-2'>
                                                Hinweis: Um weitere Arbeitszeugnisse zu übermitteln, klicken Sie bitte auf "hinzufügen".
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                        <Row>
                                            <Col className='mt-2'>
                                                <Button disabled={ countWorkIncapacities === 1 } variant="secondary" onClick={ () => handleWorkIncapacitiesRemove(index) }>Löschen</Button>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Fragment>
                                )
                            })
                        }
                    </Container>
                </FieldsContainer>
                <Container fluid className='mt-2 mb-4'>
                    <Row>
                        <Col>
                            <Button variant="secondary" disabled={ countWorkIncapacities >= 3 } onClick={ handleWorkIncapacitiesAdd }>hinzufügen</Button>
                        </Col>
                        <Col className='text-end'>
                            <span className='text-muted'>Sie müssen mindestens 1 Block und können maximal 3 Blöcke befüllen!</span>
                        </Col>
                    </Row>
                </Container>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
};

export default AccidentReportAccident;