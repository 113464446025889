import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccidentReportContext } from '../../reducer/accident-report/accident-report.context';
import { BaseContext } from '../../reducer/base/base.context';
import { ACCIDENT_REPORT_STEPS, ACCIDENT_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { formatDate, formatNumberWith2DecimalsAndThousandSeparator } from '../../utils/common/functions';
import { mapAccidentReportStartupReportFrom, mapAccidentReportStartupTypeAccident, mapCivilStatus, mapYesNoStateless, mapEmployment } from '../../utils/mappingFunctions';
import { getFileFromDatabase, openDatabase } from '../../utils/indexedDbHelperFunctions';
import { postRequest } from '../../utils/axiosHelperFunctions';

const AccidentReportSummary = () => {
    const submitButtonRef = useRef(null);
    let navigate = useNavigate();
    const [sent, setSent] = useState(false);

    const {
        setCurrentStep,
        startupFields,
        personFields,
        accidentFields,
        specialFields,
        stepsValidated
    } = useContext(AccidentReportContext);

    const {
        setFooterNextAction,
        setFooterNextButtonText,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        unfallmeldungDate,
        reportFrom,
        typeAccident
    } = startupFields;

    const {
        accidentalPersonFirstName,
        accidentalPersonLastName,
        accidentalPersonBirthDate,
        accidentalPersonCivilStatus,
        accidentalPersonWorkplace,
        accidentalPersonEmail,
        accidentalPersonPhone,
        reportingPersonFirstName,
        reportingPersonLastName,
        reportingPersonDepartment,
        reportingPersonWorkplace,
        reportingPersonEmail,
        reportingPersonPhone,
        accidentalPersonEmployers,
        accidentDetailsDate,
        accidentDetailsTime,
        accidentDetailsIndustrialAccident,
        accidentDetailsWayToWork,
        accidentDetailsZipCity,
        accidentDetailsStreetAndNr,
        accidentDetailsCourseOfEvents,
        accidentDetailsPoliceReportCreated,
        accidentDetailsWhichPoliceStation,
    } = personFields;

    const {
        lastWorkDay,
        timeClockOut,
        injuries,
        doctors,
        workIncapacities
    } = accidentFields;

    const {
        independetInstitutionsFirstName,
        independetInstitutionsLastName,
        independetInstitutionsFunction,
        independetInstitutionsPersonId,
        independetInstitutionsAhvSvNr,
        independetInstitutionsEmployment,
        independetInstitutionsPensumInPercent,
        independetInstitutionsEntryDate,
        independetInstitutionsDepartment,
        independetInstitutionsWorkplace,
        independetInstitutionsPrivateStreetAndNr,
        independetInstitutionsPrivateZipCity,
        independetInstitutionsCivilStatus,
        independetInstitutionsChildren,
        independetInstitutionsAdditionalInsurance,
        independetInstitutionsMonthlyBaseSalary,
        independetInstitutionsMonthlyAdditionalSalary,
        independetInstitutionsYearlySalaryByHourlyRate,
        independetInstitutionsYearlySalaryVacationCompensation,
        independetInstitutionsMonthlyAllowance,
        independetInstitutionsYearlyInconviniences,
        independetInstitutionsYearlyLebo,
        independetInstitutionsYearlyBonus,
        studentStreet,
        studentStreetNr,
        studentZip,
        studentCity,
        studentSvNr,
        studentEntryDate
    } = specialFields;

    useEffect(() => {

        if (!stepsValidated[ACCIDENT_REPORT_STEPS.SPECIAL]) {
            navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.SPECIAL}`);
        } else {
            setCurrentStep(ACCIDENT_REPORT_STEPS.SUMMARY);
            setFooterNextAction(() => submitButtonRef.current.click());
            setFooterBackAction(() => navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.SPECIAL}`));
        }
        setFooterNextButtonText('Senden');

        return () => {
            setFooterNextButtonText('Weiter');
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])    

    useEffect(() => {
        const sendData = async () => {
            const {
                workIncapacities
            } = accidentFields;
    
            const formData = new FormData();
            formData.append('startupFields', JSON.stringify(startupFields));
            formData.append('personFields', JSON.stringify(personFields));
            formData.append('accidentFields', JSON.stringify(accidentFields));
            formData.append('specialFields', JSON.stringify(specialFields));

            try {
                const db = await openDatabase();
                for(let i = 0; i < workIncapacities.length; i++) {
                    const {
                        medicalCertificate: {
                            id
                        }
                    } = workIncapacities[i];

                    const file = await getFileFromDatabase(db, id);
                    formData.append('files', file);

                }
            } catch (error) {
                console.log('Fehler beim laden der Files: ', error);
            }

            postRequest('formular', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error);
            });
        };

        if (sent) {
            sendData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sent]);

    const handleSubmit = async(event) => {
        event.preventDefault();

        setSent(true);
    };    

    return (
        <div className="accident-report-person">
            <Form noValidate onSubmit={ handleSubmit } >
                <FieldsContainer title={''} isWithHeadline={ false }>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Empfänger</span>
                            </Col>
                            <Col>
                                <span>Personalamt</span><br />
                                <span>Rathaus</span><br />
                                <span>4509 Solothurn</span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Unfallmeldung'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Datum Unfallmeldung</span>
                            </Col>
                            <Col>
                                <span>
                                    { formatDate(unfallmeldungDate) }
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Durch wen erfolgt die Unfallmeldung?'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Meldung erfolgt</span>
                            </Col>
                            <Col>
                                <span>
                                    { mapAccidentReportStartupReportFrom(reportFrom) }
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Art / Ursache des Unfalls'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Art / Ursache des Unfalls</span>
                            </Col>
                            <Col>
                                <span>
                                    { mapAccidentReportStartupTypeAccident(typeAccident) }
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Personalien der verunfallten Person'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Vorname</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentalPersonFirstName }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Nachname</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentalPersonLastName }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Geburtsdatum</span>
                            </Col>
                            <Col>
                                <span>
                                    { formatDate(accidentalPersonBirthDate) }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Zivilstand</span>
                            </Col>
                            <Col>
                                <span>
                                    { mapCivilStatus(accidentalPersonCivilStatus) }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Arbeitsort</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentalPersonWorkplace }
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'E-mail und Telefon-Nr. verunfallte Person'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>E-Mail</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentalPersonEmail }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Telefon-Nr.</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentalPersonPhone }
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Personalien meldende resp. vorgesetzte Person'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Vorname</span>
                            </Col>
                            <Col>
                                <span>
                                    { reportingPersonFirstName }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Nachname</span>
                            </Col>
                            <Col>
                                <span>
                                    { reportingPersonLastName }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Dienststelle</span>
                            </Col>
                            <Col>
                                <span>
                                    { reportingPersonDepartment }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Arbeitsort</span>
                            </Col>
                            <Col>
                                <span>
                                    { reportingPersonWorkplace }
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'E-mail und Telefon-Nr. meldende resp.vorgesetzte Person'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>E-Mail</span>
                            </Col>
                            <Col>
                                <span>
                                    { reportingPersonEmail }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Telefon-Nr.</span>
                            </Col>
                            <Col>
                                <span>
                                    { reportingPersonPhone }
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                {
                    accidentalPersonEmployers.map((item, index) => {
                        return (
                            <Fragment key={ index }>
                                <FieldsContainer title={'weitere Arbeitgeber'} isWithHeadline={ index === 0 }>
                                    <Container fluid>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>weiterer Arbeitgeber</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item }
                                                </span>
                                            </Col>
                                        </Row>
                                    </Container>
                                </FieldsContainer>
                            </Fragment>
                        )
                    })
                }

                <FieldsContainer title={'Angaben zum Unfall'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Unfallsdatum</span>
                            </Col>
                            <Col>
                                <span>
                                    { formatDate(accidentDetailsDate) }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Uhrzeit</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentDetailsTime }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Betriebsunfall</span>
                            </Col>
                            <Col>
                                <span>
                                    { mapYesNoStateless(accidentDetailsIndustrialAccident) }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Unfall auf Arbeitsweg</span>
                            </Col>
                            <Col>
                                <span>
                                    { mapYesNoStateless(accidentDetailsWayToWork) }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Unfallort (PLZ / Ortsname)</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentDetailsZipCity }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Strasse und Haus-Nr.</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentDetailsStreetAndNr }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Hergang</span>
                            </Col>
                            <Col>
                                <span>
                                    { accidentDetailsCourseOfEvents }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Wurde ein Polizeirapport erstellt?</span>
                            </Col>
                            <Col>
                                <span>
                                    { mapYesNoStateless(accidentDetailsPoliceReportCreated) }
                                </span>
                            </Col>
                        </Row>
                        {
                            accidentDetailsWhichPoliceStation &&
                                <Row>
                                    <Col className='text-end me-4'>
                                        <span>Welche Polizeistelle</span>
                                    </Col>
                                    <Col>
                                        <span>
                                            { accidentDetailsWhichPoliceStation }
                                        </span>
                                    </Col>
                                </Row>
                        }
                        
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Letzter Arbeitstag vor Unfall'}>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>letzter Arbeitstag (vor Unfall)</span>
                            </Col>
                            <Col>
                                <span>
                                    { formatDate(lastWorkDay) }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Uhrzeit Ausstempeln / Arbeitsende</span>
                            </Col>
                            <Col>
                                <span>
                                    { timeClockOut }
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                {
                    injuries.map((item, index) => {
                        return (
                            <Fragment key={ index }>
                                <FieldsContainer title={'Verletzungen'} isWithHeadline={ index === 0 }>
                                    <Container fluid>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>betroffener Körperteil</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item.affectedBodyPart }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Körperseite</span>
                                            </Col>
                                            <Col>
                                                {
                                                    Object.keys(item.bodySide).map((key, idx) => {
                                                        return (
                                                            <Fragment key={ idx }>
                                                                <span>
                                                                    { item.bodySide[key] ? key === 'left' ? 'links' : key === 'right' ? 'rechts' : 'unknown' : '' }
                                                                </span>
                                                                {
                                                                    item.bodySide[key] &&
                                                                        <br />
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </FieldsContainer>
                            </Fragment>
                        )
                    })
                }

                {
                    doctors.map((item, index) => {
                        return (
                            <Fragment key={ index }>
                                <FieldsContainer title={'Ärzte'} isWithHeadline={ index === 0 }>
                                    <Container fluid>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Arzt</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item.doctor }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Strasse</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item.street }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Hausnummer</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item.streetNr }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Postleitzahl</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item.zip }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Ort</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item.city }
                                                </span>
                                            </Col>
                                        </Row>
                                    </Container>
                                </FieldsContainer>
                            </Fragment>
                        )
                    })
                }

                {
                    workIncapacities.map((item, index) => {
                        return (
                            <Fragment key={ index }>
                                <FieldsContainer title={'Arbeitsunfähigkeit'} isWithHeadline={ index === 0 }>
                                    <Container fluid>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Arbeitsfähigkeit</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { mapYesNoStateless(item.incapacity) }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Arbeitsunfähigkeit in %</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item.incapacityInPercent }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>wenn ja, seit wann</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatDate(item.incapacityFrom) }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Ärztl. Zeugnis</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { item.medicalCertificate.name }
                                                </span>
                                            </Col>
                                        </Row>
                                    </Container>
                                </FieldsContainer>
                            </Fragment>
                        )
                    })
                }

                {
                    (
                        independetInstitutionsFirstName ||
                        independetInstitutionsLastName ||
                        independetInstitutionsFunction ||
                        independetInstitutionsPersonId ||
                        independetInstitutionsAhvSvNr ||
                        independetInstitutionsEmployment ||
                        independetInstitutionsPensumInPercent ||
                        independetInstitutionsEntryDate ||
                        independetInstitutionsDepartment ||
                        independetInstitutionsWorkplace ||
                        independetInstitutionsPrivateStreetAndNr ||
                        independetInstitutionsPrivateZipCity ||
                        independetInstitutionsCivilStatus ||
                        independetInstitutionsChildren ||
                        independetInstitutionsAdditionalInsurance
                    ) &&
                        <FieldsContainer title={'Nur für selbstständige Anstalten (SGV, AKSO, IVSO) / Angaben zur verunfallten Person'}>
                            <Container fluid>
                                {
                                    (independetInstitutionsFirstName && independetInstitutionsFirstName.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Vorname</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsFirstName }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsLastName && independetInstitutionsLastName.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Nachname</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsLastName }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsFunction && independetInstitutionsFunction.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Funktion</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsFunction }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsPersonId && independetInstitutionsPersonId.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Personen-ID</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsPersonId }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsAhvSvNr && independetInstitutionsAhvSvNr.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>AHV/SV Nummer</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsAhvSvNr }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsEmployment && independetInstitutionsEmployment.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Anstellung</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { mapEmployment(independetInstitutionsEmployment) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsPensumInPercent && independetInstitutionsPensumInPercent.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Pensum in %</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsPensumInPercent }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsEntryDate && independetInstitutionsEntryDate.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Eintrittsdatum</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatDate(independetInstitutionsEntryDate) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsDepartment && independetInstitutionsDepartment.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Dienststelle</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsDepartment }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsWorkplace && independetInstitutionsWorkplace.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Arbeitsort</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsWorkplace }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsPrivateStreetAndNr && independetInstitutionsPrivateStreetAndNr.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Privatadresse (Str./Nr.)</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsPrivateStreetAndNr }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsPrivateZipCity && independetInstitutionsPrivateZipCity.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>PLZ / Ort</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsPrivateZipCity }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsCivilStatus && independetInstitutionsCivilStatus.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Zivilstand</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { mapCivilStatus(independetInstitutionsCivilStatus) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsChildren && independetInstitutionsChildren.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Kinder (mit Unterstüzungspflicht)</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { independetInstitutionsChildren }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsAdditionalInsurance && independetInstitutionsAdditionalInsurance.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>UVG Zusatzversicherung?</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { mapYesNoStateless(independetInstitutionsAdditionalInsurance) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                
                            </Container>
                        </FieldsContainer>
                }

                {
                    (
                        independetInstitutionsMonthlyBaseSalary ||
                        independetInstitutionsMonthlyAdditionalSalary ||
                        independetInstitutionsYearlySalaryByHourlyRate ||
                        independetInstitutionsYearlySalaryVacationCompensation ||
                        independetInstitutionsMonthlyAllowance ||
                        independetInstitutionsYearlyInconviniences ||
                        independetInstitutionsYearlyLebo ||
                        independetInstitutionsYearlyBonus
                    ) &&
                        <FieldsContainer title={'Nur für selbstständige Anstalten (SGV, AKSO, IVSO) / Angaben zum Lohn'}>
                            <Container fluid>
                                {
                                    (independetInstitutionsMonthlyBaseSalary && independetInstitutionsMonthlyBaseSalary.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Vetraglicher Grundlohn pro Monat Brutton inkl. Teuerungszulage</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatNumberWith2DecimalsAndThousandSeparator(independetInstitutionsMonthlyBaseSalary) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsMonthlyAdditionalSalary && independetInstitutionsMonthlyAdditionalSalary.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Besondere vertragliche Leistungen pro Monat Brutto (Besitzstand etc.)</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatNumberWith2DecimalsAndThousandSeparator(independetInstitutionsMonthlyAdditionalSalary) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsYearlySalaryByHourlyRate && independetInstitutionsYearlySalaryByHourlyRate.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto (bitte Details "Stunden/Ansatz" via Mail)</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatNumberWith2DecimalsAndThousandSeparator(independetInstitutionsYearlySalaryByHourlyRate) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsYearlySalaryVacationCompensation && independetInstitutionsYearlySalaryVacationCompensation.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto Ferien- & Feiertagsentschädigung</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatNumberWith2DecimalsAndThousandSeparator(independetInstitutionsYearlySalaryVacationCompensation) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsMonthlyAllowance && independetInstitutionsMonthlyAllowance.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Kinder- / Familien- / Ausbildungszulagen pro Monat</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatNumberWith2DecimalsAndThousandSeparator(independetInstitutionsMonthlyAllowance) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsYearlyInconviniences && independetInstitutionsYearlyInconviniences.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Inkonvenienzen (gemäss GAV); 12 Mt. Periode (bitte Details via Mail)</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatNumberWith2DecimalsAndThousandSeparator(independetInstitutionsYearlyInconviniences) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsYearlyLebo && independetInstitutionsYearlyLebo.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>LEBO; 12 Mt. Periode</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatNumberWith2DecimalsAndThousandSeparator(independetInstitutionsYearlyLebo) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (independetInstitutionsYearlyBonus && independetInstitutionsYearlyBonus.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>13. Monatslohn / Gratifikation pro Jahr</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatNumberWith2DecimalsAndThousandSeparator(independetInstitutionsYearlyBonus) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                            </Container>
                        </FieldsContainer>
                }

                { (studentStreet ||
                    studentStreetNr ||
                    studentZip ||
                    studentCity ||
                    studentSvNr ||
                    studentEntryDate) &&
                        <FieldsContainer title={'Nur für Schüler/-innen der Schule für Mode und Gestalten und des ZeitZentrums'}>
                            <Container fluid>
                                {
                                    (studentStreet && studentStreet.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Strasse</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { studentStreet }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (studentStreetNr && studentStreetNr.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Hausnummer</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { studentStreetNr }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (studentZip && studentZip.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Postleitzahl</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { studentZip }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (studentCity && studentCity.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Ort</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { studentCity }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (studentSvNr && studentSvNr.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>SV-Nummer (falls vorhanden)</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { studentSvNr }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                                {
                                    (studentEntryDate && studentEntryDate.length > 0) &&
                                        <Row>
                                            <Col className='text-end me-4'>
                                                <span>Eintrittsdatum</span>
                                            </Col>
                                            <Col>
                                                <span>
                                                    { formatDate(studentEntryDate) }
                                                </span>
                                            </Col>
                                        </Row>
                                }
                            </Container>
                        </FieldsContainer>
                }

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
}

export default AccidentReportSummary;