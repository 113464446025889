import { useReducer } from "react";
import { ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT, YES_NO_STATELESS } from "../../utils/consts/radioButtons";
import { createAction } from "../../utils/reducer/reducer.utils";
import { ACCIDENT_REPORT_ACTION_TYPES } from "./accident-report.actions";
import { AccidentReportContext } from "./accident-report.context";
import { ACCIDENT_REPORT_STEPS } from "../../utils/consts/steps.consts";
import { getAccidentReportData, setAccidentReportData } from '../../utils/sessionStorageHelperFunctions';

const accidentReportReducer = (state, action) => {
    const {
        type,
        payload
    } = action;

    switch (type) {
        case ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_STEP:
            return {
                ...state,
                currentStep: payload
            };
        case ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_STARTUP_FIELDS:
            return {
                ...state,
                startupFields: payload
            };
        case ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_PERSON_FIELDS:
            return {
                ...state,
                personFields: payload
            };
        case ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_ACCIDENT_FIELDS:
            return {
                ...state,
                accidentFields: payload
            };
        case ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_SPECIAL_FIELDS:
            return {
                ...state,
                specialFields: payload
            };
        case ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_STEPS_VALIDATED:
            return {
                ...state,
                stepsValidated: payload
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
}

const INITIAL_STATE = () => {
    const accidentReportData = getAccidentReportData();

    const {
        startup,
        person,
        accident,
        special,
        stepsValidatedStore
    } = accidentReportData;

    return ({
        currentStep: null,
        startupFields: Object.keys(startup).length > 0 ? startup :{
            unfallmeldungDate: new Date().toISOString().slice(0, 10),
            reportFrom: '',
            typeAccident: ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.BAGATELLE
        },
        personFields: Object.keys(person).length > 0 ? person : {
            accidentalPersonFirstName: '',
            accidentalPersonLastName: '',
            accidentalPersonBirthDate: '',
            accidentalPersonCivilStatus: '',
            accidentalPersonWorkplace: '',
            accidentalPersonEmail: '',
            accidentalPersonPhone: '',
            reportingPersonFirstName: '',
            reportingPersonLastName: '',
            reportingPersonDepartment: '',
            reportingPersonWorkplace: '',
            reportingPersonEmail: '',
            reportingPersonPhone: '',
            accidentalPersonEmployers: [],
            accidentDetailsDate: '',
            accidentDetailsTime: '',
            accidentDetailsIndustrialAccident: YES_NO_STATELESS.STATELESS,
            accidentDetailsWayToWork: YES_NO_STATELESS.STATELESS,
            accidentDetailsZipCity: '',
            accidentDetailsStreetAndNr: '',
            accidentDetailsCourseOfEvents: '',
            accidentDetailsPoliceReportCreated: YES_NO_STATELESS.STATELESS,
            accidentDetailsWhichPoliceStation: ''
        },
        accidentFields: Object.keys(accident).length > 0 ? accident : {
            lastWorkDay: '',
            timeClockOut: '',
            injuries: [{
                affectedBodyPart: '',
                bodySide: {
                    left: false,
                    right: false,
                    unknown: false
                },
                injuryType: ''
            }],
            doctors: [{
                doctor: '',
                street: '',
                streetNr: '',
                zip: '',
                city: ''
            }],
            workIncapacities: [{
                incapacity: YES_NO_STATELESS.STATELESS,
                incapacityInPercent: '',
                incapacityFrom: '',
                medicalCertificate: {
                    id: '',
                    name: '',
                    type: '',
                    objectUrl: ''
                }
            }]
        },
        specialFields: Object.keys(special).length > 0 ? special : { 
            independetInstitutionsFirstName: '',
            independetInstitutionsLastName: '',
            independetInstitutionsFunction: '',
            independetInstitutionsPersonId: '',
            independetInstitutionsAhvSvNr: '',
            independetInstitutionsEmployment: '',
            independetInstitutionsPensumInPercent: '',
            independetInstitutionsEntryDate: '',
            independetInstitutionsDepartment: '',
            independetInstitutionsWorkplace: '',
            independetInstitutionsPrivateStreetAndNr: '',
            independetInstitutionsPrivateZipCity: '',
            independetInstitutionsCivilStatus: '',
            independetInstitutionsChildren: '',
            independetInstitutionsAdditionalInsurance: YES_NO_STATELESS.STATELESS,
            independetInstitutionsMonthlyBaseSalary: '',
            independetInstitutionsMonthlyAdditionalSalary: '',
            independetInstitutionsYearlySalaryByHourlyRate: '',
            independetInstitutionsYearlySalaryVacationCompensation: '',
            independetInstitutionsMonthlyAllowance: '',
            independetInstitutionsYearlyInconviniences: '',
            independetInstitutionsYearlyLebo: '',
            independetInstitutionsYearlyBonus: '',
            studentStreet: '',
            studentStreetNr: '',
            studentZip: '',
            studentCity: '',
            studentSvNr: '',
            studentEntryDate: ''
        },
        stepsValidated: Object.keys(stepsValidatedStore).length > 0 ? stepsValidatedStore : {
            [ACCIDENT_REPORT_STEPS.STARTUP]: false,
            [ACCIDENT_REPORT_STEPS.PERSONAL_INFORMATION]: false,
            [ACCIDENT_REPORT_STEPS.ACCIDENT_INFORMATION]: false,
            [ACCIDENT_REPORT_STEPS.SPECIAL]: false
        }
    });
};

export const AccidentReportProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accidentReportReducer, INITIAL_STATE());

    const {
        currentStep,
        startupFields,
        personFields,
        accidentFields,
        specialFields,
        stepsValidated
    } = state;

    const setCurrentStep = (step) => {
        dispatch(createAction(ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_STEP, step));
    };

    const setStartupFields = (fields) => {
        setAccidentReportData(ACCIDENT_REPORT_STEPS.STARTUP, fields);
        dispatch(createAction(ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_STARTUP_FIELDS, fields));
    };

    const setPersonFields = (fields) => {
        setAccidentReportData(ACCIDENT_REPORT_STEPS.PERSONAL_INFORMATION, fields);
        dispatch(createAction(ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_PERSON_FIELDS, fields));
    };

    const setAccidentFields = (fields) => {
        setAccidentReportData(ACCIDENT_REPORT_STEPS.ACCIDENT_INFORMATION, fields);
        dispatch(createAction(ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_ACCIDENT_FIELDS, fields));
    };

    const setSpecialFields = (fields) => {
        setAccidentReportData(ACCIDENT_REPORT_STEPS.SPECIAL, fields);
        dispatch(createAction(ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_SPECIAL_FIELDS, fields));
    };

    const setStepsValidated = (steps) => {
        setAccidentReportData('stepsValidatedStore', steps);
        dispatch(createAction(ACCIDENT_REPORT_ACTION_TYPES.SET_ACCIDENT_REPORT_STEPS_VALIDATED, steps));
    };

    const value = {
        currentStep,
        setCurrentStep,
        startupFields,
        setStartupFields,
        personFields,
        setPersonFields,
        accidentFields,
        setAccidentFields,
        specialFields,
        setSpecialFields,
        stepsValidated,
        setStepsValidated
    };

    return (
        <AccidentReportContext.Provider value={ value }>
            { children }
        </AccidentReportContext.Provider>
    );
}