import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../reducer/base/base.context';
import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { EMPLOYMENT } from '../../utils/consts/radioButtons';
import { getPreviousSickReportStep, getRequiredErrorMessageText, goToNextSickReportStep } from '../../utils/common/functions';
import { inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations, rightAlignedTextColConfigurations, rightAlignedInputColConfigurations, getRowClassNames } from '../../utils/common/bootstrap';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';

const SickReportEmploymentDetails = () => {
    const [validated, setValidated] = useState(false);
    const [countOtherEmployments, setCountOtherEmployments] = useState(1);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        currentSickReportType,
        currentStep,
        setCurrentStep,
        employmentDetailsFields,
        setEmploymentDetailsFields,
        stepsValidated,
        setStepsValidated
    } = useContext(SickReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        role,
        workplace,
        zip,
        school,
        entryDate,
        employment,
        temporaryYearOfService,
        endOfTemoraryEmployment,
        otherEmployments,
        weeklyPensumInPercent,
        weeklyLessons,
        weeklyLessonsInFullPensum,
        monthlyBaseSalary,
        monthlyAdditionalSalary,
        yearlySalaryByHourlyRate,
        yearlySalaryVacationCompensation,
        monthlyAllowance,
        yearlyInconviniences,
        yearlyLebo,
        yearlyBonus
    } = employmentDetailsFields;

    useEffect(() => {
        setCurrentStep(SICK_REPORT_STEPS(currentSickReportType).EMPLOYMENT_DETAILS);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentStep) {
            const prevStep = getPreviousSickReportStep(currentSickReportType, currentStep);

            if (prevStep) {
                if (!stepsValidated[prevStep]) {
                    navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`);
                } else {
                    setCountOtherEmployments(otherEmployments.length || 1);
                    setFooterNextAction(() => submitButtonRef.current.click());
                    setFooterBackAction(() => navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`));
                }
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setEmploymentDetailsFields({ ...employmentDetailsFields, [name]: value });
    };

    // OtherEmployments Functions Start
    const handleOtherEmploymentsRemove = (index) => {
        const newOtherEmployments = [...otherEmployments];
        newOtherEmployments.splice(index, 1);

        setEmploymentDetailsFields({ ...employmentDetailsFields, otherEmployments: newOtherEmployments });
        setCountOtherEmployments(countOtherEmployments - 1);
    };

    const handleOtherEmploymentsAdd = () => {
        if (countOtherEmployments < 10) {
            const newOtherEmployments = [...otherEmployments];
            newOtherEmployments.push({
                employment: '',
                temporaryYearOfService: '',
                endOfTemoraryEmployment: ''
            });

            setEmploymentDetailsFields({ ...employmentDetailsFields, otherEmployments: newOtherEmployments });
            setCountOtherEmployments(countOtherEmployments + 1);
        }
    };

    const handleOtherEmploymentsChange = (event, index) => {
        const { name, value } = event.target;

        let correctName = name;
        if (name === `employment-${ index }`) {
            correctName = 'employment';
        }

        const newOtherEmployments = [...otherEmployments];
        newOtherEmployments[index][correctName] = value;

        setEmploymentDetailsFields({ ...employmentDetailsFields, otherEmployments: newOtherEmployments });
    };
    // OtherEmployments Functions End

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [SICK_REPORT_STEPS(currentSickReportType).EMPLOYMENT_DETAILS]: true
            } );

            goToNextSickReportStep(currentSickReportType, currentStep, navigate);
        }

        setValidated(true);
    };

    return (
        <div className="sick-report-employeeInformationFields">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer title={'Angaben zur Arbeitsstelle'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Funktion*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ role }
                                    name="role"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Funktion') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Arbeitsort*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ workplace }
                                    name="workplace"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Arbeitsort') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Postleitzahl*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ zip }
                                    name="zip"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Postleitzahl') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Schulhaus / Schule*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ school }
                                    name="school"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Schulhaus / Schule') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Eintrittsdatum*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    max={ new Date().toISOString().split('T')[0] }
                                    type="date"
                                    name="entryDate"
                                    value={ entryDate }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Eintrittsdatum') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Anstellung*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="befristet"
                                    name="employment"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ EMPLOYMENT.TEMPORARY }
                                    checked={ employment === EMPLOYMENT.TEMPORARY }
                                />
                                <Form.Check
                                    label="unbefristet"
                                    name="employment"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ EMPLOYMENT.UNLIMITED }
                                    checked={ employment === EMPLOYMENT.UNLIMITED }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>
                        </Row>

                        {
                            employment === EMPLOYMENT.TEMPORARY &&
                                <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                        <Form.Label>In welchem Dienstjahr?*</Form.Label>
                                    </Col>
                                    <Col { ...inputColConfigurations }>
                                        <Form.Control
                                            className={ inputColClassNames }
                                            required
                                            type="number"
                                            min={ 1 }
                                            name="temporaryYearOfService"
                                            value={ temporaryYearOfService }
                                            onChange={ handleFieldsChange }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            { getRequiredErrorMessageText('In welchem Dienstjahr?') }
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                        <Form.Label>Ende der befristeten Anstellung*</Form.Label>
                                    </Col>
                                    <Col { ...inputColConfigurations }>
                                        <Form.Control
                                            className={ inputColClassNames }
                                            required
                                            type="date"
                                            min={ new Date().toISOString().split('T')[0] }
                                            name="endOfTemoraryEmployment"
                                            value={ endOfTemoraryEmployment }
                                            onChange={ handleFieldsChange }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            { getRequiredErrorMessageText('Ende der befristeten Anstellung') }
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Angaben zu weiteren Anstellungen'}>
                    <Container fluid>
                        {
                            [...Array(countOtherEmployments)].map((item, index) => {
                                return (
                                    <Fragment key={ index }>
                                        <Row { ...rowConfigurations } className={ index > 0 ? 'mt-4 pt-4 border-top border-1 align-items-start' : 'align-items-start' }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>Anstellung</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Check
                                                    label="befristet"
                                                    name={ `employment-${index}` }
                                                    type='radio'
                                                    onChange={ (event) => handleOtherEmploymentsChange(event, index) }
                                                    value={ EMPLOYMENT.TEMPORARY }
                                                    checked={ otherEmployments[index].employment === EMPLOYMENT.TEMPORARY }
                                                />
                                                <Form.Check
                                                    label="unbefristet"
                                                    name={ `employment-${index}` }
                                                    type='radio'
                                                    onChange={ (event) => handleOtherEmploymentsChange(event, index) }
                                                    value={ EMPLOYMENT.UNLIMITED }
                                                    checked={ otherEmployments[index].employment === EMPLOYMENT.UNLIMITED }
                                                />
                                            </Col>
                                        </Row>

                                        {
                                            otherEmployments[index].employment === EMPLOYMENT.TEMPORARY &&
                                                <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                                        <Form.Label>In welchem Dienstjahr?*</Form.Label>
                                                    </Col>
                                                    <Col { ...inputColConfigurations }>
                                                        <Form.Control
                                                            className={ inputColClassNames }
                                                            required
                                                            type="number"
                                                            name="temporaryYearOfService"
                                                            value={ otherEmployments[index].temporaryYearOfService }
                                                            onChange={ (event) => handleOtherEmploymentsChange(event, index) }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('In welchem Dienstjahr?') }
                                                        </Form.Control.Feedback>
                                                    </Col>

                                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                                        <Form.Label>Ende der befristeten Anstellung*</Form.Label>
                                                    </Col>
                                                    <Col { ...inputColConfigurations }>
                                                        <Form.Control
                                                            className={ inputColClassNames }
                                                            required
                                                            type="date"
                                                            name="endOfTemoraryEmployment"
                                                            value={ otherEmployments[index].endOfTemoraryEmployment }
                                                            onChange={ (event) => handleOtherEmploymentsChange(event, index) }
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            { getRequiredErrorMessageText('Ende der befristeten Anstellung') }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                        }

                                        <Row>
                                            <Col className='mt-2'>
                                                <Button disabled={ countOtherEmployments === 1 } variant="secondary" onClick={ () => handleOtherEmploymentsRemove(index) }>Löschen</Button>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Fragment>
                                )
                            })
                        }
                    </Container>
                </FieldsContainer>
                <Container fluid className='mt-2 mb-4'>
                    <Row>
                        <Col>
                            <Button variant="secondary" disabled={ countOtherEmployments >= 10 } onClick={ handleOtherEmploymentsAdd }>hinzufügen</Button>
                        </Col>
                        <Col className='text-end'>
                            <span className='text-muted'>Sie können maximal 10 Blöcke befüllen!</span>
                        </Col>
                    </Row>
                </Container>

                <span>
                    "Beschäftigungsgrad/Pensum" und "Angaben zum Lohn" auf die ganze Anstellung bezogen angeben (Totale)
                </span>

                <FieldsContainer title={'Beschäftigungsgrad / Pensum'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Gesamt Pensum in % Woche*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="number"
                                    value={ weeklyPensumInPercent }
                                    name="weeklyPensumInPercent"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Gesamt Pensum in % Woche') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col>
                                (Angabe ohne Kommastelle, d..h. auf- oder abrunden)
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Gesamt Pensum in Lektionen je Woche*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="number"
                                    value={ weeklyLessons }
                                    name="weeklyLessons"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Gesamt Pensum in Lektionen je Woche') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col>
                                (Angabe ohne Kommastelle, d..h. auf- oder abrunden)
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Anzahl Lektionen/Woche in einem Vollpensum</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ weeklyLessonsInFullPensum }
                                    name="weeklyLessonsInFullPensum"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Angaben zum Lohn'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col>
                                <span>Zeitperiode: 12 Mt. vor Beginn der Krankheit</span>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>{`Vertraglicher Grundlohn pro Monat Brutto inkl. Teuerungszulage ${ yearlySalaryByHourlyRate ? '' : '*' }`}</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required={ !yearlySalaryByHourlyRate }
                                    type="number"
                                    value={ monthlyBaseSalary }
                                    name="monthlyBaseSalary"
                                    onChange={ handleFieldsChange }
                                />
                                {
                                    !yearlySalaryByHourlyRate &&
                                        <Form.Control.Feedback type="invalid">
                                            { getRequiredErrorMessageText('Vertraglicher Grundlohn pro Monat Brutto inkl. Teuerungszulage') }
                                        </Form.Control.Feedback>
                                }
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Besondere vertragliche Leistungen pro Monat Brutto (Besitzstand etc.)</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ monthlyAdditionalSalary }
                                    name="monthlyAdditionalSalary"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>{`Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto (bitte Details "Stunden/Ansatz" via Mail) ${ monthlyBaseSalary ? '' : '*' }`}</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required={ !monthlyBaseSalary }
                                    type="number"
                                    value={ yearlySalaryByHourlyRate }
                                    name="yearlySalaryByHourlyRate"
                                    onChange={ handleFieldsChange }
                                />
                                {
                                    !monthlyBaseSalary &&
                                        <Form.Control.Feedback type="invalid">
                                            { getRequiredErrorMessageText('Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto (bitte Details "Stunden/Ansatz" via Mail') }
                                        </Form.Control.Feedback>
                                }
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>{`Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto Ferien- & Feiertagsentschädigung ${ yearlySalaryByHourlyRate ? '*' : '' }`}</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required={ yearlySalaryByHourlyRate }
                                    type="number"
                                    value={ yearlySalaryVacationCompensation }
                                    name="yearlySalaryVacationCompensation"
                                    onChange={ handleFieldsChange }
                                />
                                {
                                    yearlySalaryByHourlyRate &&
                                        <Form.Control.Feedback type="invalid">
                                            { getRequiredErrorMessageText('Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto Ferien- & Feiertagsentschädigung') }
                                        </Form.Control.Feedback>
                                }
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Kinder- / Familien- / Ausbildungszulagen pro Monat</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ monthlyAllowance }
                                    name="monthlyAllowance"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Inkonvenienzen (gemäss GAV); 12 Mt. Periode (bitte Details via Mail)</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ yearlyInconviniences }
                                    name="yearlyInconviniences"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>LEBO; 12 Mt. Periode</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ yearlyLebo }
                                    name="yearlyLebo"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>13. Monatslohn / Gratifikation pro Jahr</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ yearlyBonus }
                                    name="yearlyBonus"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
};

export default SickReportEmploymentDetails;
