import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../reducer/base/base.context';
import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { IV_EARLY_DETECTION, PHYSICAL_ACTIVITY, RECOVERY_BACK_TO_WORK, STRESS, YES_NO_STATELESS } from '../../utils/consts/radioButtons';
import { getPreviousSickReportStep, getRequiredErrorMessageText, goToNextSickReportStep } from '../../utils/common/functions';
import { getRowClassNames, inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations } from '../../utils/common/bootstrap';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';


const SickReportDisabilityManagement = () => {
    const [validated, setValidated] = useState(false);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        currentSickReportType,
        currentStep,
        setCurrentStep,
        disabilityManagementFields,
        setDisabilityManagementFields,
        stepsValidated,
        setStepsValidated
    } = useContext(SickReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        physicalActivity,
        stress,
        ivEarlyDetection,
        whenAndByWhom,
        disclosuresEmployerDoubtsIncapacity,
        disclosuresEmployerDoubtsIncapacityDesc,
        disclosuresEmployerIvEarlyDetectionIsDone,
        disclosuresEmployerIvEarlyDetectionIsDoneDesc,
        disclosuresEmployerBenefitsOtherInsurances,
        disclosuresEmployerBenefitsOtherInsurancesDesc,
        recoveryBackToWork,
        recoveryBackToWorkPercentage
    } = disabilityManagementFields;

    useEffect(() => {
        setCurrentStep(SICK_REPORT_STEPS(currentSickReportType).DISABILITY_MANAGEMENT);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentStep) {
            const prevStep = getPreviousSickReportStep(currentSickReportType, currentStep);

            if (prevStep) {
                if (!stepsValidated[prevStep]) {
                    navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`);
                } else {
                    setFooterNextAction(() => submitButtonRef.current.click());
                    setFooterBackAction(() => navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`));
                }
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setDisabilityManagementFields({ ...disabilityManagementFields, [name]: value });
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [SICK_REPORT_STEPS(currentSickReportType).DISABILITY_MANAGEMENT]: true
            } );
            goToNextSickReportStep(currentSickReportType, currentStep, navigate);
        }

        setValidated(true);
    };

    return (
        <div className="sick-report-disabilityManagementFields">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer title={'Angaben zur körperlichen Belastung'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Körperliche Tätigkeit</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="leicht"
                                    name='physicalActivity'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ PHYSICAL_ACTIVITY.LIGHT }
                                    checked={ physicalActivity === PHYSICAL_ACTIVITY.LIGHT }
                                />
                                <Form.Check
                                    label="mittel"
                                    name='physicalActivity'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ PHYSICAL_ACTIVITY.MEDIUM }
                                    checked={ physicalActivity === PHYSICAL_ACTIVITY.MEDIUM }
                                />
                                <Form.Check
                                    label="schwer"
                                    name='physicalActivity'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ PHYSICAL_ACTIVITY.HEAVY }
                                    checked={ physicalActivity === PHYSICAL_ACTIVITY.HEAVY }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Belastung</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="keine"
                                    name='stress'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ STRESS.NONE }
                                    checked={ stress === STRESS.NONE }
                                />
                                <Form.Check
                                    label="Rücken"
                                    name='stress'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ STRESS.BACK }
                                    checked={ stress === STRESS.BACK }
                                />
                                <Form.Check
                                    label="Rücken und Schulter"
                                    name='stress'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ STRESS.BACK_SHOULDER }
                                    checked={ stress === STRESS.BACK_SHOULDER }
                                />
                                <Form.Check
                                    label="Schulter"
                                    name='stress'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ STRESS.SHOULDER }
                                    checked={ stress === STRESS.SHOULDER }
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'IV-Früherfassung'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>IV-Früherfassung*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations } xl={ 10 }>
                                <Form.Check
                                    label="abwarten / noch keine IV-Meldung notwenig (z.B. keine Informationen vorliegend wie lange der Ausfall noch dauert oder welche Auswirkungen die Erkrankung auf die berufliche Leistungsfähigkeit haben wird)"
                                    name='ivEarlyDetection'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ IV_EARLY_DETECTION.WAIT }
                                    checked={ ivEarlyDetection === IV_EARLY_DETECTION.WAIT }
                                />
                                <Form.Check
                                    label="die Meldung ist bereits erfolgt (wenn 'ja'; wann und durch wen?)"
                                    name='ivEarlyDetection'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ IV_EARLY_DETECTION.DONE }
                                    checked={ ivEarlyDetection === IV_EARLY_DETECTION.DONE }
                                />
                                <Form.Check
                                    label="es ist definitiv keine IV-Meldung notwendig (z.B. die Arbeitsunfähigkeit wird voraussichtlich < 60 Tage sein, die Erkrankung hat keinen EInfluss auf die berufliche Leistungsfähigkeit oder es handelt sich um eine Schwangerschaft deren Arbeitsunfähigkeit mit der Geburt endet)"
                                    name='ivEarlyDetection'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ IV_EARLY_DETECTION.NOT_NEEDED }
                                    checked={ ivEarlyDetection === IV_EARLY_DETECTION.NOT_NEEDED }
                                />
                                <Form.Check
                                    label="bitte nehmen Sie mit mir Kontakt auf (z.B. für Mehrinformationen oder Verständnisfragen)"
                                    name='ivEarlyDetection'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ IV_EARLY_DETECTION.CONTACT_ME }
                                    checked={ ivEarlyDetection === IV_EARLY_DETECTION.CONTACT_ME }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>
                        </Row>

                        {
                            ivEarlyDetection === IV_EARLY_DETECTION.DONE &&
                                <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                        <Form.Label>Wann und durch wen ist die Meldung erfolgt?*</Form.Label>
                                    </Col>
                                    <Col { ...inputColConfigurations }>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            rows={ 3 }
                                            value={ whenAndByWhom }
                                            name="whenAndByWhom"
                                            onChange={ handleFieldsChange }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            { getRequiredErrorMessageText('Wann und durch wen ist die Meldung erfolgt?') }
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Angaben Arbeitgeber'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Bestehen Ihrerseits Zweifel an der Arbeitsunfähigkeit?</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="ja"
                                    name='disclosuresEmployerDoubtsIncapacity'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ disclosuresEmployerDoubtsIncapacity === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="nein"
                                    name='disclosuresEmployerDoubtsIncapacity'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ disclosuresEmployerDoubtsIncapacity === YES_NO_STATELESS.NO }
                                />
                            </Col>

                            {
                                disclosuresEmployerDoubtsIncapacity === YES_NO_STATELESS.YES &&
                                    <Fragment>
                                        <Col { ...textColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Welche Zweifel bestehen?*</Form.Label>
                                        </Col>
                                        <Col { ...inputColConfigurations }>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                rows={ 3 }
                                                value={ disclosuresEmployerDoubtsIncapacityDesc }
                                                name="disclosuresEmployerDoubtsIncapacityDesc"
                                                onChange={ handleFieldsChange }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                { getRequiredErrorMessageText('Welche Zweifel bestehen?') }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Fragment>
                            }
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Ist eine IV-Anmeldung erfolgt?</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="ja"
                                    name='disclosuresEmployerIvEarlyDetectionIsDone'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ disclosuresEmployerIvEarlyDetectionIsDone === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="nein"
                                    name='disclosuresEmployerIvEarlyDetectionIsDone'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ disclosuresEmployerIvEarlyDetectionIsDone === YES_NO_STATELESS.NO }
                                />
                            </Col>

                            {
                                disclosuresEmployerIvEarlyDetectionIsDone === YES_NO_STATELESS.YES &&
                                    <Fragment>
                                        <Col { ...textColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Wann und für was?*</Form.Label>
                                        </Col>
                                        <Col { ...inputColConfigurations }>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                rows={ 3 }
                                                value={ disclosuresEmployerIvEarlyDetectionIsDoneDesc }
                                                name="disclosuresEmployerIvEarlyDetectionIsDoneDesc"
                                                onChange={ handleFieldsChange }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                { getRequiredErrorMessageText('Wann und für was?') }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Fragment>
                            }
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Bezieht der Arbeitnehmende bereits Leistungen anderer Versicherungen?</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="ja"
                                    name='disclosuresEmployerBenefitsOtherInsurances'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ disclosuresEmployerBenefitsOtherInsurances === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="nein"
                                    name='disclosuresEmployerBenefitsOtherInsurances'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ disclosuresEmployerBenefitsOtherInsurances === YES_NO_STATELESS.NO }
                                />
                            </Col>

                            {
                                disclosuresEmployerBenefitsOtherInsurances === YES_NO_STATELESS.YES &&
                                    <Fragment>
                                        <Col { ...textColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Welche Leistungsart / Gesellschaft*</Form.Label>
                                        </Col>
                                        <Col { ...inputColConfigurations }>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                rows={ 3 }
                                                value={ disclosuresEmployerBenefitsOtherInsurancesDesc }
                                                name="disclosuresEmployerBenefitsOtherInsurancesDesc"
                                                onChange={ handleFieldsChange }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                { getRequiredErrorMessageText('Welche Leistungsart / Gesellschaft?') }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Fragment>
                            }
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Genesungsmeldung / Teilgenesungsmeldung'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Verischerte Person hat Arbeit wieder aufgenommen</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="ganz / Fallabschluss (vollständige Wiederaufnahme oder bei Schwangerschaft - Geburt erfolgt)"
                                    name='recoveryBackToWork'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ RECOVERY_BACK_TO_WORK.FULLY }
                                    checked={ recoveryBackToWork === RECOVERY_BACK_TO_WORK.FULLY }
                                />
                                <Form.Check
                                    label="teilweise"
                                    name='recoveryBackToWork'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ RECOVERY_BACK_TO_WORK.PARTLY }
                                    checked={ recoveryBackToWork === RECOVERY_BACK_TO_WORK.PARTLY }
                                />
                                <Form.Check
                                    label="therapeutischer Arbeitsversuch"
                                    name='recoveryBackToWork'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ RECOVERY_BACK_TO_WORK.THERAOEUTIC }
                                    checked={ recoveryBackToWork === RECOVERY_BACK_TO_WORK.THERAOEUTIC }
                                />
                                <Form.Check
                                    label="nein"
                                    name='recoveryBackToWork'
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ RECOVERY_BACK_TO_WORK.NO }
                                    checked={ recoveryBackToWork === RECOVERY_BACK_TO_WORK.NO }
                                />
                            </Col>

                            {
                                recoveryBackToWork === RECOVERY_BACK_TO_WORK.PARTLY &&
                                    <Fragment>
                                        <Col { ...textColConfigurations } className={ textColClassNames }>
                                            <Form.Label>Zu wieviel %?*</Form.Label>
                                        </Col>
                                        <Col { ...inputColConfigurations }>
                                            <Form.Control
                                                className={ inputColClassNames }
                                                type="number"
                                                min={ 1 }
                                                max={ 100 }
                                                required
                                                value={ recoveryBackToWorkPercentage }
                                                name="recoveryBackToWorkPercentage"
                                                onChange={ handleFieldsChange }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                { getRequiredErrorMessageText('Zu wieviel %?') }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Fragment>
                            }
                        </Row>
                    </Container>
                </FieldsContainer>                            

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
};

export default SickReportDisabilityManagement;
