import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccidentReportContext } from '../../reducer/accident-report/accident-report.context';
import { BaseContext } from '../../reducer/base/base.context';
import { ACCIDENT_REPORT_STEPS, ACCIDENT_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { CIVIL_STATUS, DEPARTMENT } from '../../utils/consts/selects';
import { YES_NO_STATELESS } from '../../utils/consts/radioButtons';
import { getRequiredErrorMessageText } from '../../utils/common/functions';
import { getRowClassNames, inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations } from '../../utils/common/bootstrap';

const AccidentReportPerson = () => {
    const [validated, setValidated] = useState(false);
    const [countOtherEmployers, setCountOtherEmployers] = useState(1);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        setCurrentStep,
        personFields,
        setPersonFields,
        stepsValidated,
        setStepsValidated
    } = useContext(AccidentReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        accidentalPersonFirstName,
        accidentalPersonLastName,
        accidentalPersonBirthDate,
        accidentalPersonCivilStatus,
        accidentalPersonWorkplace,
        accidentalPersonEmail,
        accidentalPersonPhone,
        reportingPersonFirstName,
        reportingPersonLastName,
        reportingPersonDepartment,
        reportingPersonWorkplace,
        reportingPersonEmail,
        reportingPersonPhone,
        accidentalPersonEmployers,
        accidentDetailsDate,
        accidentDetailsTime,
        accidentDetailsIndustrialAccident,
        accidentDetailsWayToWork,
        accidentDetailsZipCity,
        accidentDetailsStreetAndNr,
        accidentDetailsCourseOfEvents,
        accidentDetailsPoliceReportCreated,
        accidentDetailsWhichPoliceStation,
    } = personFields;

    useEffect(() => {
        if (!stepsValidated[ACCIDENT_REPORT_STEPS.STARTUP]) {
            navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.STARTUP}`);
        } else {
            setCurrentStep(ACCIDENT_REPORT_STEPS.PERSONAL_INFORMATION);
            setFooterNextAction(() => submitButtonRef.current.click());
            setFooterBackAction(() => navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.STARTUP}`));
        }

        setCountOtherEmployers(accidentalPersonEmployers.length || 1);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setPersonFields({ ...personFields, [name]: value });
    };

    const handleAccidentalPersonEmployersChange = (event, index) => {
        const { name, value } = event.target;

        const newAccidentalPersonEmployers = [...accidentalPersonEmployers];
        newAccidentalPersonEmployers[index] = value;

        setPersonFields({ ...personFields, [name]: newAccidentalPersonEmployers });
    };

    const handleAddAccidentalPersonEmployer = () => {
        if (countOtherEmployers < 3) {
            setCountOtherEmployers(countOtherEmployers + 1);
        }
    };

    const handleRemoveAccidentalPersonEmployer = (index) => {
        if (countOtherEmployers > 1) {
            const newAccidentalPersonEmployers = [...accidentalPersonEmployers];
            newAccidentalPersonEmployers.splice(index, 1);

            setPersonFields({ ...personFields, accidentalPersonEmployers: newAccidentalPersonEmployers });
            setCountOtherEmployers(countOtherEmployers - 1);
        }
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [ACCIDENT_REPORT_STEPS.PERSONAL_INFORMATION]: true
            } );
            navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.ACCIDENT_INFORMATION}`);
        }

        setValidated(true);
    };

    return (
        <div className="accident-report-person">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer title={'Personalien der verunfallten Person'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Vorname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ accidentalPersonFirstName }
                                    name="accidentalPersonFirstName"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Vorname') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Nachname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ accidentalPersonLastName }
                                    name="accidentalPersonLastName"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Nachname') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Geburtsdatum*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="date"
                                    name="accidentalPersonBirthDate"
                                    value={ accidentalPersonBirthDate }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Geburtsdatum') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Zivilstand*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Select
                                    className={ inputColClassNames }
                                    required
                                    name="accidentalPersonCivilStatus"
                                    onChange={ handleFieldsChange }
                                    value={ accidentalPersonCivilStatus }
                                >
                                    <option value=''>Bitte auswählen...</option>
                                    <option value={ CIVIL_STATUS.DIVORCED }>geschieden</option>
                                    <option value={ CIVIL_STATUS.DISSOLVED_PARTNERSHIP }>in aufgelöster Partnerschaft</option>
                                    <option value={ CIVIL_STATUS.REGISTERED_PARTNERSHIP }>in eingetragener Partnerschaft</option>
                                    <option value={ CIVIL_STATUS.SINGLE }>ledig</option>
                                    <option value={ CIVIL_STATUS.MARRIED }>verheiratet</option>
                                    <option value={ CIVIL_STATUS.WIDOWED }>verwitwet</option>
                                    <option value={ CIVIL_STATUS.LIVING_SEPARATELY }>getrennt lebend</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Zivilstand') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Arbeitsort*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ accidentalPersonWorkplace }
                                    name="accidentalPersonWorkplace"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Arbeitsort') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col xxl={6} className='align-self-center'>
                                üblicher Arbeitsplatz der/des Veletzten (Amt/Amtsstelle/Betriebszweig)
                            </Col>
                        </Row>

                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'E-Mail und Telefon-Nr. verunfallte Person'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>E-Mail*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="email"
                                    value={ accidentalPersonEmail }
                                    name="accidentalPersonEmail"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('E-Mail') }
                                </Form.Control.Feedback>
                            </Col>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Telefon-Nr.*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ accidentalPersonPhone }
                                    name="accidentalPersonPhone"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Telefon-Nr.') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Personalien meldende resp. vorgesetzte Person'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Vorname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ reportingPersonFirstName }
                                    name="reportingPersonFirstName"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Vorname') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Nachname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ reportingPersonLastName }
                                    name="reportingPersonLastName"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Nachname') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Dienststelle*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Select
                                    className={ inputColClassNames }
                                    required
                                    name="reportingPersonDepartment"
                                    onChange={ handleFieldsChange }
                                    value={ reportingPersonDepartment }
                                >
                                    <option value=''>Bitte auswählen...</option>
                                    <option value={ DEPARTMENT.ABMH }>{ DEPARTMENT.ABMH }</option>
                                    <option value={ DEPARTMENT.ADA }>{ DEPARTMENT.ADA }</option>
                                    <option value={ DEPARTMENT.AFIN }>{ DEPARTMENT.AFIN }</option>
                                    <option value={ DEPARTMENT.AFU }>{ DEPARTMENT.AFU }</option>
                                    <option value={ DEPARTMENT.AGEM }>{ DEPARTMENT.AGEM }</option>
                                    <option value={ DEPARTMENT.AGI }>{ DEPARTMENT.AGI }</option>
                                    <option value={ DEPARTMENT.AGS }>{ DEPARTMENT.AGS }</option>
                                    <option value={ DEPARTMENT.AIO }>{ DEPARTMENT.AIO }</option>
                                    <option value={ DEPARTMENT.AJUV }>{ DEPARTMENT.AJUV }</option>
                                    <option value={ DEPARTMENT.AKS }>{ DEPARTMENT.AKS }</option>
                                    <option value={ DEPARTMENT.AKSO }>{ DEPARTMENT.AKSO }</option>
                                    <option value={ DEPARTMENT.ALW }>{ DEPARTMENT.ALW }</option>
                                    <option value={ DEPARTMENT.AMB }>{ DEPARTMENT.AMB }</option>
                                    <option value={ DEPARTMENT.ARP }>{ DEPARTMENT.ARP }</option>
                                    <option value={ DEPARTMENT.AS }>{ DEPARTMENT.AS }</option>
                                    <option value={ DEPARTMENT.ASI }>{ DEPARTMENT.ASI }</option>
                                    <option value={ DEPARTMENT.AVT }>{ DEPARTMENT.AVT }</option>
                                    <option value={ DEPARTMENT.AWA }>{ DEPARTMENT.AWA }</option>
                                    <option value={ DEPARTMENT.AWJF }>{ DEPARTMENT.AWJF }</option>
                                    <option value={ DEPARTMENT.BBZ_OL }>{ DEPARTMENT.BBZ_OL }</option>
                                    <option value={ DEPARTMENT.BBZ_SOGR }>{ DEPARTMENT.BBZ_SOGR }</option>
                                    <option value={ DEPARTMENT.BVS }>{ DEPARTMENT.BVS }</option>
                                    <option value={ DEPARTMENT.DS_BJD }>{ DEPARTMENT.DS_BJD }</option>
                                    <option value={ DEPARTMENT.DS_DBK }>{ DEPARTMENT.DS_DBK }</option>
                                    <option value={ DEPARTMENT.DS_DDI }>{ DEPARTMENT.DS_DDI }</option>
                                    <option value={ DEPARTMENT.DS_FD }>{ DEPARTMENT.DS_FD }</option>
                                    <option value={ DEPARTMENT.DS_VWD }>{ DEPARTMENT.DS_VWD }</option>
                                    <option value={ DEPARTMENT.GESA }>{ DEPARTMENT.GESA }</option>
                                    <option value={ DEPARTMENT.GV }>{ DEPARTMENT.GV }</option>
                                    <option value={ DEPARTMENT.HBA }>{ DEPARTMENT.HBA }</option>
                                    <option value={ DEPARTMENT.HPSZ }>{ DEPARTMENT.HPSZ }</option>
                                    <option value={ DEPARTMENT.IV }>{ DEPARTMENT.IV }</option>
                                    <option value={ DEPARTMENT.JUGA }>{ DEPARTMENT.JUGA }</option>
                                    <option value={ DEPARTMENT.KAPO }>{ DEPARTMENT.KAPO }</option>
                                    <option value={ DEPARTMENT.KDLV }>{ DEPARTMENT.KDLV }</option>
                                    <option value={ DEPARTMENT.KESB }>{ DEPARTMENT.KESB }</option>
                                    <option value={ DEPARTMENT.KFK }>{ DEPARTMENT.KFK }</option>
                                    <option value={ DEPARTMENT.KSO }>{ DEPARTMENT.KSO }</option>
                                    <option value={ DEPARTMENT.KSSO }>{ DEPARTMENT.KSSO }</option>
                                    <option value={ DEPARTMENT.KSTA }>{ DEPARTMENT.KSTA }</option>
                                    <option value={ DEPARTMENT.LJ }>{ DEPARTMENT.LJ }</option>
                                    <option value={ DEPARTMENT.MFK }>{ DEPARTMENT.MFK }</option>
                                    <option value={ DEPARTMENT.MISA }>{ DEPARTMENT.MISA }</option>
                                    <option value={ DEPARTMENT.OA }>{ DEPARTMENT.OA }</option>
                                    <option value={ DEPARTMENT.PA }>{ DEPARTMENT.PA }</option>
                                    <option value={ DEPARTMENT.PD }>{ DEPARTMENT.PD }</option>
                                    <option value={ DEPARTMENT.PKSO }>{ DEPARTMENT.PKSO }</option>
                                    <option value={ DEPARTMENT.RA }>{ DEPARTMENT.RA }</option>
                                    <option value={ DEPARTMENT.RRD }>{ DEPARTMENT.RRD }</option>
                                    <option value={ DEPARTMENT.SGV }>{ DEPARTMENT.SGV }</option>
                                    <option value={ DEPARTMENT.SMG }>{ DEPARTMENT.SMG }</option>
                                    <option value={ DEPARTMENT.SPG }>{ DEPARTMENT.SPG }</option>
                                    <option value={ DEPARTMENT.STA }>{ DEPARTMENT.STA }</option>
                                    <option value={ DEPARTMENT.STAWA }>{ DEPARTMENT.STAWA }</option>
                                    <option value={ DEPARTMENT.VSA }>{ DEPARTMENT.VSA }</option>
                                    <option value={ DEPARTMENT.WALDEGG }>{ DEPARTMENT.WALDEGG }</option>
                                    <option value={ DEPARTMENT.WARTENFELS }>{ DEPARTMENT.WARTENFELS }</option>
                                    <option value={ DEPARTMENT.ZB }>{ DEPARTMENT.ZB }</option>
                                    <option value={ DEPARTMENT.ZD }>{ DEPARTMENT.ZD }</option>
                                    <option value={ DEPARTMENT.ZZ }>{ DEPARTMENT.ZZ }</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Dienststelle') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col xxl={2} className='align-self-end text-end'></Col>
                            <Col xxl={4}></Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Arbeitsort*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ reportingPersonWorkplace }
                                    name="reportingPersonWorkplace"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Arbeitsort') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col xxl={2} className='align-self-end text-end'></Col>
                            <Col xxl={4}></Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'E-Mail und Telefon-Nr. meldende resp. vorgesetzte Person'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>E-Mail*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="email"
                                    value={ reportingPersonEmail }
                                    name="reportingPersonEmail"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('E-Mail') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Telefon-Nr.*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ reportingPersonPhone }
                                    name="reportingPersonPhone"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Telefon-Nr.') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'weitere Arbeitgeber'}>
                    <Container fluid>
                        {
                            [...Array(countOtherEmployers)].map((item, index) => {
                                return (
                                    <Fragment key={ index }>
                                        <Row { ...rowConfigurations } className={ index > 0 ? 'mt-4 pt-4 border-top border-1 align-items-start' : 'align-items-start' }>
                                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                                <Form.Label>weiterer Arbeitgeber</Form.Label>
                                            </Col>
                                            <Col { ...inputColConfigurations }>
                                                <Form.Control
                                                    className={ inputColClassNames }
                                                    type="text"
                                                    value={ accidentalPersonEmployers[index] }
                                                    name="accidentalPersonEmployers"
                                                    onChange={ (event) => handleAccidentalPersonEmployersChange(event, index) }
                                                />
                                            </Col>
                                        </Row>
                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col xxl={12} className='mt-2'>
                                                <Button disabled={ countOtherEmployers === 1 } variant="secondary" onClick={ () => handleRemoveAccidentalPersonEmployer(index) }>Löschen</Button>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                )
                            })
                        }
                    </Container>
                </FieldsContainer>
                <Container fluid className='mt-2 mb-4'>
                    <Row { ...rowConfigurations }>
                        <Col xxl={6}>
                            <Button variant="secondary" disabled={ countOtherEmployers >= 3 } onClick={ handleAddAccidentalPersonEmployer }>hinzufügen</Button>
                        </Col>
                        <Col  xxl={6} className='text-end text-muted align-self-center'>
                            Sie können maximal 3 Blöcke befüllen!
                        </Col>
                    </Row>
                </Container>

                <FieldsContainer title={'Angaben zum Unfall'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Unfalldatum*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="date"
                                    name="accidentDetailsDate"
                                    value={ accidentDetailsDate }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Unfalldatum') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Uhrzeit*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="time"
                                    name="accidentDetailsTime"
                                    value={ accidentDetailsTime }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Uhrzeit') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Betriebsunfall*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="Ja"
                                    name="accidentDetailsIndustrialAccident"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ accidentDetailsIndustrialAccident === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="Nein"
                                    name="accidentDetailsIndustrialAccident"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ accidentDetailsIndustrialAccident === YES_NO_STATELESS.NO }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Unfall auf Arbeitsweg*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="Ja"
                                    name="accidentDetailsWayToWork"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ accidentDetailsWayToWork === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="Nein"
                                    name="accidentDetailsWayToWork"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ accidentDetailsWayToWork === YES_NO_STATELESS.NO }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Unfallort (PLZ / Ortsname)*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ accidentDetailsZipCity }
                                    name="accidentDetailsZipCity"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Unfallort') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Strasse und Haus-Nr.*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    required
                                    type="text"
                                    value={ accidentDetailsStreetAndNr }
                                    name="accidentDetailsStreetAndNr"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Strasse und Haus-Nr.') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Hergang*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    as="textarea"
                                    rows={ 3 }
                                    required
                                    value={ accidentDetailsCourseOfEvents }
                                    name="accidentDetailsCourseOfEvents"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Hergang') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col xxl={2} className='align-self-end text-end'></Col>
                            <Col xxl={4}></Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Wurde ein Polizeirapport erstellt?*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="Ja"
                                    name="accidentDetailsPoliceReportCreated"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ accidentDetailsPoliceReportCreated === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="Nein"
                                    name="accidentDetailsPoliceReportCreated"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ accidentDetailsPoliceReportCreated === YES_NO_STATELESS.NO }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>

                            <Col xxl={2} className='align-self-end text-end'></Col>
                            <Col xxl={4}></Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Welche Polizeistelle?</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ accidentDetailsWhichPoliceStation }
                                    name="accidentDetailsWhichPoliceStation"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col xxl={2} className='align-self-end text-end'></Col>
                            <Col xxl={4}></Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
}

export default AccidentReportPerson;