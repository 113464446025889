import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../reducer/base/base.context';
import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { formatDate, formatNumberWith2DecimalsAndThousandSeparator, getPreviousSickReportStep } from '../../utils/common/functions';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';
import { IV_EARLY_DETECTION, RECOVERY_BACK_TO_WORK, YES_NO_STATELESS } from '../../utils/consts/radioButtons';
import { SICK_REPORT_TYPE } from '../../utils/consts/selects';
import { mapBooleanToYesNo, mapCivilStatus, mapEmployment, mapGender, mapIvEarlyDetection, mapPhysicalActivity, mapRecoveryBackToWork, mapSickReportType, mapStress, mapYesNoStateless } from '../../utils/mappingFunctions';
import { getFileFromDatabase, openDatabase } from '../../utils/indexedDbHelperFunctions';
import { postRequest } from '../../utils/axiosHelperFunctions';


const SickReportSummary = () => {
    const submitButtonRef = useRef(null);
    let navigate = useNavigate();
    const [sent, setSent] = useState(false);

    const {
        currentStep,
        setCurrentStep,
        currentSickReportType,
        startupFields,
        illnessCaseInformationFields,
        illnessCaseDetailsFields,
        employeeInformationFields,
        employmentDetailsFields,
        insurancePensionInformationFields,
        illnessCaseManagementFields,
        disabilityManagementFields,
        stepsValidated
    } = useContext(SickReportContext);

    const {
        setFooterNextAction,
        setFooterNextButtonText,
        setFooterBackAction
    } = useContext(BaseContext);



    useEffect(() => {
        setCurrentStep(SICK_REPORT_STEPS(currentSickReportType).SUMMARY);

        return () => {
            setFooterNextButtonText('Weiter');
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentStep) {
            const prevStep = getPreviousSickReportStep(currentSickReportType, currentStep);

            if (prevStep) {
                if (!stepsValidated[prevStep]) {
                    navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`);
                } else {
                    setFooterNextButtonText('Senden');
                    setFooterNextAction(() => submitButtonRef.current.click());
                    setFooterBackAction(() => navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`));
                }
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    useEffect(() => {
        const sendData = async () => {
            const formData = await getFormdataBySickReportType();
            console.log('formData', formData);

            postRequest('formular', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error);
            });
        };

        if (sent) {
            sendData();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sent]);

    const getFormdataBySickReportType = async () => {
        const formData = new FormData();
        const documentIds = [];

        formData.append('startupFields', JSON.stringify(startupFields));

        switch (currentSickReportType) {
            case SICK_REPORT_TYPE.ADMINISTRATION_AND_CANTONAL_SCHOOLS:
                formData.append('illnessCaseInformationFields', JSON.stringify(illnessCaseInformationFields));
                break;
            case SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS:
                formData.append('illnessCaseDetailsFields', JSON.stringify({ ...illnessCaseInformationFields, ...illnessCaseDetailsFields }));
                break;
            case SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS:
            case SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL:
                formData.append('employeeInformationFields', JSON.stringify(employeeInformationFields));
                formData.append('employmentDetailsFields', JSON.stringify(employmentDetailsFields));
                formData.append('insurancePensionInformationFields', JSON.stringify(insurancePensionInformationFields));

                const { dgoRegulation } = insurancePensionInformationFields;
                documentIds.push(dgoRegulation?.id);
                break;
            default:
                return null;
        }

        formData.append('illnessCaseManagementFields', JSON.stringify(illnessCaseManagementFields));
        formData.append('disabilityManagementFields', JSON.stringify(disabilityManagementFields));
        
        const { medicalCertificates } = illnessCaseManagementFields;
        for (let i = 0; i < medicalCertificates.length; i++) {
            const {
                certificate: {
                    id
                }
            } = medicalCertificates[i];
            documentIds.push(id);
        }

        console.log('documentIds', documentIds);
        try {
            const db = await openDatabase();
            for (let i = 0; i < documentIds.length; i++) {
                const file = await getFileFromDatabase(db, documentIds[i]);
                formData.append('files', file);
            }
        } catch (error) {
            console.log('Fehler beim laden der Files: ', error);
        } finally {
            return formData;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setSent(true);
    };

    return (
        <div className="sick-report-summary">
            <Form noValidate onSubmit={ handleSubmit } >
                <FieldsContainer title={''} isWithHeadline={ false }>
                    <Container fluid>
                        <Row>
                            <Col className='text-end me-4'>
                                <span>Empfänger</span>
                            </Col>
                            <Col>
                                <span>Personalamt</span><br />
                                <span>Rathaus</span><br />
                                <span>Barfüssergasse 24</span><br />
                                <span>4509 Solothurn</span>
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                {
                    [startupFields].map(
                        (
                            {
                                messageDate
                            },
                            index
                        ) => {
                            return (
                                <Fragment key={ index }>
                                    <FieldsContainer title={'Formular Krankheitsmeldung'}>
                                        <Container fluid>
                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Datum Meldung</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { formatDate(messageDate) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Formular</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapSickReportType(currentSickReportType) }
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </FieldsContainer>
                                </Fragment>
                            )
                        }

                    )
                }

                {
                    (currentSickReportType === SICK_REPORT_TYPE.ADMINISTRATION_AND_CANTONAL_SCHOOLS || currentSickReportType === SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS) &&
                        [illnessCaseInformationFields].map (
                            (
                                {
                                    firstName,
                                    lastName,
                                    role,
                                    personId,
                                    ahvSvNumber,
                                    employment,
                                    workloadInPercent,
                                    entryDate,
                                    department,
                                    workplace,
                                    telephonePrivate,
                                    emailPrivate
                                },
                                index
                            ) => {
                                return (
                                    <Fragment key={ index }>
                                        <FieldsContainer title={'Angeben zur erkrankten Person'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Vorname</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { firstName }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Nachname</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { lastName }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Funktion</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { role }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Personen-ID</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { personId }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                {
                                                    (ahvSvNumber && ahvSvNumber.length > 0 && ahvSvNumber.indexOf('_') === -1) &&
                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>AHV/SV Nummer</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { ahvSvNumber }
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                }
                                                

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Anstellung</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { mapEmployment(employment) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Pensum in %</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { workloadInPercent }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Eintrittsdatum</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatDate(entryDate) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Dienststelle</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { department }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Arbeitsort</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { workplace }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>

                                        {
                                            currentSickReportType === SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS &&
                                                [illnessCaseDetailsFields].map(
                                                    (
                                                        {
                                                            monthlyBaseSalary,
                                                            monthlyAdditionalSalary,
                                                            yearlySalaryByHourlyRate,
                                                            yearlySalaryVacationCompensation,
                                                            monthlyAllowance,
                                                            yearlyInconviniences,
                                                            yearlyLebo,
                                                            yearlyBonus,
                                                            bankDetails,
                                                            ibanNr,
                                                            postAccount,
                                                            accountNr
                                                        },
                                                        index
                                                    ) => {
                                                        return (
                                                            <Fragment key={ index }>
                                                                <FieldsContainer title={'Angaben zum Lohn'}>
                                                                    <Container fluid>
                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>Vertraglicher Grundlohn pro Monat Brutto inkl. Teuerungszulage</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { formatNumberWith2DecimalsAndThousandSeparator(monthlyBaseSalary) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>Besondere vertragliche Leistungen pro Monat Brutto (besitzstand etc.)</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { formatNumberWith2DecimalsAndThousandSeparator(monthlyAdditionalSalary) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto (bitte Details "Stunden/Ansatz" via Mail)</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { formatNumberWith2DecimalsAndThousandSeparator(yearlySalaryByHourlyRate) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto Ferien- & Feiertagsentschädigung</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { formatNumberWith2DecimalsAndThousandSeparator(yearlySalaryVacationCompensation) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>Kinder- / Familien- / Ausbildungszulagen pro Monat</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { formatNumberWith2DecimalsAndThousandSeparator(monthlyAllowance) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>Inkonvenienzen (gemäss GAV); 12 Mt. Periode (bitte Details via Mail)</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { formatNumberWith2DecimalsAndThousandSeparator(yearlyInconviniences) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>LEBO; 12 Mt. Periode</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { formatNumberWith2DecimalsAndThousandSeparator(yearlyLebo) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>13. Monatslohn / Gratifikation pro Jahr</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { formatNumberWith2DecimalsAndThousandSeparator(yearlyBonus) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>
                                                                </FieldsContainer>

                                                                <FieldsContainer title={'Zahlungsverbindung der/des MA'}>
                                                                    <Container fluid>
                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>Bankverbindung</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { mapBooleanToYesNo(bankDetails) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        {
                                                                            bankDetails &&
                                                                                <Row>
                                                                                    <Col className='text-end me-4'>
                                                                                        <span>IBAN-Nr.</span>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <span>
                                                                                            { ibanNr }
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row> 
                                                                        }

                                                                        <Row>
                                                                            <Col className='text-end me-4'>
                                                                                <span>Postkonto</span>
                                                                            </Col>
                                                                            <Col>
                                                                                <span>
                                                                                    { mapBooleanToYesNo(postAccount) }
                                                                                </span>
                                                                            </Col>
                                                                        </Row>

                                                                        {
                                                                            postAccount &&
                                                                                <Row>
                                                                                    <Col className='text-end me-4'>
                                                                                        <span>Konto-Nr.</span>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <span>
                                                                                            { accountNr }
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row> 
                                                                        }
                                                                    </Container>
                                                                </FieldsContainer>
                                                            </Fragment>
                                                        )
                                                    })
                                        }

                                        <FieldsContainer title={'Erreichbarkeit der erkrankten Person während der Krankheitsabwesenheit'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Tel. Nr. MA/Privat</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { telephonePrivate }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>E-Mail MA/Privat</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { emailPrivate }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>
                                    </Fragment>
                                )
                            }

                        )
                }

                {
                    (currentSickReportType === SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS || currentSickReportType === SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL) &&
                        [employeeInformationFields].map(
                            (
                                {
                                    firstName,
                                    lastName,
                                    birthDate,
                                    street,
                                    houseNr,
                                    zip,
                                    city,
                                    nationality,
                                    civilStatus,
                                    gender,
                                    ahvSvNumber,
                                    bankDetails,
                                    ibanNr,
                                    postAccount,
                                    accountNr,
                                    telephonePrivate,
                                    emailPrivate
                                },
                                index
                            ) => {
                                return (
                                    <Fragment key={ index }>
                                        <FieldsContainer title={'Angeben zur erkrankten Person'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Vorname</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { firstName }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Nachname</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { lastName}
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Geburtsdatum</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatDate(birthDate) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Strasse</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { street }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Haus-Nr.</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { houseNr }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Postleitzahl</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { zip }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Ort</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { city }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Nationalität</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { nationality }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Zivilstand</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { mapCivilStatus(civilStatus) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Geschlecht</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { mapGender(gender) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>AHV/SV Nummer</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ahvSvNumber }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>

                                        <FieldsContainer title={'Zahlungsverbindung der/des MA'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Bankverbindung</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { mapBooleanToYesNo(bankDetails) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                {
                                                    bankDetails &&
                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>IBAN-Nr.</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { ibanNr }
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                }

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Postkonto</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { mapBooleanToYesNo(postAccount) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                {
                                                    postAccount &&
                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>Konto-Nr.</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { accountNr }
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                }
                                            </Container>
                                        </FieldsContainer>

                                        <FieldsContainer title={'Erreichbarkeit der erkrankten Person während der Krankheitsabwesenheit'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Tel.-Nr-MA/Privat</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { telephonePrivate }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>E-Mail MA/Privat</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { emailPrivate }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>
                                    </Fragment>
                                )
                            })
                }

                {
                    (currentSickReportType === SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS || currentSickReportType === SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL) &&
                        [employmentDetailsFields].map(
                            (
                                {
                                    role,
                                    workplace,
                                    zip,
                                    school,
                                    entryDate,
                                    employment,
                                    temporaryYearOfService,
                                    endOfTemoraryEmployment,
                                    otherEmployments,
                                    weeklyPensumInPercent,
                                    weeklyLessons,
                                    weeklyLessonsInFullPensum,
                                    monthlyBaseSalary,
                                    monthlyAdditionalSalary,
                                    yearlySalaryByHourlyRate,
                                    yearlySalaryVacationCompensation,
                                    monthlyAllowance,
                                    yearlyInconviniences,
                                    yearlyLebo,
                                    yearlyBonus
                                },
                                index
                            ) => {
                                return (
                                    <Fragment key={ index }>
                                        <FieldsContainer title={'Angaben zur Arbeitsstelle'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Funktion</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { role }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Arbeitsort</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { workplace }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Postleitzahl</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { zip }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Schulhaus / Schule</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { school }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Eintrittsdaum</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatDate(entryDate) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Anstellung</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { mapEmployment(employment) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>in Dienstjahr</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { temporaryYearOfService }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Ende der befristeten Anstellung</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatDate(endOfTemoraryEmployment) }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>

                                        {
                                            otherEmployments.map(
                                                (
                                                    {
                                                        employment,
                                                        temporaryYearOfService,
                                                        endOfTemoraryEmployment
                                                    },
                                                    index
                                                ) => {
                                                return (
                                                    <Fragment key={ index }>
                                                        <FieldsContainer title={'Angabe zu weiteren Anstellungen'} isWithHeadline={ index === 0 }>
                                                            <Container fluid>
                                                                <Row>
                                                                    <Col className='text-end me-4'>
                                                                        <span>Anstellung</span>
                                                                    </Col>
                                                                    <Col>
                                                                        <span>
                                                                            { mapEmployment(employment) }
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className='text-end me-4'>
                                                                        <span>in Dienstjahr</span>
                                                                    </Col>
                                                                    <Col>
                                                                        <span>
                                                                            { temporaryYearOfService }
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className='text-end me-4'>
                                                                        <span>Ende der befristeten Anstellung</span>
                                                                    </Col>
                                                                    <Col>
                                                                        <span>
                                                                            { formatDate(endOfTemoraryEmployment) }
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </FieldsContainer>
                                                    </Fragment>
                                                )
                                            })
                                        }

                                        <FieldsContainer title={'Beschäftigungsgrad / Pensum'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Gesamt Pensum in % je Woche</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { weeklyPensumInPercent }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Gesamt Pensum in Lektionen je Woche</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { weeklyLessons }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Anzahl Lektionen/Woche in einem Vollpensum</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { weeklyLessonsInFullPensum }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>

                                        <FieldsContainer title={'Angaben zum Lohn'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Vertraglicher Grundlohn pro Monat Brutto inkl. Teuerungszulage</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatNumberWith2DecimalsAndThousandSeparator(monthlyBaseSalary) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Besondere vertragliche Leistungen pro Monat Brutto (besitzstand etc.)</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatNumberWith2DecimalsAndThousandSeparator(monthlyAdditionalSalary) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto (bitte Details "Stunden/Ansatz" via Mail)</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatNumberWith2DecimalsAndThousandSeparator(yearlySalaryByHourlyRate) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto Ferien- & Feiertagsentschädigung</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatNumberWith2DecimalsAndThousandSeparator(yearlySalaryVacationCompensation) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Kinder- / Familien- / Ausbildungszulagen pro Monat</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatNumberWith2DecimalsAndThousandSeparator(monthlyAllowance) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Inkonvenienzen (gemäss GAV); 12 Mt. Periode (bitte Details via Mail)</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatNumberWith2DecimalsAndThousandSeparator(yearlyInconviniences) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>LEBO; 12 Mt. Periode</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatNumberWith2DecimalsAndThousandSeparator(yearlyLebo) }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>13. Monatslohn / Gratifikation pro Jahr</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { formatNumberWith2DecimalsAndThousandSeparator(yearlyBonus) }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>
                                    </Fragment>
                                )
                            })
                }

                {
                    (currentSickReportType === SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS || currentSickReportType === SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL) &&
                        [insurancePensionInformationFields].map(
                            (
                                {
                                    lfzRegulation,
                                    dgoRegulation: {
                                        name
                                    },
                                    withFirstInsuranceSolutionVseg,
                                    bankDetails,
                                    ibanNr,
                                    postAccount,
                                    accountNr,
                                    ktgName,
                                    ktgCity,
                                    ktgPolicyNumber,
                                    ktgEmail,
                                    ktgWaitingPeriodInDays,
                                    ktgBenefitLevelInPercent,
                                    ktgPerformanceDurationInDays,
                                    bvgName,
                                    bvgCity,
                                    bvgContractNumber,
                                    bvgWaitingPeriodForPremiiumReductionInDays,
                                    ktgv,
                                    ktgvperformCaseManagement
                                },
                                index
                            ) => {
                                return (
                                    <Fragment key={ index }>
                                        {
                                            (currentSickReportType === SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL) &&
                                                    <FieldsContainer title={''} isWithHeadline={ false }>
                                                    <Container fluid>
                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>Gilt für die angestellte/versicherte Person in Ihrer DGO sinngemäss der GAV des Kanton Solothurn (LFZ-Regelung)?</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { mapYesNoStateless(lfzRegulation) }
                                                                </span>
                                                            </Col>
                                                        </Row>

                                                        {
                                                            lfzRegulation === YES_NO_STATELESS.NO &&
                                                                <Fragment>
                                                                    {
                                                                        name &&
                                                                            <Row>
                                                                                <Col className='text-end me-4'>
                                                                                    <span>DGO-Regelung</span>
                                                                                </Col>
                                                                                <Col>
                                                                                    <span>
                                                                                        { name }
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                    }
                                                                    
                                                                </Fragment>
                                                                
                                                        }

                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>Mit Erstversicherungslösung VSEG?</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { mapYesNoStateless(withFirstInsuranceSolutionVseg) }
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </FieldsContainer>
                                        }

                                        {
                                            (currentSickReportType === SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS) &&
                                                <FieldsContainer title={'KTG Versicherung Situation'}>
                                                    <Container fluid>
                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>Besteht für die erkrankte Volksschullehrperson bei der Gemeinde eine zusätzliche Krankentaggeldversicherung (KTGV)?</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { mapYesNoStateless(ktgv) }
                                                                </span>
                                                            </Col>
                                                        </Row>

                                                        {
                                                            ktgv === YES_NO_STATELESS.YES &&
                                                                <Row>
                                                                    <Col className='text-end me-4'>
                                                                        <span>Wird diese KTGV ein Case-Management durchführen?</span>
                                                                    </Col>
                                                                    <Col>
                                                                        <span>
                                                                            { mapYesNoStateless(ktgvperformCaseManagement) }
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                        }

                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>Mit Erstversicherungslösung VSEG?</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { mapYesNoStateless(withFirstInsuranceSolutionVseg) }
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </FieldsContainer>
                                        }

                                        {
                                            withFirstInsuranceSolutionVseg === YES_NO_STATELESS.NO &&
                                                <FieldsContainer title={'Kontoverbindung'}>
                                                    <Container fluid>
                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>Bankverbindung</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { mapBooleanToYesNo(bankDetails) }
                                                                </span>
                                                            </Col>
                                                        </Row>

                                                        {
                                                            bankDetails &&
                                                                <Row>
                                                                    <Col className='text-end me-4'>
                                                                        <span>IBAN-Nr.</span>
                                                                    </Col>
                                                                    <Col>
                                                                        <span>
                                                                            { ibanNr }
                                                                        </span>
                                                                    </Col>
                                                                </Row> 
                                                        }

                                                        <Row>
                                                            <Col className='text-end me-4'>
                                                                <span>Postkonto</span>
                                                            </Col>
                                                            <Col>
                                                                <span>
                                                                    { mapBooleanToYesNo(postAccount) }
                                                                </span>
                                                            </Col>
                                                        </Row>

                                                        {
                                                            postAccount &&
                                                                <Row>
                                                                    <Col className='text-end me-4'>
                                                                        <span>Konto-Nr.</span>
                                                                    </Col>
                                                                    <Col>
                                                                        <span>
                                                                            { accountNr }
                                                                        </span>
                                                                    </Col>
                                                                </Row> 
                                                        }
                                                </Container>
                                            </FieldsContainer>
                                        }

                                        <FieldsContainer title={'KTG Erstversicherer'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Name</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgName }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Ort</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgCity }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Policen-Nr.</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgPolicyNumber }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>E-Mail</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgEmail }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Wartefrist in Tagen</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgWaitingPeriodInDays }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Leistungshöhe in %</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgBenefitLevelInPercent }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Leistungsdauer in Tagen</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgPerformanceDurationInDays }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>

                                        <FieldsContainer title={'Pensionskasse / BVG'}>
                                            <Container fluid>
                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Name</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { bvgName }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Ort</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { bvgCity }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Vertrags-Nr.</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { bvgContractNumber }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Wartefrist Prämienbefreiung in Tagen</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { bvgWaitingPeriodForPremiiumReductionInDays }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Wartefrist in Tagen</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgWaitingPeriodInDays }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Leistungshöhe in %</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgBenefitLevelInPercent }
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className='text-end me-4'>
                                                        <span>Leistungsdauer in Tagen</span>
                                                    </Col>
                                                    <Col>
                                                        <span>
                                                            { ktgPerformanceDurationInDays }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FieldsContainer>
                                    </Fragment>
                                )
                            })
                }

                {
                    [illnessCaseManagementFields].map(
                        (
                            {
                                caseIllnessStartDate,
                                caseRelapse,
                                caseAbsenceLongTerm,
                                casePregnancyCalculatedDateOfBirth,
                                caseRemark,
                                medicalCertificates,
                                superiorFirstName,
                                superiorLastName,
                                superiorTelephone,
                                superiorEmail
                            },
                            index
                        ) => {
                            return (
                                <Fragment key={ index }>
                                    <FieldsContainer title={'Angeben zum Fall'}>
                                        <Container fluid>
                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Krankheitsbeginn</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { formatDate(caseIllnessStartDate) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Handelt es sich um einen Rückfall?</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapYesNoStateless(caseRelapse) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Abwesenheit voraussichtlich längerfristig</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapYesNoStateless(caseAbsenceLongTerm) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            {
                                                (casePregnancyCalculatedDateOfBirth && casePregnancyCalculatedDateOfBirth.length > 0) &&
                                                    <Row>
                                                        <Col className='text-end me-4'>
                                                            <span>Erechneter Geburtstermin</span>
                                                        </Col>
                                                        <Col>
                                                            <span>
                                                                { formatDate(casePregnancyCalculatedDateOfBirth) }
                                                            </span>
                                                        </Col>
                                                    </Row>
                                            }

                                            {
                                                (caseRemark && caseRemark.length > 0) &&
                                                    <Row>
                                                        <Col className='text-end me-4'>
                                                            <span>Bemerkung / zusätzliche Angaben</span>
                                                        </Col>
                                                        <Col>
                                                            <span>
                                                                { caseRemark }
                                                            </span>
                                                        </Col>
                                                    </Row>
                                            }
                                            
                                        </Container>
                                    </FieldsContainer>

                                    {
                                        medicalCertificates.map(
                                            (
                                                {
                                                    certificate: {
                                                        name
                                                    },
                                                    startDate,
                                                    endDate,
                                                    incapacityInPercent
                                                },
                                                index
                                            ) => {
                                            return (
                                                <Fragment key={ index }>
                                                    <FieldsContainer title={'Arztzeugnis'} isWithHeadline={ index === 0 }>
                                                        <Container fluid>
                                                            <Row>
                                                                <Col className='text-end me-4'>
                                                                    <span>Arztzeugnis vom</span>
                                                                </Col>
                                                                <Col>
                                                                    <span>
                                                                        { formatDate(startDate) }
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className='text-end me-4'>
                                                                    <span>Gültig bis</span>
                                                                </Col>
                                                                <Col>
                                                                    <span>
                                                                        { formatDate(endDate) }
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className='text-end me-4'>
                                                                    <span>Arbeitsunfähigkeit in %</span>
                                                                </Col>
                                                                <Col>
                                                                    <span>
                                                                        { incapacityInPercent }
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className='text-end me-4'>
                                                                    <span>Arztzeugnis</span>
                                                                </Col>
                                                                <Col>
                                                                    <span>
                                                                        { name }
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </FieldsContainer>
                                                </Fragment>
                                            )
                                        })
                                    }

                                    <FieldsContainer title={'Daten der vorgesetzten - / für die Meldung zuständigen Person'}>
                                        <Container fluid>
                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Vorname</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { superiorFirstName }
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Nachname</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { superiorLastName }
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Tel.-Nr. Arbeitsplatz</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { superiorTelephone }
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>E-Mail Arbeitsplatz</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { superiorEmail }
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </FieldsContainer>
                                </Fragment>
                            )
                        }

                    )
                }

                {
                    [disabilityManagementFields].map(
                        (
                            {
                                physicalActivity,
                                stress,
                                ivEarlyDetection,
                                whenAndByWhom,
                                disclosuresEmployerDoubtsIncapacity,
                                disclosuresEmployerDoubtsIncapacityDesc,
                                disclosuresEmployerIvEarlyDetectionIsDone,
                                disclosuresEmployerIvEarlyDetectionIsDoneDesc,
                                disclosuresEmployerBenefitsOtherInsurances,
                                disclosuresEmployerBenefitsOtherInsurancesDesc,
                                recoveryBackToWork,
                                recoveryBackToWorkPercentage
                            },
                            index
                        ) => {
                            return (
                                <Fragment key={ index }>
                                    <FieldsContainer title={'Angeben zur körperlichen Belastung'}>
                                        <Container fluid>
                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Körperliche Tätigkeit</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapPhysicalActivity(physicalActivity) }
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Belastung</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapStress(stress) }
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </FieldsContainer>

                                    <FieldsContainer title={'IV-Früherfassung'}>
                                        <Container fluid>
                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>IV-Früherfassung</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapIvEarlyDetection(ivEarlyDetection) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            {
                                                ivEarlyDetection === IV_EARLY_DETECTION.DONE &&
                                                    <Row>
                                                        <Col className='text-end me-4'>
                                                            <span>Wann und durch wen ist die Meldung erfolgt?</span>
                                                        </Col>
                                                        <Col>
                                                            <span>
                                                                { whenAndByWhom }
                                                            </span>
                                                        </Col>
                                                    </Row>
                                            }
                                        </Container>
                                    </FieldsContainer>

                                    <FieldsContainer title={'Angaben Arbeitgeber'}>
                                        <Container fluid>
                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Bestehen Ihrerseits Zweifel an der Arbeitsunfähigkeit?</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapYesNoStateless(disclosuresEmployerDoubtsIncapacity) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            {
                                                disclosuresEmployerDoubtsIncapacity === YES_NO_STATELESS.YES &&
                                                    <Row>
                                                        <Col className='text-end me-4'>
                                                            <span>Welche Zweifel bestehen?</span>
                                                        </Col>
                                                        <Col>
                                                            <span>
                                                                { disclosuresEmployerDoubtsIncapacityDesc }
                                                            </span>
                                                        </Col>
                                                    </Row>
                                            }

                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Ist eine IV-Anmeldung erfolgt</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapYesNoStateless(disclosuresEmployerIvEarlyDetectionIsDone) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            {
                                                disclosuresEmployerIvEarlyDetectionIsDone === YES_NO_STATELESS.YES &&
                                                    <Row>
                                                        <Col className='text-end me-4'>
                                                            <span>Wann und für was?</span>
                                                        </Col>
                                                        <Col>
                                                            <span>
                                                                { disclosuresEmployerIvEarlyDetectionIsDoneDesc }
                                                            </span>
                                                        </Col>
                                                    </Row>
                                            }

                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Bezieht der Arbeitnehmende bereits Leistungen anderer Versicherungen?</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapYesNoStateless(disclosuresEmployerBenefitsOtherInsurances) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            {
                                                disclosuresEmployerBenefitsOtherInsurances === YES_NO_STATELESS.YES &&
                                                    <Row>
                                                        <Col className='text-end me-4'>
                                                            <span>Welche Leistungsart / Gesellschaft</span>
                                                        </Col>
                                                        <Col>
                                                            <span>
                                                                { disclosuresEmployerBenefitsOtherInsurancesDesc }
                                                            </span>
                                                        </Col>
                                                    </Row>
                                            }
                                        </Container>
                                    </FieldsContainer>

                                    <FieldsContainer title={'Genesungsmeldung / Teilgenesungsmeldung'}>
                                        <Container fluid>
                                            <Row>
                                                <Col className='text-end me-4'>
                                                    <span>Versicherte Person hat Arbeit wieder aufgenommen</span>
                                                </Col>
                                                <Col>
                                                    <span>
                                                        { mapRecoveryBackToWork(recoveryBackToWork) }
                                                    </span>
                                                </Col>
                                            </Row>

                                            {
                                                recoveryBackToWork === RECOVERY_BACK_TO_WORK.PARTLY &&
                                                    <Row>
                                                        <Col className='text-end me-4'>
                                                            <span>Zu wieviel %?</span>
                                                        </Col>
                                                        <Col>
                                                            <span>
                                                                { recoveryBackToWorkPercentage }
                                                            </span>
                                                        </Col>
                                                    </Row>
                                            }
                                        </Container>
                                    </FieldsContainer>
                                </Fragment>
                            )
                        }

                    )
                }

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
};

export default SickReportSummary;
