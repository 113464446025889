import InfoIcon from '../info-icon/info-icon.component';

import './info-icon-field.styles.scss';

const InfoIconField = (
    {
        children: inputField,
        infoIconId,
        infoIconText,
        inputType
    }
) => {
    return (
        <div className={ `info-icon-field __${ inputType }` }>
            { inputField }
            <InfoIcon
                id={ infoIconId }
                text={ infoIconText }
            />
        </div>
    )
}

export default InfoIconField;