import { ACCIDENT_REPORT_STEPS, SICK_REPORT_STEPS } from "./consts/steps.consts";

const createBaseData = () => {
    const baseData = {
        footerIsWithActions: false,
        headerFormName: null,
        isFooterAccidentCopyright: true,
    }
    const baseDataFromStorage = sessionStorage.getItem('baseData');

    if (baseDataFromStorage) {
        return;
    }
    sessionStorage.setItem('baseData', JSON.stringify(baseData));
}

const createReportFormData = () => {
    const formData = {
        accidentReport: {
            startup: {},
            person: {},
            accident: {},
            special: {},
            stepsValidatedStore: {}
        },
        sickReport: {
            currentSickReportType: '',
            startup: {},
            illnessCaseInformation: {},
            illnessCaseDetails: {},
            employeeInformation: {},
            employmentDetails: {},
            insurancePensionInformation: {},
            illnessCaseManagement: {},
            disabilityManagement: {},
            stepsValidatedStore: {}
        }
    }
    const formDataFromStorage = sessionStorage.getItem('reportFormData');

    if (formDataFromStorage) {
        return;
    }
    sessionStorage.setItem('reportFormData', JSON.stringify(formData));
}

const setReportData = (step, data, isSickReport) => {
    let formData = sessionStorage.getItem('reportFormData');
    if (!formData) {
        createReportFormData();
        formData = sessionStorage.getItem('reportFormData');
    }

    formData = JSON.parse(formData);

    if (isSickReport) {
        formData.sickReport[step] = data;
    } else {
        formData.accidentReport[step] = data;
    }

    sessionStorage.setItem('reportFormData', JSON.stringify(formData));
}

export const clearFormData = (sessionData) => {
    sessionStorage.clear();
}

export const getAccidentReportData = (step = ACCIDENT_REPORT_STEPS.SUMMARY) => {
    let data = sessionStorage.getItem('reportFormData');
    if (!data) {
        createReportFormData();
        data = sessionStorage.getItem('reportFormData');
    }

    data = JSON.parse(data);

    let accidentReportData = data.accidentReport;

    if (step === ACCIDENT_REPORT_STEPS.SUMMARY) {
        return accidentReportData;
    }

    return accidentReportData[step];
};

//EXPORTS
export const getSickReportData = (step = SICK_REPORT_STEPS().SUMMARY) => {
    let data = sessionStorage.getItem('reportFormData');
    if (!data) {
        createReportFormData();
        data = sessionStorage.getItem('reportFormData');
    }

    data = JSON.parse(data);

    let sickReportData = data.sickReport;

    if (step === SICK_REPORT_STEPS().SUMMARY) {
        return sickReportData;
    }

    return sickReportData[step];
};

export const setAccidentReportData = (step, data) => {
    setReportData(step, data, false);
}

export const setSickReportData = (step, data) => {
    setReportData(step, data, true);
}

export const getBaseData = () => {
    let data = sessionStorage.getItem('baseData');
    if (!data) {
        createBaseData();
        data = sessionStorage.getItem('baseData');
    }

    data = JSON.parse(data);

    return data;
}

export const setBaseData = (name, data) => {
    let baseData = sessionStorage.getItem('baseData');
    if (!baseData) {
        createBaseData();
        baseData = sessionStorage.getItem('baseData');
    }

    baseData = JSON.parse(baseData);

    baseData[name] = data;

    sessionStorage.setItem('baseData', JSON.stringify(baseData));
}

export const createObjectUrl = (file) => {
    return URL.createObjectURL(file);
}

export const revokeObjectUrl = (url) => {
    URL.revokeObjectURL(url);
}

export const objectUrlExists = (url) => {
    try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, false);
        xhr.send();
        return xhr.status !== 404;
    } catch (e) {
        return false;
    }
}
