import { Routes, Route } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';
import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import SickReportStartup from '../../components/sick-report-startup/sick-report-startup.component';
import SickReportIllnessCaseInformation from '../../components/sick-report-illnessCaseInformation/sick-report-illnessCaseInformation.component';
import SickReportEmployeeInformation from '../../components/sick-report-employeeInformation/sick-report-employeeInformation.component';
import SickReportEmploymentDetails from '../../components/sick-report-employmentDetails/sick-report-employmentDetails.component';
import SickReportInsurancePensionInformation from '../../components/sick-report-insurancePensionInformation/sick-report-insurancePensionInformation.component';
import SickReportIllnessCaseManagement from '../../components/sick-report-illnessCaseManagement/sick-report-illnessCaseManagement.component';
import SickReportDisabilityManagement from '../../components/sick-report-disabilityManagement/sick-report-disabilityManagement.component';
import SickReportSummary from '../../components/sick-report-summary/sick-report-summary.component';

const SickReport = () => {
    const {
        currentSickReportType
    } = useContext(SickReportContext);

    useEffect(() => {
        document.title = 'Kanton Solothurn - Krankheitsmeldung an das Personalamt';

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Routes>
            <Route path={ '1' } element={ <SickReportStartup /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).STARTUP) } element={ <SickReportStartup /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_INFORMATION) } element={ <SickReportIllnessCaseInformation isDetail={ false } /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_DETAILS) } element={ <SickReportIllnessCaseInformation isDetail={ true } /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).EMPLOYEE_INFORMATION) } element={ <SickReportEmployeeInformation /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).EMPLOYMENT_DETAILS) } element={ <SickReportEmploymentDetails /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).INSURANCE_PENSION_INFORMATION) } element={ <SickReportInsurancePensionInformation /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_MANAGEMENT) } element={ <SickReportIllnessCaseManagement /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).DISABILITY_MANAGEMENT) } element={ <SickReportDisabilityManagement /> } />
            <Route path={ SICK_REPORT_STEPS_ROUTES(currentSickReportType, SICK_REPORT_STEPS(currentSickReportType).SUMMARY) } element={ <SickReportSummary /> } />
        </Routes>
    );
}

export default SickReport;