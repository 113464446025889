export const ACCIDENT_REPORT_STARTUP_REPORT_FROM = {
    INJURED_PERSON: 'injured',
    ANOTHER_PERSON: 'another'
};

export const ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT = {
    BAGATELLE: 'bagatelle',
    ACCIDENT: 'accident',
    RELAPSE: 'relapse',
    TOOTH_DEMAGE: 'toothDemage',
    OCCUPATIONAL_DISEASE: 'occupationalDisease'
};

export const YES_NO_STATELESS = {
    YES: 'yes',
    NO: 'no',
    STATELESS: ''
};

export const EMPLOYMENT = {
    TEMPORARY: 'temporary',
    UNLIMITED: 'unlimited'
};

export const PHYSICAL_ACTIVITY = {
    LIGHT: 'light',
    MEDIUM: 'medium',
    HEAVY: 'heavy'
};

export const STRESS = {
    NONE: 'none',
    BACK: 'back',
    BACK_SHOULDER: 'backShoulder',
    SHOULDER: 'shoulder'
};

export const IV_EARLY_DETECTION = {
    WAIT: 'wait',
    DONE: 'done',
    NOT_NEEDED: 'notNeeded',
    CONTACT_ME: 'contactMe'
};

export const RECOVERY_BACK_TO_WORK = {
    FULLY: 'fully',
    PARTLY: 'partly',
    THERAOEUTIC: 'therapeutic',
    NO: 'no'
};

export const GENDER = {
    MALE: 'male',
    FEMALE: 'female'
};