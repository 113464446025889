import { useReducer } from "react";
import { SICK_REPORT_STEPS } from "../../utils/consts/steps.consts";
import { createAction } from "../../utils/reducer/reducer.utils";
import { SICK_REPORT_ACTION_TYPES } from "./sick-report.actions";
import { SickReportContext } from "./sick-report.context";
import { PHYSICAL_ACTIVITY, STRESS, YES_NO_STATELESS } from "../../utils/consts/radioButtons";
import { getSickReportData, setSickReportData } from '../../utils/sessionStorageHelperFunctions';
import { SICK_REPORT_TYPE } from "../../utils/consts/selects";

const sickReportReducer = (state, action) => {
    const {
        type,
        payload
    } = action;

    switch (type) {
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_TYPE:
            return {
                ...state,
                currentSickReportType: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_STEP:
            return {
                ...state,
                currentStep: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_STARTUP_FIELDS:
            return {
                ...state,
                startupFields: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_ILLNESS_CASE_INFORMATION_FIELDS:
            return {
                ...state,
                illnessCaseInformationFields: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_ILLNESS_CASE_DETAILS_FIELDS:
            return {
                ...state,
                illnessCaseDetailsFields: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_EMPLOYEE_INFORMATION_FIELDS:
            return {
                ...state,
                employeeInformationFields: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_EMPLOYMENT_DETAILS_FIELDS:
            return {
                ...state,
                employmentDetailsFields: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_INSURANCE_PENSION_INFORMATION_FIELDS:
            return {
                ...state,
                insurancePensionInformationFields: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_ILLNESS_CASE_MANAGEMENT_FIELDS:
            return {
                ...state,
                illnessCaseManagementFields: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_DISABILITY_MANAGEMENT_FIELDS:
            return {
                ...state,
                disabilityManagementFields: payload
            };
        case SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_STEPS_VALIDATED:
            return {
                ...state,
                stepsValidated: payload
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
}

const INITIAL_STATE = () => {
    const sickReportData =  getSickReportData();

    const {
        startup,
        illnessCaseInformation,
        illnessCaseDetails,
        employeeInformation,
        employmentDetails,
        insurancePensionInformation,
        illnessCaseManagement,
        disabilityManagement,
        currentSickReportType,
        stepsValidatedStore
    } = sickReportData;

    return ({
        currentSickReportType: currentSickReportType ? currentSickReportType : '',
        currentStep: '',
        startupFields: Object.keys(startup).length > 0 ? startup : {
            messageDate: new Date().toISOString().slice(0, 10)
        },
        illnessCaseInformationFields: Object.keys(illnessCaseInformation).length > 0 ? illnessCaseInformation : {
            firstName: '',
            lastName: '',
            role: '',
            personId: '',
            ahvSvNumber: '',
            employment: '',
            workloadInPercent: '',
            entryDate: '',
            department: '',
            workplace: '',
            telephonePrivate: '',
            emailPrivate: ''
        },
        illnessCaseDetailsFields: Object.keys(illnessCaseDetails).length > 0 ? illnessCaseDetails : {
            monthlyBaseSalary: '',
            monthlyAdditionalSalary: '',
            yearlySalaryByHourlyRate: '',
            yearlySalaryVacationCompensation: '',
            monthlyAllowance: '',
            yearlyInconviniences: '',
            yearlyLebo: '',
            yearlyBonus: '',
            bankDetails: false,
            ibanNr: '',
            postAccount: false,
            accountNr: ''
        },
        employeeInformationFields: Object.keys(employeeInformation).length > 0 ? employeeInformation : {
            firstName: '',
            lastName: '',
            birthDate: '',
            street: '',
            houseNr: '',
            zip: '',
            city: '',
            nationality: '',
            civilStatus: '',
            gender: '',
            ahvSvNumber: '',
            bankDetails: false,
            ibanNr: '',
            postAccount: false,
            accountNr: '',
            telephonePrivate: '',
            emailPrivate: ''
        },
        employmentDetailsFields: Object.keys(employmentDetails).length > 0 ? employmentDetails : {
            role: '',
            workplace: '',
            zip: '',
            school: '',
            entryDate: '',
            employment: '',
            temporaryYearOfService: '',
            endOfTemoraryEmployment: '',
            otherEmployments: [{
                employment: '',
                temporaryYearOfService: '',
                endOfTemoraryEmployment: ''
            }],
            weeklyPensumInPercent: '',
            weeklyLessons: '',
            weeklyLessonsInFullPensum: '',
            monthlyBaseSalary: '',
            monthlyAdditionalSalary: '',
            yearlySalaryByHourlyRate: '',
            yearlySalaryVacationCompensation: '',
            monthlyAllowance: '',
            yearlyInconviniences: '',
            yearlyLebo: '',
            yearlyBonus: ''
        },
        insurancePensionInformationFields: Object.keys(insurancePensionInformation).length > 0 ? insurancePensionInformation : {
            lfzRegulation: YES_NO_STATELESS.STATELESS,
            dgoRegulation: {
                id: '',
                name: '',
                type: '',
                objectUrl: ''
            },
            withFirstInsuranceSolutionVseg: YES_NO_STATELESS.STATELESS,
            bankDetails: false,
            ibanNr: '',
            postAccount: false,
            accountNr: '',
            ktgName: '',
            ktgCity: '',
            ktgPolicyNumber: '',
            ktgEmail: '',
            ktgWaitingPeriodInDays: '',
            ktgBenefitLevelInPercent: '',
            ktgPerformanceDurationInDays: '',
            bvgName: '',
            bvgCity: '',
            bvgContractNumber: '',
            bvgWaitingPeriodForPremiiumReductionInDays: '',
            ktgv: YES_NO_STATELESS.STATELESS,
            ktgvperformCaseManagement: YES_NO_STATELESS.STATELESS
        },
        illnessCaseManagementFields: Object.keys(illnessCaseManagement).length > 0 ? illnessCaseManagement : {
            caseIllnessStartDate: '',
            caseRelapse: YES_NO_STATELESS.STATELESS,
            caseAbsenceLongTerm: YES_NO_STATELESS.STATELESS,
            casePregnancyCalculatedDateOfBirth: '',
            caseRemark: '',
            medicalCertificates: [{
                certificate: {
                    id: '',
                    name: '',
                    type: '',
                    objectUrl: ''
                },
                startDate: '',
                endDate: '',
                incapacityInPercent: ''
            }],
            superiorFirstName: '',
            superiorLastName: '',
            superiorTelephone: '',
            superiorEmail: ''
        },
        disabilityManagementFields: Object.keys(disabilityManagement).length > 0 ? disabilityManagement : {
            physicalActivity: PHYSICAL_ACTIVITY.LIGHT,
            stress: STRESS.NONE,
            ivEarlyDetection: '',
            whenAndByWhom: '',
            disclosuresEmployerDoubtsIncapacity: YES_NO_STATELESS.NO,
            disclosuresEmployerDoubtsIncapacityDesc: '',
            disclosuresEmployerIvEarlyDetectionIsDone: YES_NO_STATELESS.NO,
            disclosuresEmployerIvEarlyDetectionIsDoneDesc: '',
            disclosuresEmployerBenefitsOtherInsurances: YES_NO_STATELESS.NO,
            disclosuresEmployerBenefitsOtherInsurancesDesc: '',
            recoveryBackToWork: '',
            recoveryBackToWorkPercentage: ''
        },
        stepsValidated: Object.keys(stepsValidatedStore).length > 0 ? stepsValidatedStore :
            Object.values(SICK_REPORT_STEPS(currentSickReportType)).reduce((acc, step) => {
                acc[step] = false;
                return acc;
            }
            , {})
    });
};

export const SickReportProvider = ({ children }) => {
    const [state, dispatch] = useReducer(sickReportReducer, INITIAL_STATE());

    const {
        currentSickReportType,
        currentStep,
        startupFields,
        illnessCaseInformationFields,
        illnessCaseDetailsFields,
        employeeInformationFields,
        employmentDetailsFields,
        insurancePensionInformationFields,
        illnessCaseManagementFields,
        disabilityManagementFields,
        stepsValidated
    } = state;

    const setCurrentSickReportType = (type) => {
        setSickReportData('currentSickReportType', type);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_TYPE, type));
    };

    const setCurrentStep = (step) => {
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_STEP, step));
    };

    const setStartupFields = (fields) => {
        setSickReportData(SICK_REPORT_STEPS(currentSickReportType).STARTUP, fields);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_STARTUP_FIELDS, fields));
    };

    const setIllnessCaseInformationFields = (fields) => {
        if (currentSickReportType === SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS) {
            setSickReportData('illnessCaseInformation', fields);
        } else {
            setSickReportData(SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_INFORMATION, fields);
        }
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_ILLNESS_CASE_INFORMATION_FIELDS, fields));
    };

    const setIllnessCaseDetailsFields = (fields) => {
        setSickReportData(SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_DETAILS, fields);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_ILLNESS_CASE_DETAILS_FIELDS, fields));
    };

    const setEmployeeInformationFields = (fields) => {
        setSickReportData(SICK_REPORT_STEPS(currentSickReportType).EMPLOYEE_INFORMATION, fields);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_EMPLOYEE_INFORMATION_FIELDS, fields));
    };

    const setEmploymentDetailsFields = (fields) => {
        setSickReportData(SICK_REPORT_STEPS(currentSickReportType).EMPLOYMENT_DETAILS, fields);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_EMPLOYMENT_DETAILS_FIELDS, fields));
    };

    const setInsurancePensionInformationFields = (fields) => {
        setSickReportData(SICK_REPORT_STEPS(currentSickReportType).INSURANCE_PENSION_INFORMATION, fields);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_INSURANCE_PENSION_INFORMATION_FIELDS, fields));
    };

    const setIllnessCaseManagementFields = (fields) => {
        setSickReportData(SICK_REPORT_STEPS(currentSickReportType).ILLNESS_CASE_MANAGEMENT, fields);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_ILLNESS_CASE_MANAGEMENT_FIELDS, fields));
    };

    const setDisabilityManagementFields = (fields) => {
        setSickReportData(SICK_REPORT_STEPS(currentSickReportType).DISABILITY_MANAGEMENT, fields);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_DISABILITY_MANAGEMENT_FIELDS, fields));
    };

    const setStepsValidated = (steps) => {
        setSickReportData('stepsValidatedStore', steps);
        dispatch(createAction(SICK_REPORT_ACTION_TYPES.SET_SICK_REPORT_STEPS_VALIDATED, steps));
    };



    const value = {
        currentSickReportType,
        setCurrentSickReportType,
        currentStep,
        setCurrentStep,
        startupFields,
        setStartupFields,
        illnessCaseInformationFields,
        setIllnessCaseInformationFields,
        illnessCaseDetailsFields,
        setIllnessCaseDetailsFields,
        employeeInformationFields,
        setEmployeeInformationFields,
        employmentDetailsFields,
        setEmploymentDetailsFields,
        insurancePensionInformationFields,
        setInsurancePensionInformationFields,
        illnessCaseManagementFields,
        setIllnessCaseManagementFields,
        disabilityManagementFields,
        setDisabilityManagementFields,
        stepsValidated,
        setStepsValidated
    };

    return (
        <SickReportContext.Provider value={ value }>
            { children }
        </SickReportContext.Provider>
    );
}