import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/footer/footer.component';
import Header from '../../components/header/header.component';
import ScrollToTop from '../../components/scroll-to-top/scroll-to-top.component';

const Router = () => {
    return (
        <Fragment>
            <Header />
            <Outlet />
            <Footer />
            <ScrollToTop />
        </Fragment>
    );
}

export default Router;