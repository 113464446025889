import { Routes, Route } from 'react-router-dom';
import AccidentReportStartup from '../../components/accident-report-startup/accident-report-startup.component';
import AccidentReportPerson from '../../components/accident-report-person/accident-report-person.component';
import AccidentReportAccident from '../../components/accident-report-accident/accident-report-accident.component';
import AccidentReportSpecial from '../../components/accident-report-special/accident-report-special.components';
import AccidentReportSummary from '../../components/accident-report-summary/accident-report-summary.components';
import { useEffect } from 'react';
import { ACCIDENT_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';

const AccidentReport = () => {
    useEffect(() => {
        document.title = 'Kanton Solothurn - Unfallmeldung an das Personalamt';

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Routes>
            <Route path={ ACCIDENT_REPORT_STEPS_ROUTES.STARTUP } element={ <AccidentReportStartup /> } />
            <Route path={ ACCIDENT_REPORT_STEPS_ROUTES.PERSONAL_INFORMATION } element={ <AccidentReportPerson /> } />
            <Route path={ ACCIDENT_REPORT_STEPS_ROUTES.ACCIDENT_INFORMATION } element={ <AccidentReportAccident /> } />
            <Route path={ ACCIDENT_REPORT_STEPS_ROUTES.SPECIAL } element={ <AccidentReportSpecial /> } />
            <Route path={ ACCIDENT_REPORT_STEPS_ROUTES.SUMMARY } element={ <AccidentReportSummary /> } />
        </Routes>
    );
}

export default AccidentReport;