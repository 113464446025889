import FieldsContainer from "../fields-container/fields-container.component";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BaseContext } from "../../reducer/base/base.context";
import { SICK_REPORT_STEPS } from "../../utils/consts/steps.consts";
import { getRowClassNames, inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations } from "../../utils/common/bootstrap";
import { SickReportContext } from "../../reducer/sick-report/sick-report.context";
import { SICK_REPORT_TYPE } from "../../utils/consts/selects";
import { goToNextSickReportStep } from "../../utils/common/functions";
import InfoIcon from "../info-icon/info-icon.component";
import InfoIconField from "../info-icon-field/info-icon-field.component";

const SickReportStartup = () => {
    const [validated, setValidated] = useState(false);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        currentSickReportType,
        setCurrentSickReportType,
        currentStep,
        setCurrentStep,
        startupFields,
        stepsValidated,
        setStepsValidated
    } = useContext(SickReportContext);

    const {
        setHeaderFormName,
        setFooterIsWithActions,
        setFooterNextAction,
        setFooterBackAction,
        setIsFooterAccidentCopyright
    } = useContext(BaseContext);

    const {
        messageDate
    } = startupFields;

    useEffect(() => {
        setHeaderFormName('Krankheitsmeldung an das Personalamt');
        setFooterIsWithActions(true);
        setCurrentStep(SICK_REPORT_STEPS(currentSickReportType).STARTUP);
        setFooterNextAction(() => submitButtonRef.current.click());
        setFooterBackAction(() => navigate('/'));
        setIsFooterAccidentCopyright(false);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    const handleFormTypeChange = (event) => {
        const { value } = event.target;

        setCurrentSickReportType(value);
        setStepsValidated( { } );
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [SICK_REPORT_STEPS(currentSickReportType).STARTUP]: true
            } );
            goToNextSickReportStep(currentSickReportType, currentStep, navigate);
        }

        setValidated(true);
    };

    return (
        <div className="sick-report-startup">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer
                    title={'Formular Krankheitsmeldung'}
                    infoIcon={
                        {
                            isShow: true,
                            id: 'info-container-1',
                            text: 'Das ist ein Test 123'
                        }
                    }
                >
                    <Container fluid>

                        <Row className="pt-4 pb-4">
                            <Col xl={ 6 } sm={ 12 }>
                                <p>Anleitung/Verständnis:</p>
                                <p>Bitte wählen Sie das richtige Formular. Die Beurteilung erfolgt aus dem Anstellungsstatus der erkrankten Person. Beispiele: <br />
                                    Verwaltung </p>
                                <ul>
                                    <li>Alle Anstellungen, welche nicht zu einer nachfolgender Kategorien gehören</li>
                                    <li>Inkl. selbstständige Institutionen, bei denen via Personalamt das Personal -respektive Lohnwesen SAP delegiert ist</li>
                                </ul>
                                <br />
                                selbstständige Anstalten <br />
                                <ul>
                                    <li>alle Angestellten der 3 definierten Anstalten (AKSO, IVSO und SGV)</li>
                                </ul>
                                <br />
                                Volksschule <br />
                                <ul>
                                    <li>alle dem GAV unterstellten Volksschullehrpersonen</li>
                                </ul>
                                <br />
                                Gemeinde- und/oder Sozialregion-Personal <br />
                                <ul>
                                    <li>Voraussetzung:<br />Es wurde eine "Anschlusslösung VSEG" im Krankentaggeld abgeschlossen</li>
                                    <li>Bereiche:<br />Schulleitung, administratives Personal Schulverwaltung, Musikschul-Lehrpersonen (gehören nicht zum GAV), Verwaltungs- und übriges Personal; für das eine arbeitsrechtlich definierte Lohnfortzahlung respektive Krankentaggeld-Lösung vereinbart ist</li>
                                </ul>
                            </Col>
                        </Row>


                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Datum Meldung</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <InfoIconField
                                    infoIconId={ 'info-1' }
                                    infoIconText={ 'Das ist ein Test für ein Field 123' }
                                    inputType={ 'date' }
                                >
                                    <Form.Control
                                        className={ inputColClassNames}
                                        type="date"
                                        name="messageDate"
                                        value={ messageDate }
                                        disabled
                                    />
                                </InfoIconField>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Formular*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <InfoIconField
                                    infoIconId={ 'info-2' }
                                    infoIconText={ 'Das ist ein Test für ein Field 123' }
                                    inputType={ 'select' }
                                >
                                    <Form.Select
                                        className={ inputColClassNames }
                                        required
                                        name="currentSickReportType"
                                        onChange={ handleFormTypeChange }
                                        value={ currentSickReportType }
                                    >
                                        <option value=''>Bitte auswählen...</option>
                                        <option value={ SICK_REPORT_TYPE.ADMINISTRATION_AND_CANTONAL_SCHOOLS }>für Verwaltung und kantonale Schulen</option>
                                        <option value={ SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS }>für selbstständige Anstalten (AKSO/IVSO/SGV)</option>
                                        <option value={ SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS }>für Volksschulen</option>
                                        <option value={ SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL }>für Gemeinde- und/oder Sozialregion-Personal</option>
                                    </Form.Select>
                                </InfoIconField>
                            </Col>
                        </Row>

                    </Container>
                </FieldsContainer>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
}

export default SickReportStartup;
