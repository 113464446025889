import './footer.styles.scss';
import Button from 'react-bootstrap/Button';
import { useContext } from 'react';
import { BaseContext } from '../../reducer/base/base.context';

const Footer = () => {
    const {
        footerBackAction,
        footerNextAction,
        footerCancelAction,
        footerIsWithActions,
        footerNextButtonText,
        isFooterAccidentCopyright
    } = useContext(BaseContext);

    return (
        <footer className='footer'>
            { footerIsWithActions && (
                <div className='actions'>
                    <Button
                        variant="secondary"
                        onClick={ footerCancelAction }
                    >
                        Abbrechen
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={ footerBackAction }
                    >
                        Zurück
                    </Button>
                    <Button
                        variant="primary"
                        onClick={ footerNextAction }
                    >
                        { footerNextButtonText || 'Weiter' }
                    </Button>
                </div>
            ) }
            <div className='copyright'>
                {
                    isFooterAccidentCopyright ? (
                        <span>MeldungUnfall - 31.01.2023 Kanton Solothurn ©2023</span>
                    ) : (
                        <span>KmInitWf -  31.01.2023  Kanton Solothurn ©2023</span>
                    )
                }
            </div>
        </footer>
    );
}

export default Footer;