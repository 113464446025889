import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../reducer/base/base.context';
import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { YES_NO_STATELESS } from '../../utils/consts/radioButtons';
import { getPreviousSickReportStep, getRequiredErrorMessageText, goToNextSickReportStep } from '../../utils/common/functions';
import { inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations, rightAlignedTextColConfigurations, rightAlignedInputColConfigurations, getRowClassNames } from '../../utils/common/bootstrap';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';
import { addFileToDatabase, deleteFileFromDatabase, getFileFromDatabase, openDatabase } from '../../utils/indexedDbHelperFunctions';
import { createObjectUrl, objectUrlExists, revokeObjectUrl } from '../../utils/sessionStorageHelperFunctions';
import { SICK_REPORT_TYPE } from '../../utils/consts/selects';

const SickReportInsurancePensionInformation = () => {
    const [validated, setValidated] = useState(false);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        currentSickReportType,
        currentStep,
        setCurrentStep,
        insurancePensionInformationFields,
        setInsurancePensionInformationFields,
        stepsValidated,
        setStepsValidated
    } = useContext(SickReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        lfzRegulation,
        dgoRegulation,
        withFirstInsuranceSolutionVseg,
        bankDetails,
        ibanNr,
        postAccount,
        accountNr,
        ktgName,
        ktgCity,
        ktgPolicyNumber,
        ktgEmail,
        ktgWaitingPeriodInDays,
        ktgBenefitLevelInPercent,
        ktgPerformanceDurationInDays,
        bvgName,
        bvgCity,
        bvgContractNumber,
        bvgWaitingPeriodForPremiiumReductionInDays,
        ktgv,
        ktgvperformCaseManagement
    } = insurancePensionInformationFields;

    useEffect(() => {
        setCurrentStep(SICK_REPORT_STEPS(currentSickReportType).INSURANCE_PENSION_INFORMATION);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function initializeDgoRegulation() {
            const dgoData = dgoRegulation;

            if (dgoData.objectUrl && !objectUrlExists(dgoData.objectUrl)) {
                revokeObjectUrl(dgoData.objectUrl);
                dgoData.objectUrl = '';
            }

            if(dgoData.id) {
                try {
                    const db = await openDatabase();
                    const file = await getFileFromDatabase(db, dgoData.id);
                    const objectUrl = createObjectUrl(file);
                    dgoData.objectUrl = objectUrl;
            
                    setInsurancePensionInformationFields({ ...insurancePensionInformationFields, dgoRegulation: dgoData });
                } catch (error) {
                    console.log(error);
                }
            }
        }

        if (currentStep) {
            const prevStep = getPreviousSickReportStep(currentSickReportType, currentStep);

            if (prevStep) {
                if (!stepsValidated[prevStep]) {
                    navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`);
                } else {
                    setFooterNextAction(() => submitButtonRef.current.click());
                    setFooterBackAction(() => navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`));
                    if (currentSickReportType === SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL) {
                        initializeDgoRegulation();
                    }
                }
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setInsurancePensionInformationFields({ ...insurancePensionInformationFields, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setInsurancePensionInformationFields({ ...insurancePensionInformationFields, [name]: checked });
    };

    // DGO Regulation Functions Start
    const handleDgoRegulationChange = async(event) => {
        const { files } = event.target;

        // Check for existing ObjectUrl and revoke it
        if (dgoRegulation.objectUrl) {
            revokeObjectUrl(dgoRegulation.objectUrl);
        }

        // Delete file from database
        if (dgoRegulation.id) {            
            try {
                const db = await openDatabase();
                await deleteFileFromDatabase(db, dgoRegulation.id);
            } catch (error) {
                console.log(error);
            }
        }

        const dgoData = {};
        //Create objectUrl from file
        if (files && files[0]) {
            try {
                const db = await openDatabase();
                const data = await addFileToDatabase(db, files[0]);
                dgoData.id = data;
                dgoData.name = files[0].name;
                dgoData.type = files[0].type;
                dgoData.objectUrl = createObjectUrl(files[0]);
            } catch (error) {
                console.log(error);
            }
        }

        setInsurancePensionInformationFields({ ...insurancePensionInformationFields, dgoRegulation: dgoData });
    };

    const handleDgoRegulationDelete = async() => {
        // Check for existing ObjectUrl and revoke it
        if (dgoRegulation.objectUrl) {
            revokeObjectUrl(dgoRegulation.objectUrl);
        }

        // Delete file from database
        if (dgoRegulation.id) {
            try {
                const db = await openDatabase();
                await deleteFileFromDatabase(db, dgoRegulation.id);
            } catch (error) {
                console.log(error);
            }
        }

        let newDgoRegulation = dgoRegulation;
        newDgoRegulation = {
            id: '',
            name: '',
            type: '',
            objectUrl: ''
        };

        setInsurancePensionInformationFields({ ...insurancePensionInformationFields, dgoRegulation: newDgoRegulation });
    };
    // DGO Regulation Functions End

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [SICK_REPORT_STEPS(currentSickReportType).INSURANCE_PENSION_INFORMATION]: true
            } );

            goToNextSickReportStep(currentSickReportType, currentStep, navigate);
        }

        setValidated(true);
    };

    return (
        <div className="sick-report-employeeInformationFields">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >

                {
                    currentSickReportType === SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS ? (
                        <FieldsContainer title={'Angaben zur erkrankten Person'}>
                            <Container fluid>
                                <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                                    <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                        <Form.Label>Besteht für die erkrankte Volksschullehrperson bei der Gemeinde eine zusätzliche Krankentaggeldversicherung (KTGV)?*</Form.Label>
                                    </Col>
                                    <Col { ...rightAlignedInputColConfigurations }>
                                        <Form.Check
                                            label="Ja"
                                            name="ktgv"
                                            type='radio'
                                            onChange={ handleFieldsChange }
                                            value={ YES_NO_STATELESS.YES }
                                            checked={ ktgv === YES_NO_STATELESS.YES }
                                        />
                                        <Form.Check
                                            label="Nein"
                                            name="ktgv"
                                            type='radio'
                                            onChange={ handleFieldsChange }
                                            value={ YES_NO_STATELESS.NO }
                                            checked={ ktgv === YES_NO_STATELESS.NO }
                                            required
                                            feedback="Bitte wählen Sie eine Option aus."
                                            feedbackType="invalid"
                                        />
                                    </Col>
                                </Row>

                                {
                                    ktgv === YES_NO_STATELESS.YES &&
                                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                                <Form.Label>Wird diese KTGV ein Case-Management durchführen??*</Form.Label>
                                            </Col>
                                            <Col { ...rightAlignedInputColConfigurations }>
                                                <Form.Check
                                                    label="Ja"
                                                    name="ktgvperformCaseManagement"
                                                    type='radio'
                                                    onChange={ handleFieldsChange }
                                                    value={ YES_NO_STATELESS.YES }
                                                    checked={ ktgvperformCaseManagement === YES_NO_STATELESS.YES }
                                                />
                                                <Form.Check
                                                    label="Nein"
                                                    name="ktgvperformCaseManagement"
                                                    type='radio'
                                                    onChange={ handleFieldsChange }
                                                    value={ YES_NO_STATELESS.NO }
                                                    checked={ ktgvperformCaseManagement === YES_NO_STATELESS.NO }
                                                    required
                                                    feedback="Bitte wählen Sie eine Option aus."
                                                    feedbackType="invalid"
                                                />
                                            </Col>
                                        </Row>
                                }

                                <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                    <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                        <Form.Label>Mit Erstversicherungslösung VSEG?*</Form.Label>
                                    </Col>
                                    <Col { ...rightAlignedInputColConfigurations }>
                                        <Form.Check
                                            label="Ja"
                                            name="withFirstInsuranceSolutionVseg"
                                            type='radio'
                                            onChange={ handleFieldsChange }
                                            value={ YES_NO_STATELESS.YES }
                                            checked={ withFirstInsuranceSolutionVseg === YES_NO_STATELESS.YES }
                                        />
                                        <Form.Check
                                            label="Nein"
                                            name="withFirstInsuranceSolutionVseg"
                                            type='radio'
                                            onChange={ handleFieldsChange }
                                            value={ YES_NO_STATELESS.NO }
                                            checked={ withFirstInsuranceSolutionVseg === YES_NO_STATELESS.NO }
                                            required
                                            feedback="Bitte wählen Sie eine Option aus."
                                            feedbackType="invalid"
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </FieldsContainer>
                    ) : (
                        <FieldsContainer title={''}>
                            <Container fluid>
                                <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                                    <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                        <Form.Label>Gilt für die angestellte/versicherte Person in Ihrer DGO sinngemäss der GAV des Kanton Solothurn (LFZ-Regelung)?*</Form.Label>
                                    </Col>
                                    <Col { ...rightAlignedInputColConfigurations }>
                                        <Form.Check
                                            label="Ja"
                                            name="lfzRegulation"
                                            type='radio'
                                            onChange={ handleFieldsChange }
                                            value={ YES_NO_STATELESS.YES }
                                            checked={ lfzRegulation === YES_NO_STATELESS.YES }
                                        />
                                        <Form.Check
                                            label="Nein"
                                            name="lfzRegulation"
                                            type='radio'
                                            onChange={ handleFieldsChange }
                                            value={ YES_NO_STATELESS.NO }
                                            checked={ lfzRegulation === YES_NO_STATELESS.NO }
                                            required
                                            feedback="Bitte wählen Sie eine Option aus."
                                            feedbackType="invalid"
                                        />
                                    </Col>
                                </Row>

                                {
                                    lfzRegulation === YES_NO_STATELESS.NO &&
                                        <Fragment>
                                            <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                                <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                                    <Form.Label>DGO-Regelung*</Form.Label>
                                                </Col>
                                                <Col { ...rightAlignedInputColConfigurations }>
                                                    {
                                                        (!(dgoRegulation.objectUrl && objectUrlExists(dgoRegulation.objectUrl))) ? (
                                                            <Fragment>
                                                                <Form.Control
                                                                    required
                                                                    type="file"
                                                                    name="dgoRegulation"
                                                                    onChange={ handleDgoRegulationChange }
                                                                />
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                <a href={ dgoRegulation.objectUrl } target="_blank" rel="noreferrer">
                                                                    { dgoRegulation.name }
                                                                </a>
                                                                <Button className='ms-xl-2 ms-sm-0 ms-xs-0 mt-xl-0 mt-sm-2 mt-xs-2' onClick={ handleDgoRegulationDelete }>Beilage entfernen</Button>
                                                            </Fragment>
                                                        )
                                                    }

                                                </Col>
                                            </Row>
                                        </Fragment>
                                }

                                <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                    <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                        <Form.Label>Mit Erstversicherungslösung VSEG?*</Form.Label>
                                    </Col>
                                    <Col { ...rightAlignedInputColConfigurations }>
                                        <Form.Check
                                            label="Ja"
                                            name="withFirstInsuranceSolutionVseg"
                                            type='radio'
                                            onChange={ handleFieldsChange }
                                            value={ YES_NO_STATELESS.YES }
                                            checked={ withFirstInsuranceSolutionVseg === YES_NO_STATELESS.YES }
                                        />
                                        <Form.Check
                                            label="Nein"
                                            name="withFirstInsuranceSolutionVseg"
                                            type='radio'
                                            onChange={ handleFieldsChange }
                                            value={ YES_NO_STATELESS.NO }
                                            checked={ withFirstInsuranceSolutionVseg === YES_NO_STATELESS.NO }
                                            required
                                            feedback="Bitte wählen Sie eine Option aus."
                                            feedbackType="invalid"
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </FieldsContainer>
                    )
                }

                {
                    withFirstInsuranceSolutionVseg === YES_NO_STATELESS.YES ? (
                        <FieldsContainer title={'Kontoverbindung'}>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        der zuständige KTG-Versicherer des Kantons wird gestüzt auf die vorhandene EV KTG-Anschlusslösung das ausgewiesene Krankentaggeld auf das definierte Konto überweisen
                                    </Col>
                                </Row>

                                <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                        <Form.Label>Bankverbindung</Form.Label>
                                    </Col>
                                    <Col { ...inputColConfigurations }>
                                        <Form.Check
                                            label=""
                                            name="bankDetails"
                                            type='checkbox'
                                            onChange={ handleCheckboxChange }
                                            value={ bankDetails }
                                            checked={ bankDetails }
                                        />
                                    </Col>


                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                        <Form.Label>{ `IBAN-Nr. ${ bankDetails ? '*' : '' }` }</Form.Label>
                                    </Col>
                                    <Col { ...inputColConfigurations }>
                                        <Form.Control
                                            required={ bankDetails }
                                            className={ inputColClassNames }
                                            type="text"
                                            value={ ibanNr }
                                            name="ibanNr"
                                            onChange={ handleFieldsChange }
                                        />
                                        {
                                            bankDetails &&
                                            <Form.Control.Feedback type="invalid">
                                                { getRequiredErrorMessageText('IBAN-Nr.') }
                                            </Form.Control.Feedback>
                                        }
                                    </Col>

                                </Row>

                                <Row { ...rowConfigurations } className={ getRowClassNames() }>
                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                        <Form.Label>Postkonto</Form.Label>
                                    </Col>
                                    <Col { ...inputColConfigurations }>
                                        <Form.Check
                                            label=""
                                            name="postAccount"
                                            type='checkbox'
                                            onChange={ handleCheckboxChange }
                                            value={ postAccount }
                                            checked={ postAccount }
                                        />
                                    </Col>

                                    <Col { ...textColConfigurations } className={ textColClassNames }>
                                        <Form.Label>{ `Konto-Nr. ${ postAccount ? '*' : '' }` }</Form.Label>
                                    </Col>
                                    <Col { ...inputColConfigurations }>
                                        <Form.Control
                                            className={ inputColClassNames }
                                            type="text"
                                            required={ postAccount }
                                            value={ accountNr }
                                            name="accountNr"
                                            onChange={ handleFieldsChange }
                                        />
                                        {
                                            postAccount &&
                                                <Form.Control.Feedback type="invalid">
                                                    { getRequiredErrorMessageText('Konto-Nr.') }
                                                </Form.Control.Feedback>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </FieldsContainer>
                    ) : (
                        <FieldsContainer title={'Hinweis "Prozess-Koordination'}>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        Sofern die Gemeinde/Schule über eine eigenständige KTG-Lösung für die LFZ-Dauer abgeschlossen hat, stellt sie die Abwicklung der möglichen Leistungen mit ihrer Versicherung selbstständog sicher
                                    </Col>
                                </Row>
                            </Container>
                        </FieldsContainer>
                    )
                }

                <FieldsContainer title={'KTG Erstversicherer'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Name</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ ktgName }
                                    name="ktgName"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Ort</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ ktgCity }
                                    name="ktgCity"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Policen-Nr.</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ ktgPolicyNumber }
                                    name="ktgPolicyNumber"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>E-Mail</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="email"
                                    value={ ktgEmail }
                                    name="ktgEmail"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Wartefrist in Tagen</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ ktgWaitingPeriodInDays }
                                    name="ktgWaitingPeriodInDays"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Leistungshöhe in %</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    min={ 1 }
                                    max={ 100 }
                                    value={ ktgBenefitLevelInPercent }
                                    name="ktgBenefitLevelInPercent"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Leistungsdauer in Tagen</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ ktgPerformanceDurationInDays }
                                    name="ktgPerformanceDurationInDays"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Pensionskasse BVG'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Name</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ bvgName }
                                    name="bvgName"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Ort</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ bvgCity }
                                    name="bvgCity"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Vertrags-Nr.</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ bvgContractNumber }
                                    name="bvgContractNumber"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Wartefrist Prämienbefreiung in Tagen</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="number"
                                    value={ bvgWaitingPeriodForPremiiumReductionInDays }
                                    name="bvgWaitingPeriodForPremiiumReductionInDays"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
};

export default SickReportInsurancePensionInformation;
