export const rowConfigurations = {
    xl: 4,
    sm: 1,
    xs: 1
};

export const textColConfigurations = {
    xl: 2,
    sm: 12,
    xs: 12
};

export const textColClassNames = 'align-self-start mt-2 text-xl-end'; 

export const inputColConfigurations = {
    xl: 4,
    sm: 12,
    xs: 12
};


export const rightAlignedTextColConfigurations = {
    xl: 8,
    sm: 12,
    xs: 12
};

export const rightAlignedInputColConfigurations = {
    xl: 4,
    sm: 12,
    xs: 12
};

export const inputColClassNames = 'w-xl-auto';

export const getRowClassNames = (isFirstRow = false) => {
    let classNames = 'align-items-start';
    if (!isFirstRow) {
        classNames += ' mt-3';
    }
    return classNames;
}