import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../consts/steps.consts';

export const getRequiredErrorMessageText = (fieldName) => {
    return `Das Feld ${fieldName} ist ein Pflichtfeld. Bitte geben Sie einen Wert ein!`;
}

export const formatDate = (date) => {
    if (date) {
        const dateParts = date.split('-');
        return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
    }
    return '';
}

export const formatNumberWith2Decimals = (number) => {
    if (typeof number === 'string') {
        number = Number(number);
    }
    return number ? number.toFixed(2) : '';
}

export const formatNumberWith2DecimalsAndThousandSeparator = (number) => {
    if (typeof number === 'string') {
        number = Number(number);
    }
    return number ? number.toLocaleString('de-CH', { minimumFractionDigits: 2 }) : '';
}

export const goToNextSickReportStep = (sickReportType, currentStep, navigate) => {
    const steps = SICK_REPORT_STEPS(sickReportType);
    let nextStep;
    let lastStep;
    
    Object.keys(steps).forEach((key) => {
        if (lastStep === currentStep) {
            nextStep = steps[key];
        }
        lastStep = steps[key];
    });

    navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(sickReportType, nextStep) }`);
}

export const getPreviousSickReportStep = (sickReportType, currentStep) => {
    const steps = SICK_REPORT_STEPS(sickReportType);
    let previousStep;
    let finalPreviousStep = SICK_REPORT_STEPS().STARTUP;
    
    Object.keys(steps).forEach((key) => {
        if (steps[key] === currentStep) {
            finalPreviousStep = previousStep;
            return;
        }
        previousStep = steps[key];
    });

    return finalPreviousStep;
}