import { SICK_REPORT_TYPE } from './selects.js';

export const ACCIDENT_REPORT_STEPS = {
    STARTUP: 'startup',
    PERSONAL_INFORMATION: 'person',
    ACCIDENT_INFORMATION: 'accident',
    SPECIAL: 'special',
    SUMMARY: 'summary'
};

export const ACCIDENT_REPORT_STEPS_ROUTES = {
    STARTUP: '1',
    PERSONAL_INFORMATION: '2',
    ACCIDENT_INFORMATION: '3',
    SPECIAL: '4',
    SUMMARY: '5'
};

export const SICK_REPORT_STEPS = (sickReportType) => {
    const startStep = {};
    const endSteps = {};

    startStep.STARTUP = 'startup';
    endSteps.ILLNESS_CASE_MANAGEMENT = 'illnessCaseManagement';
    endSteps.DISABILITY_MANAGEMENT = 'disabilityManagement';
    endSteps.SUMMARY = 'summary';

    switch (sickReportType) {
        case SICK_REPORT_TYPE.ADMINISTRATION_AND_CANTONAL_SCHOOLS:
            return {
                ...startStep,
                ILLNESS_CASE_INFORMATION: 'illnessCaseInformation',
                ...endSteps            
            };
        case SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS:
            return {
                ...startStep,
                ILLNESS_CASE_DETAILS: 'illnessCaseDetails',
                ...endSteps 
            };
        case SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS:
            return {
                ...startStep,
                EMPLOYEE_INFORMATION: 'employeeInformation',
                EMPLOYMENT_DETAILS: 'employmentDetails',
                INSURANCE_PENSION_INFORMATION: 'insurancePensionInformation',
                ...endSteps 
            };
        case SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL:
            return {
                ...startStep,
                EMPLOYEE_INFORMATION: 'employeeInformation',
                EMPLOYMENT_DETAILS: 'employmentDetails',
                INSURANCE_PENSION_INFORMATION: 'insurancePensionInformation',
                ...endSteps 
            }
            default:
                return startStep;
    }
};

export const SICK_REPORT_STEPS_ROUTES = (sickReportType, step) => {
    return `${SICK_REPORT_TYPE_ROUTE_PART_MAPPING(sickReportType)}${SICK_REPORT_STEP_NUMBER_BY_TYPE_AND_STEP(sickReportType, step)}`;
};

const SICK_REPORT_TYPE_ROUTE_PART_MAPPING = (sickReportType) => {
    switch (sickReportType) {
        case SICK_REPORT_TYPE.ADMINISTRATION_AND_CANTONAL_SCHOOLS:
            return 'verwaltung-und-kantonale-schulen/';
        case SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS:
            return 'selbststaendige-anstalten/';
        case SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS:
            return 'volksschulen/';
        case SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL:
            return 'gemeinde-und-oder-sozialregion-personal/';
        default:
            return '';
    }
};

const SICK_REPORT_STEP_NUMBER_BY_TYPE_AND_STEP = (sickReportType, step) => {
    switch (sickReportType) {
        case SICK_REPORT_TYPE.ADMINISTRATION_AND_CANTONAL_SCHOOLS:
            switch (step) {
                case SICK_REPORT_STEPS(sickReportType).STARTUP:
                    return '1';
                case 'illnessCaseInformation':
                    return '2';
                case 'illnessCaseManagement':
                    return '3';
                case 'disabilityManagement':
                    return '4';
                case 'summary':
                    return '5';
                default:
                    return '1';
            }
        case SICK_REPORT_TYPE.INDEPENDENT_INSTITUTIONS:
            switch (step) {
                case 'startup':
                    return '1';
                case 'illnessCaseDetails':
                    return '2';
                case 'illnessCaseManagement':
                    return '3';
                case 'disabilityManagement':
                    return '4';
                case 'summary':
                    return '5';
                default:
                    return '1';
            }
        case SICK_REPORT_TYPE.ELEMENTARY_SCHOOLS:
            switch (step) {
                case 'startup':
                    return '1';
                case 'employeeInformation':
                    return '2';
                case 'employmentDetails':
                    return '3';
                case 'insurancePensionInformation':
                    return '4';
                case 'illnessCaseManagement':
                    return '5';
                case 'disabilityManagement':
                    return '6';
                case 'summary':
                    return '7';
                default:
                    return '1';
            }
        case SICK_REPORT_TYPE.MUNICIPAL_AND_OR_SOCIAL_REGION_PERSONNEL:
            switch (step) {
                case 'startup':
                    return '1';
                case 'employeeInformation':
                    return '2';
                case 'employmentDetails':
                    return '3';
                case 'insurancePensionInformation':
                    return '4';
                case 'illnessCaseManagement':
                    return '5';
                case 'disabilityManagement':
                    return '6';
                case 'summary':
                    return '7';
                default:
                    return '1';
            }
        default:
            return '1';
    }
}