import './fields-container.styles.scss';
import { useContext } from 'react';
import { AccidentReportContext } from '../../reducer/accident-report/accident-report.context';
import { ACCIDENT_REPORT_STEPS, SICK_REPORT_STEPS } from '../../utils/consts/steps.consts';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';
import InfoIcon from '../info-icon/info-icon.component';

const FieldsContainer = (
    {
        children,
        title,
        isWithHeadline = true,
        infoIcon = {
            isShow: false,
            id: '1',
            text: ''
        }
    }
) => {
    const {
        isShow: isShowInfoIcon,
        id: infoIconId,
        text: infoIconText
    } = infoIcon;

    const {
        currentStep: currentAccidentStep
    } = useContext(AccidentReportContext);

    const {
        currentStep: currentSickStep,
        currentSickReportType
    } = useContext(SickReportContext);

    return (
        <div className={ `fields-container ${currentAccidentStep === ACCIDENT_REPORT_STEPS.SUMMARY || currentSickStep === SICK_REPORT_STEPS(currentSickReportType).SUMMARY ? '--light' : ''}` }>
            {
                isWithHeadline && (
                    <div className='headline'>
                        <h2>{title}</h2>
                        {
                            isShowInfoIcon && (
                                <InfoIcon
                                    id={ infoIconId }
                                    text={ infoIconText }
                                />
                            )
                        }
                    </div>
                )
            }
            <div className='fields'>
                {children}
            </div>
        </div>
    );
}

export default FieldsContainer;