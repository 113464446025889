import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccidentReportContext } from '../../reducer/accident-report/accident-report.context';
import { BaseContext } from '../../reducer/base/base.context';
import { ACCIDENT_REPORT_STEPS, ACCIDENT_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { CIVIL_STATUS, DEPARTMENT } from '../../utils/consts/selects';
import { EMPLOYMENT, YES_NO_STATELESS } from '../../utils/consts/radioButtons';
import { inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations, rightAlignedTextColConfigurations, rightAlignedInputColConfigurations, getRowClassNames } from "../../utils/common/bootstrap";
import MaskedInputField from '../masked-input/masked-input';

const AccidentReportSpecial = () => {
    const [validated, setValidated] = useState(false);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        setCurrentStep,
        specialFields,
        setSpecialFields,
        stepsValidated,
        setStepsValidated
    } = useContext(AccidentReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        independetInstitutionsFirstName,
        independetInstitutionsLastName,
        independetInstitutionsFunction,
        independetInstitutionsPersonId,
        independetInstitutionsAhvSvNr,
        independetInstitutionsEmployment,
        independetInstitutionsPensumInPercent,
        independetInstitutionsEntryDate,
        independetInstitutionsDepartment,
        independetInstitutionsWorkplace,
        independetInstitutionsPrivateStreetAndNr,
        independetInstitutionsPrivateZipCity,
        independetInstitutionsCivilStatus,
        independetInstitutionsChildren,
        independetInstitutionsAdditionalInsurance,
        independetInstitutionsMonthlyBaseSalary,
        independetInstitutionsMonthlyAdditionalSalary,
        independetInstitutionsYearlySalaryByHourlyRate,
        independetInstitutionsYearlySalaryVacationCompensation,
        independetInstitutionsMonthlyAllowance,
        independetInstitutionsYearlyInconviniences,
        independetInstitutionsYearlyLebo,
        independetInstitutionsYearlyBonus,
        studentStreet,
        studentStreetNr,
        studentZip,
        studentCity,
        studentSvNr,
        studentEntryDate
    } = specialFields;

    useEffect(() => {
        if (!stepsValidated[ACCIDENT_REPORT_STEPS.ACCIDENT_INFORMATION]) {
            navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.ACCIDENT_INFORMATION}`);
        } else {
            setCurrentStep(ACCIDENT_REPORT_STEPS.SPECIAL);
            setFooterNextAction(() => submitButtonRef.current.click());
            setFooterBackAction(() => navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.ACCIDENT_INFORMATION}`));
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])    

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setSpecialFields({ ...specialFields, [name]: value });
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [ACCIDENT_REPORT_STEPS.SPECIAL]: true
            } );
            navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.SUMMARY}`);
        }

        setValidated(true);
    };

    return (
        <div className="accident-report-person">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer title={'Nur für selbstständige Anstalten, (SGV, AKSO, IVSO) / Angaben zur verunfallten Person'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Vorname</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsFirstName }
                                    name="independetInstitutionsFirstName"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Nachname</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsLastName }
                                    name="independetInstitutionsLastName"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Funktion</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsFunction }
                                    name="independetInstitutionsFunction"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Personen-ID</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsPersonId }
                                    name="independetInstitutionsPersonId"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>AHV/SV Nummer</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <MaskedInputField
                                    mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/]}
                                    inputProps={{
                                        name: 'independetInstitutionsAhvSvNr',
                                        value: independetInstitutionsAhvSvNr,
                                        onChange: handleFieldsChange,
                                        className: inputColClassNames
                                    } }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Anstellung</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="befristet"
                                    name="independetInstitutionsEmployment"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ EMPLOYMENT.TEMPORARY }
                                    checked={ independetInstitutionsEmployment === EMPLOYMENT.TEMPORARY }
                                />
                                <Form.Check
                                    label="unbefristet"
                                    name="independetInstitutionsEmployment"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ EMPLOYMENT.UNLIMITED }
                                    checked={ independetInstitutionsEmployment === EMPLOYMENT.UNLIMITED }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Pensum in %</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsPensumInPercent }
                                    name="independetInstitutionsPensumInPercent"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col>
                                <span>(Angabe ohne Kommastelle, d.h. auf- oder abrunden)</span>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Eintrittsdatum</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="date"
                                    name="independetInstitutionsEntryDate"
                                    value={ independetInstitutionsEntryDate }
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Dienststelle</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Select
                                    className={ inputColClassNames }
                                    name="independetInstitutionsDepartment"
                                    onChange={ handleFieldsChange }
                                    value={ independetInstitutionsDepartment }
                                >
                                    <option value=''>Bitte auswählen...</option>
                                    <option value={ DEPARTMENT.AKSO }>{ DEPARTMENT.AKSO }</option>
                                    <option value={ DEPARTMENT.IV }>{ DEPARTMENT.IV }</option>
                                    <option value={ DEPARTMENT.SGV }>{ DEPARTMENT.SGV }</option>
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Arbeitsort</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsWorkplace }
                                    name="independetInstitutionsWorkplace"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                <span>Weitere Angaben</span>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Privateadresse (Str./Nr.)</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsPrivateStreetAndNr }
                                    name="independetInstitutionsPrivateStreetAndNr"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>PLZ/Ort</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsPrivateZipCity }
                                    name="independetInstitutionsPrivateZipCity"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Zivilstand*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Select
                                    className={ inputColClassNames }
                                    name="independetInstitutionsCivilStatus"
                                    onChange={ handleFieldsChange }
                                    value={ independetInstitutionsCivilStatus }
                                >
                                    <option value=''>Bitte auswählen...</option>
                                    <option value={ CIVIL_STATUS.DIVORCED }>geschieden</option>
                                    <option value={ CIVIL_STATUS.DISSOLVED_PARTNERSHIP }>in aufgelöster Partnerschaft</option>
                                    <option value={ CIVIL_STATUS.REGISTERED_PARTNERSHIP }>in eingetragener Partnerschaft</option>
                                    <option value={ CIVIL_STATUS.SINGLE }>ledig</option>
                                    <option value={ CIVIL_STATUS.MARRIED }>verheiratet</option>
                                    <option value={ CIVIL_STATUS.WIDOWED }>verwitwet</option>
                                    <option value={ CIVIL_STATUS.LIVING_SEPARATELY }>getrennt lebend</option>
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Kinder (mit Unterstützungspflicht)</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsChildren }
                                    name="independetInstitutionsChildren"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Verfügt die verunfallte Person über eine Unfallzusatzversicherung?</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="Ja"
                                    name="independetInstitutionsAdditionalInsurance"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.YES }
                                    checked={ independetInstitutionsAdditionalInsurance === YES_NO_STATELESS.YES }
                                />
                                <Form.Check
                                    label="Nein"
                                    name="independetInstitutionsAdditionalInsurance"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ YES_NO_STATELESS.NO }
                                    checked={ independetInstitutionsAdditionalInsurance === YES_NO_STATELESS.NO }
                                />
                            </Col>
                        </Row>

                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Nur für selbstständige Anstalten (SGV, AKSO, IVSO) / Angaben zum Lohn'}>
                    <Container fluid>
                        <Row { ...rowConfigurations }>
                            <Col>
                                <span>Zeitperiode: 12 Mt. vor Beginn des Unfalls</span>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Vertraglicher Grundlohn pro Monat Brutto inkl. Teuerungszulage</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsMonthlyBaseSalary }
                                    name="independetInstitutionsMonthlyBaseSalary"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Besondere vertragliche Leistungen pro Monat Brutto (Besitzstand etc.)</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsMonthlyAdditionalSalary }
                                    name="independetInstitutionsMonthlyAdditionalSalary"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto (bitte Details "Stunden/ANsatz" via Mail)</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsYearlySalaryByHourlyRate }
                                    name="independetInstitutionsYearlySalaryByHourlyRate"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Bei Anstellung im Stundenlohn; 12 Mt. Periode Brutto Ferien- & Feiertagsentschädigung</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsYearlySalaryVacationCompensation }
                                    name="independetInstitutionsYearlySalaryVacationCompensation"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Kinder- / Familien- / Ausbildungszulagen pro Monat</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsMonthlyAllowance }
                                    name="independetInstitutionsMonthlyAllowance"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>Inkonvenienzen (gemäss GAV); 12 Mt. Periode (bitte Details via Mail)</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsYearlyInconviniences }
                                    name="independetInstitutionsYearlyInconviniences"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>LEBO; 12 Mt. Periode</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsYearlyLebo }
                                    name="independetInstitutionsYearlyLebo"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...rightAlignedTextColConfigurations } className={ textColClassNames }>
                                <Form.Label>13. Monatslohn / Gratifikation pro Jahr</Form.Label>
                            </Col>
                            <Col { ...rightAlignedInputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ independetInstitutionsYearlyBonus }
                                    name="independetInstitutionsYearlyBonus"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Nur für Schüler/-innen der Schule für Mode und Gestalten und des ZeitZentrums'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Strasse</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ studentStreet }
                                    name="studentStreet"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Hausnummer</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ studentStreetNr }
                                    name="studentStreetNr"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Postleitzahl</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ studentZip }
                                    name="studentZip"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Ort</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ studentCity }
                                    name="studentCity"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>SV-Nummer (falls vorhanden)</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    value={ studentSvNr }
                                    name="studentSvNr"
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Eintrittsdatum</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="date"
                                    name="studentEntryDate"
                                    value={ studentEntryDate }
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
}

export default AccidentReportSpecial;