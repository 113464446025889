import FieldsContainer from "../fields-container/fields-container.component";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AccidentReportContext } from "../../reducer/accident-report/accident-report.context";
import { BaseContext } from "../../reducer/base/base.context";
import { ACCIDENT_REPORT_STEPS, ACCIDENT_REPORT_STEPS_ROUTES } from "../../utils/consts/steps.consts";
import { ACCIDENT_REPORT_STARTUP_REPORT_FROM, ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT } from "../../utils/consts/radioButtons";
import { getRowClassNames, inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations } from "../../utils/common/bootstrap";

const AccidentReportStartup = () => {
    const [validated, setValidated] = useState(false);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        setCurrentStep,
        startupFields,
        setStartupFields,
        stepsValidated,
        setStepsValidated
    } = useContext(AccidentReportContext);

    const {
        setHeaderFormName,
        setFooterIsWithActions,
        setFooterNextAction,
        setIsFooterAccidentCopyright
    } = useContext(BaseContext);

    const {
        unfallmeldungDate,
        reportFrom,
        typeAccident
    } = startupFields;

    useEffect(() => {
        setHeaderFormName('Unfallmeldung');
        setFooterIsWithActions(true);
        setCurrentStep(ACCIDENT_REPORT_STEPS.STARTUP);
        setFooterNextAction(() => submitButtonRef.current.click());
        setIsFooterAccidentCopyright(true);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setStartupFields({ ...startupFields, [name]: value });
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [ACCIDENT_REPORT_STEPS.STARTUP]: true
            } );
            navigate(`/unfallmeldung/${ACCIDENT_REPORT_STEPS_ROUTES.PERSONAL_INFORMATION}`);
        }

        setValidated(true);
    };

    return (
        <div className="accident-report-startup">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer title={'Unfallmeldung'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Datum Unfallmeldung</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames}
                                    type="date"
                                    name="unfallmeldungDate"
                                    value={ unfallmeldungDate }
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Durch wen erfolgt die Unfallmeldung?'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Meldung erfolgt*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="durch die verunfallte Person"
                                    name="reportFrom"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ ACCIDENT_REPORT_STARTUP_REPORT_FROM.INJURED_PERSON }
                                    checked={ reportFrom === ACCIDENT_REPORT_STARTUP_REPORT_FROM.INJURED_PERSON }
                                />
                                <Form.Check
                                    label="stellvertretend durch eine andere Person"
                                    name="reportFrom"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ ACCIDENT_REPORT_STARTUP_REPORT_FROM.ANOTHER_PERSON }
                                    checked={ reportFrom === ACCIDENT_REPORT_STARTUP_REPORT_FROM.ANOTHER_PERSON }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Art / Ursache des Unfalls'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Art / Ursache des Unfalls*</Form.Label>
                            </Col>
                            <Col xxl={4}>
                                <Form.Check
                                    label="Bagatelle (keine resp. max. 3 Tage Arbeitsunfähigkeit)"
                                    name="typeAccident"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.BAGATELLE }
                                    checked={ typeAccident === ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.BAGATELLE }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                                <Form.Check
                                    label="Unfall (Arbeitsunfähigkeit ab 4 und mehr Tagen)"
                                    name="typeAccident"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.ACCIDENT }
                                    checked={ typeAccident === ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.ACCIDENT }
                                />
                                <Form.Check
                                    label="Rückfall"
                                    name="typeAccident"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.RELAPSE }
                                    checked={ typeAccident === ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.RELAPSE }
                                />
                                <Form.Check
                                    label="Zahnschaden"
                                    name="typeAccident"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.TOOTH_DEMAGE }
                                    checked={ typeAccident === ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.TOOTH_DEMAGE }
                                />
                                <Form.Check
                                    label="Berufskrankheit"
                                    name="typeAccident"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.OCCUPATIONAL_DISEASE }
                                    checked={ typeAccident === ACCIDENT_REPORT_STARTUP_TYPE_ACCIDENT.OCCUPATIONAL_DISEASE }
                                />
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>
                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
}

export default AccidentReportStartup;