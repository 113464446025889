import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../reducer/base/base.context';
import { SICK_REPORT_STEPS, SICK_REPORT_STEPS_ROUTES } from '../../utils/consts/steps.consts';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import FieldsContainer from '../fields-container/fields-container.component';
import { CIVIL_STATUS } from '../../utils/consts/selects';
import { GENDER } from '../../utils/consts/radioButtons';
import { getPreviousSickReportStep, getRequiredErrorMessageText, goToNextSickReportStep } from '../../utils/common/functions';
import { getRowClassNames, inputColClassNames, inputColConfigurations, rowConfigurations, textColClassNames, textColConfigurations } from '../../utils/common/bootstrap';
import { SickReportContext } from '../../reducer/sick-report/sick-report.context';
import MaskedInputField from '../masked-input/masked-input';

const SickReportEmployeeInformation = ({ employee }) => {
    const [validated, setValidated] = useState(false);

    const submitButtonRef = useRef(null);
    let navigate = useNavigate();

    const {
        currentSickReportType,
        currentStep,
        setCurrentStep,
        employeeInformationFields,
        setEmployeeInformationFields,
        stepsValidated,
        setStepsValidated
    } = useContext(SickReportContext);

    const {
        setFooterNextAction,
        setFooterBackAction
    } = useContext(BaseContext);

    const {
        firstName,
        lastName,
        birthDate,
        street,
        houseNr,
        zip,
        city,
        nationality,
        civilStatus,
        gender,
        ahvSvNumber,
        bankDetails,
        ibanNr,
        postAccount,
        accountNr,
        telephonePrivate,
        emailPrivate
    } = employeeInformationFields;

    useEffect(() => {
        setCurrentStep(SICK_REPORT_STEPS(currentSickReportType).EMPLOYEE_INFORMATION);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentStep) {
            const prevStep = getPreviousSickReportStep(currentSickReportType, currentStep);

            if (prevStep) {
                if (!stepsValidated[prevStep]) {
                    navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`);
                } else {
                    setFooterNextAction(() => submitButtonRef.current.click());
                    setFooterBackAction(() => navigate(`/krankheitsmeldung/${ SICK_REPORT_STEPS_ROUTES(currentSickReportType, prevStep) }`));
                }
            }
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const handleFieldsChange = (event) => {
        const { name, value } = event.target;

        setEmployeeInformationFields({ ...employeeInformationFields, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setEmployeeInformationFields({ ...employeeInformationFields, [name]: checked });
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            setStepsValidated( {
                ...stepsValidated,
                [SICK_REPORT_STEPS(currentSickReportType).EMPLOYEE_INFORMATION]: true
            } );

            goToNextSickReportStep(currentSickReportType, currentStep, navigate);
        }

        setValidated(true);
    };

    return (
        <div className="sick-report-employeeInformationFields">
            <Form noValidate validated={ validated } onSubmit={ handleSubmit } >
                <FieldsContainer title={'Angaben zur erkrankten Person'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Vorname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ firstName }
                                    name="firstName"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Vorname') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Nachname*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    value={ lastName }
                                    name="lastName"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Nachname') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Geburtsdatum*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    max={ new Date().toISOString().split('T')[0]}
                                    type="date"
                                    name="birthDate"
                                    value={ birthDate }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Geburtsdatum') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Strasse*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    name="street"
                                    value={ street }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Strasse') }
                                </Form.Control.Feedback>
                            </Col>
                            
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Haus-Nr.</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    name="houseNr"
                                    value={ houseNr }
                                    onChange={ handleFieldsChange }
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Postleitzahl*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    name="zip"
                                    value={ zip }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Postleitzahl') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Ort*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    name="city"
                                    value={ city }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Ort') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Nationalität*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="text"
                                    name="nationality"
                                    value={ nationality }
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Nationalität') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Zivilstand*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Select
                                    className={ inputColClassNames }
                                    required
                                    name="civilStatus"
                                    onChange={ handleFieldsChange }
                                    value={ civilStatus }
                                >
                                    <option value=''>Bitte auswählen...</option>
                                    <option value={ CIVIL_STATUS.DIVORCED }>geschieden</option>
                                    <option value={ CIVIL_STATUS.DISSOLVED_PARTNERSHIP }>in aufgelöster Partnerschaft</option>
                                    <option value={ CIVIL_STATUS.REGISTERED_PARTNERSHIP }>in eingetragener Partnerschaft</option>
                                    <option value={ CIVIL_STATUS.SINGLE }>ledig</option>
                                    <option value={ CIVIL_STATUS.MARRIED }>verheiratet</option>
                                    <option value={ CIVIL_STATUS.WIDOWED }>verwitwet</option>
                                    <option value={ CIVIL_STATUS.LIVING_SEPARATELY }>getrennt lebend</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Zivilstand') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Geschlecht*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label="männlich"
                                    name="gender"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ GENDER.MALE }
                                    checked={ gender === GENDER.MALE }
                                />
                                <Form.Check
                                    label="weiblich"
                                    name="gender"
                                    type='radio'
                                    onChange={ handleFieldsChange }
                                    value={ GENDER.FEMALE }
                                    checked={ gender === GENDER.FEMALE }
                                    required
                                    feedback="Bitte wählen Sie eine Option aus."
                                    feedbackType="invalid"
                                />
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>AHV/SV Nummer*</Form.Label>
                            </Col>
                                <MaskedInputField
                                    mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/]}
                                    inputProps={{
                                        name: 'ahvSvNumber',
                                        value: ahvSvNumber,
                                        onChange: handleFieldsChange,
                                        className: inputColClassNames,
                                        pattern: "\\d{3}\\.\\d{4}\\.\\d{4}\\.\\d{2}"
                                    } }
                                    req={{
                                        isRequired: true,
                                        label: 'AHV/SV Nummer'
                                    } }
                                />
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Zahlungsverbindung der/des MA'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Bankverbindung</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label=""
                                    name="bankDetails"
                                    type='checkbox'
                                    onChange={ handleCheckboxChange }
                                    value={ bankDetails }
                                    checked={ bankDetails }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>{ `IBAN-Nr. ${ bankDetails ? '*' : '' }` }</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required={ bankDetails }
                                    type="text"
                                    value={ ibanNr }
                                    name="ibanNr"
                                    onChange={ handleFieldsChange }
                                />
                                {
                                    bankDetails &&
                                        <Form.Control.Feedback type="invalid">
                                            { getRequiredErrorMessageText('IBAN-Nr.') }
                                        </Form.Control.Feedback>
                                }
                            </Col>
                        </Row>

                        <Row { ...rowConfigurations } className={ getRowClassNames() }>
                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Postkonto</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Check
                                    label=""
                                    name="postAccount"
                                    type='checkbox'
                                    onChange={ handleCheckboxChange }
                                    value={ postAccount }
                                    checked={ postAccount }
                                />
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>{ `Konto-Nr. ${ postAccount ? '*' : '' }` }</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    type="text"
                                    required={ postAccount }
                                    value={ accountNr }
                                    name="accountNr"
                                    onChange={ handleFieldsChange }
                                />
                                {
                                    postAccount &&
                                        <Form.Control.Feedback type="invalid">
                                            { getRequiredErrorMessageText('Konto-Nr.') }
                                        </Form.Control.Feedback>
                                }
                            </Col>
                        </Row>
                    </Container>
                </FieldsContainer>

                <FieldsContainer title={'Erreichbarkeit der erkrankten Person während der Krankheitsabwesenheit'}>
                    <Container fluid>
                        <Row { ...rowConfigurations } className={ getRowClassNames(true) }>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>Tel.-Nr. MA/Privat*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="tel"
                                    value={ telephonePrivate }
                                    name="telephonePrivate"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('Tel.-Nr. MA/Privat') }
                                </Form.Control.Feedback>
                            </Col>

                            <Col { ...textColConfigurations } className={ textColClassNames }>
                                <Form.Label>E-Mail MA/Privat*</Form.Label>
                            </Col>
                            <Col { ...inputColConfigurations }>
                                <Form.Control
                                    className={ inputColClassNames }
                                    required
                                    type="email"
                                    value={ emailPrivate }
                                    name="emailPrivate"
                                    onChange={ handleFieldsChange }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { getRequiredErrorMessageText('E-Mail MA/Privat') }
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                    </Container>
                </FieldsContainer>

                <Button type="submit" className="submit-button" ref={ submitButtonRef }></Button>
            </Form>
        </div>
    )
}

export default SickReportEmployeeInformation;
