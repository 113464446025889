import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Home from './routes/home/home.route';
import AccidentReport from './routes/accident-report/accident-report.route';
import Router from './routes/route/router';
import SickReport from './routes/sick-report/sick-report.route';

function App() {
    return (
        <Routes>
            <Route path="/" element={ <Router /> }>
                <Route index element={ <Home /> } />
                <Route path="unfallmeldung/*" element={ <AccidentReport /> } />
                <Route path="krankheitsmeldung/*" element={ <SickReport /> } />
            </Route>
        </Routes>
    );
}

export default App;
