import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

import './info-icon.styles.scss';

const InfoIcon = (
    {
        id='popover-1',
        heading='Information',
        text='Bisher keine Information vorhanden.'
    }
) => {
    const popover = (
        <Popover className="popover" id={ id }>
            <Popover.Header as="h3">
                { heading }   
            </Popover.Header>
            <Popover.Body>
                { text }
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger={ ['hover', 'focus'] } placement="right" overlay={ popover }>
            <Button variant="info" className="info-icon">
                <FontAwesomeIcon icon={ faInfo } />
            </Button>
        </OverlayTrigger>
    )
}

export default InfoIcon;